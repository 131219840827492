<!-- pages 14 /16 (overview)-->
<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <h2 class="new-reports-box__title mt-04">{{ this.report_info.house_address }} - {{ this.report_info.zipcode }}</h2>
      <div class="i-text -gray-4 font-bold mt-05">
        {{ this.report_info.house_address }}
        <br>
        {{ this.report_info.city }}
      </div>

      <h1 class="text-center page-title -primary mt-40 mt-md-30">{{ t('agents.reports.new.overview') }}</h1>
      <div class="font-13 text-center mb-40 mb-md-30">{{ t('agents.reports.new.overview_desc') }}</div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>
      <div class="new-reports-box -bg-white mb-35">
        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.basic_info') }}</h2>
        <div class="mb-20 mb-sm-15">

        </div>
        <div class="row gutter-md-10">
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.owner_full_name') }}</label>
            <div class="font-15 font-md-14"> {{ fullOwnerName() }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.address') }}</label>
            <div class="font-15 font-md-14">{{ houseFullAddress() }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.city') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.report_basic_information.city }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.zip_code') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.report_basic_information.zipcode }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.contact_person') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.name }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.phone_contact_person') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.phone_number }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.date_of_statement') }}</label>
            <div class="font-15 font-md-14">{{ displayDateTimeByFormat(reportDetails.report_basic_information.date_of_statement) }}</div>
          </div>
        </div>
        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">

        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.company_info') }}</h2>
        <div class="row gutter-md-10">
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.company_name') }}</label>
            <div class="font-15 font-md-14 i-text">{{ entityProfile.name }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_phone_number') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.phone_number }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.email_address') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.email }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.company_address') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.address }}</div>
          </div>
        </div>
        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">

        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.hirer_info') }}</h2>
        <div v-if="clientsInformation.length > 0">
          <div class="row gutter-md-10" v-for="clientInformation in clientsInformation" :key="clientInformation.id">
            <div class="col-lg-4 col-6 mb-20 mb-sm-15">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.hirer_full_name') }}</label>
              <div class="font-15 font-md-14">
                {{ clientInformation.full_name }}
              </div>
            </div>
            <div class="col-lg-4 col-6 mb-20 mb-sm-15">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_phone_number') }}</label>
              <div class="font-15 font-md-14">{{ clientInformation.phone_number }}</div>
            </div>

            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.email') }}</label>
              <div class="font-15 font-md-14">{{ clientInformation.email }}</div>
            </div>

            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="!isDeliveryReport">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_address') }}</label>
              <div class="font-15 font-md-14">{{ clientInformation.address }}</div>
            </div>
            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="!isDeliveryReport">
              <div class="mb-20 mb-sm-15">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.bank_account_number') }}</label>
                <div class="font-15 font-md-14">{{ clientInformation.bank_account_number }}</div>
              </div>
            </div>
            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.rental_agreement_start_at') }}</label>
              <div class="font-15 font-md-14">{{ displayDateTimeByFormat(clientInformation.rental_agreement_start_at) }}</div>
            </div>
            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="!isDeliveryReport">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.end_date_agreement_hiring') }}</label>
              <div class="font-15 font-md-14">{{ displayDateTimeByFormat(clientInformation.rental_agreement_end_at) }}</div>
            </div>
            <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isPreInspectionReport">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.scheduled_final_inspection_at') }}</label>
              <div class="font-15 font-md-14">{{ displayDateTimeByFormat(clientInformation.scheduled_final_inspection_at, defaultDateTime) }}</div>
            </div>
          </div>
        </div>

        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0" v-if="meterInformation">
        <h2 class="new-reports-box__title mb-20" v-if="meterInformation">
          {{ t('agents.reports.new.meter_info') }}</h2>
        <div class="row gutter-md-10" v-if="meterInformation">
          <div class="col-lg-3 col-sm-6 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.water') }}</label>
            <div class="font-15 font-md-14">{{ meterInformation.water }}</div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.gas') }}</label>
            <div class="font-15 font-md-14">{{ meterInformation.gas }}</div>
          </div>
          <div class="col-lg-6 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.electricity') }}</label>
            <div class="row gutter-md-10">
              <div class="col-6 mb-20 mb-sm-15 i-text font-15 font-md-14">
                <p><b>{{ t('agents.reports.show.day') }} -</b> {{ meterInformation.electric_day }} kWh</p>
              </div>
              <div class="col-6 mb-20 mb-sm-15 i-text font-15 font-md-14">
                <p><b>{{ t('agents.reports.show.night') }} -</b> {{ meterInformation.electric_night }} kWh</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-20 mb-sm-15" v-if="meterInformation && meterInformation.images && meterInformation.images.length > 0">
          <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
          <div class="row gutter-15">
            <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in meterInformation.images" v-bind:key="index">
              <div class="new-report-img">
                <a :href="image.url" target="_blank" rel="noopener noreferrer">
                  <img v-bind:src="image.thumb_url" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutter-md-10" v-if="reportDetails.meter_comment">
          <div class="col-12 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.comments') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.meter_comment }}</div>
          </div>
        </div>

        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0" v-if="displayKeysBlock()">
        <h2 class="new-reports-box__title mb-20" v-if="displayKeysBlock()">{{ t('agents.reports.new.keys_info') }}</h2>
        <div v-if="displayKeysBlock()">
          <div v-for="(key_for, index) in keyData" v-bind:key="index">
            <div class="row gutter-md-5" >
              <div class="col-lg-3 col-4">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.key_for') }}</label>
                <div class="font-15 font-md-14">{{ key_for.room }}</div>
              </div>
              <div class="col-lg-3 col-5">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.key_number') }}</label>
                <div class="font-15 font-md-14">{{ key_for.key_number }}</div>
              </div>
              <div class="col-lg-3 col-3">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.qty') }}</label>
                <div class="font-15 font-md-14">{{ key_for.quantity }}</div>
              </div>
            </div>
            <div class="mb-20 mb-sm-15 mt-3" v-if="key_for.images.length > 0">
              <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
              <div class="row gutter-15">
                <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in key_for.images" v-bind:key="index">
                  <div class="new-report-img">
                    <a :href="image.url" target="_blank" rel="noopener noreferrer">
                      <img v-bind:src="image.thumb_url" alt="">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-report-line -view-mode mb-15">{{ key_for.note }}</div>
          </div>
        </div>

        <div class="row gutter-md-10" v-if="reportDetails.keys_comment">
          <div class="col-12 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.comments') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.keys_comment }}</div>
          </div>
        </div>

        <div v-if="displayComment()">
          <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">

          <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.comments') }}</h2>
          <div v-for="(comment, index) in reportDetails.report_comments" v-bind:key="index">
            <div class="mb-20 mb-sm-15">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t(`agents.reports.new.${comment.type}`) }} - {{ comment.subject }}</label>
              <div class="font-15 font-md-14">{{ comment.content }}</div>
            </div>

            <div class="mb-20 mb-sm-15" v-if="comment.images.length > 0">
              <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
              <div class="row gutter-15" v-if="comment">
                <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in comment.images" v-bind:key="index">
                  <div class="new-report-img" v-if="comment">
                    <a :href="image.url" target="_blank" rel="noopener noreferrer">
                      <img v-bind:src="image.thumb_url" alt="">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="house-images" v-if="houseImages.length > 0">
          <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">
          <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.houseImages') }}</h2>

          <div class="row gutter-15">
            <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in houseImages" v-bind:key="index">
              <div class="new-report-img">
                <a :href="image.url" target="_blank" rel="noopener noreferrer">
                  <img v-bind:src="image.thumb_url" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-report-line mb-20 mt-20">
        <div class="row gutter-10 justify-content-center">
          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -white -block pl-03 pr-03" type="button"
                    @click="navigateTo('FormReportStep3', currentReportId)">
              <i class="fa fa-long-arrow-alt-left mr-08 ml-0 mt-0"></i> {{ t('buttons.back') }}
            </button>
          </div>

          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -info -block pl-03 pr-03" type="button"
                    @click="navigateTo('FormReportStep6', currentReportId)">
              {{ t('buttons.next') }} <i class="fa fa-long-arrow-alt-right ml-08 mr-0 mt-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'

import { mapActions, mapGetters } from 'vuex'
import currentReport from '@/mixins/currentReport'
import reportNavigationButtonMixin from '@/mixins/reportNavigationButtonMixin'
import typeOfReport from '@/mixins/typeOfReport'
import momentJsMixin from '@/mixins/momentJsMixin'

export default {
  name: 'ReportStep5',
  mixins: [currentReport, reportNavigationButtonMixin, typeOfReport, momentJsMixin],
  components: {
    Breadcrumb,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: '#' }
      ],
      report_info: {}
    }
  },
  computed: {
    ...mapGetters({
      commentImages: 'commonUploadImage/files',
      entityProfile: 'entities/profiles',
      clientsInformation: 'reports/clientsInformation',
      meterInformation: 'reports/meterInformation',
      keyMessageErrors: 'reportKeyInformation/errors',
      keyData: 'reportKeyInformation/data',
      houseImages: 'reportHouseImages/imageList'
    }),
    hirerInfo() {
      return this.reportDetails.report_client_information
    },
    commentList() {
      return this.reportDetails.report_comments
    }
  },
  methods: {
    ...mapActions({
      getEntityProfiles: 'entities/getProfiles',
      getClientsInformation: 'reports/clientsInformation',
      getMeterInformation: 'reports/meterInformation',
      getKeyInformation: 'reportKeyInformation/getInformation',
      getListHouseImages: 'reportHouseImages/list'
    }),
    houseFullAddress () {
      return `${this.reportDetails.report_basic_information.house_address}`
    },
    fullOwnerName() {
      return this.reportDetails.report_basic_information.owner_full_name
    },
    displayComment() {
      return this.reportDetails.report_comments.length > 0
    },
    displayKeysBlock() {
      return (this.isDeliveryReport || this.isFinalReport) && (this.reportDetails.report_key_information.length > 0)
    }
  },
  async mounted() {
    this.$emit('header-title', this.t('breadcrumb.reports'))

    await this.getReportInfo(this.currentReportId)
    await this.getClientsInformation(this.currentReportId)
    await this.getMeterInformation(this.currentReportId)
    await this.getKeyInformation(this.currentReportId)
    this.report_info = this.reportDetails.report_basic_information
    await this.getEntityProfiles()
    await this.getListHouseImages(this.currentReportId)
  }
}
</script>
