import { ReportStepList } from '@/settings/reportStepList'

const reportNextStepMixin = {
  methods: {
    nextStepPath(report) {
      let stepList = ReportStepList[report.type]
      let currentStepIndex = stepList.indexOf(report.completed_step)
      let nextStep = stepList[currentStepIndex + 1]
      return `/agents/reports/${ report.id }/${ nextStep }`
    }
  }
}

export default reportNextStepMixin
