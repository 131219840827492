const nestedBlock = {
  methods: {
    displayBlock(attributes, index) {
      return attributes[index] && attributes[index]['_destroy'] !== 1
    },
    removeBlock(attributes, index) {
      attributes[index]['_destroy'] = 1
    }
  }
}

export default nestedBlock
