<template>
  <div class="breadcrumb">
    <router-link :to="{ name: item.routerName }" v-for="(item, index) in linkItems" :key="index">
      <i :class="item.icon" v-if="item.icon"></i>
      {{item.name}}
    </router-link>
    <span><i :class="lastItem.icon" v-if="lastItem.icon"></i>{{ lastItem.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['breadcrumbItems'],
  computed: {
    lastItem () {
      return this.breadcrumbItems ? this.breadcrumbItems[this.breadcrumbItems.length -1] : null
    },
    linkItems () {
      return this.breadcrumbItems ? this.breadcrumbItems.slice(0, -1) : []
    },
  }
}
</script>
