<template>
  <div class="main-container">
    <ReportEmailConfig></ReportEmailConfig>
    <TermManagement class="mt-5"></TermManagement>
    <EntityConfiguration class="mt-5"></EntityConfiguration>
  </div>
</template>

<script>
import TermManagement from './_TermManagement'
import EntityConfiguration from './_EntityConfiguration'
import ReportEmailConfig from './_ReportEmailConfig'

export default {
  name: 'Configuration',
  components: {
    TermManagement,
    EntityConfiguration,
    ReportEmailConfig
  }
}
</script>
