import { AbilityBuilder, Ability } from '@casl/ability'

function defineAbilityFor(user) {
  const { can, rules } = new AbilityBuilder()

  if (user && user.type === 'Manager') {
    can('edit', 'MyEntity')
    can('delete', 'Report')
  } else {
    can('read', 'all')
  }

  return new Ability(rules)
}

export default defineAbilityFor