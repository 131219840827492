<template>
  <div v-if="errorCode">
    <Page404></Page404>
  </div>
  <div id="app" v-else>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Page404 from '@/views/shared/Page404'

export default {
  name: 'TheContainerNoLayout',
  components: {
    Page404
  },
  computed: {
    ...mapGetters({
      errorCode: 'globalHandleError/errorCode'
    })
  },
  methods: {
  ...mapActions({
      clearError: 'globalHandleError/clearError'
    })
  },
  async mounted() {
    this.clearError()
  }
}
</script>
