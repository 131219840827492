<template>
  <div class="house">
    <router-link class="house__img img-ratio pseudo"
                 :to="{ name: 'Agents House Show', params: { id: house.id } }">
      <img v-if="house.image_url" :src="house.image_url"  alt=""/>
    </router-link>
    <div class="house__text">
      <h3 class="house__title">
        <router-link class="smooth"
                     :to="{ name: 'Agents House Show', params: { id: house.id } }">
          {{ house.name }}
        </router-link>
      </h3>
      <div class="house__number">
        {{ house.house_code }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'House',
  props: ['house']
}
</script>
