<template>
  <form @submit="onSubmit">
    <div class="form-group"></div>
    <div class="col-12">
      <div class="mb-35 mb-sm-20">
        <label class="i-text -gray">Type</label>
        <select class="i-select mt-2" name="type" v-model="termEditing.type">
          <option value="">{{ t('agents.terms.select_type') }}</option>
          <option v-for="(option, index) in optionsTermTypes" :key="index"
                  :value="option">
            {{ t(`agents.terms.types.${option}`) }}
          </option>
        </select>
        <InvalidFieldMessage v-bind:errorMessages="errors" field="type"/>
      </div>
    </div>

    <div class="col-12">
      <div class="mb-35 mb-sm-20">
        <label class="i-text -gray">Content</label>
        <textarea class="i-input mt-2 p-3" type="text" name="content" rows="10" v-model="termEditing.content">
        </textarea>
        <InvalidFieldMessage v-bind:errorMessages="errors" field="content"/>
      </div>
    </div>
    <div class="col-sm-auto mt-sm-20">
      <div class="row gutter-15 gutter-sm-10">
        <div class="col-sm-auto col-6">
          <router-link :to="{ name: 'Configuration' }" class="i-btn -gray w-100 w-sm-auto">
            Annuleren</router-link>
        </div>
        <button type="submit" class="i-btn w-sm-auto">{{ t('buttons.save') }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import InvalidFieldMessage from '../../common/InvalidFieldMessage'

export default {
    name: 'TermForm',
    props: ['term'],
    setup() {
      const { t } = useI18n()
      return { t }
    },
    data: function () {
      return {
        termEditing: this.term
      }
    },
    components: {
      InvalidFieldMessage
    },
    computed: {
      ...mapGetters({
        errors: 'terms/errors'
      }),
      optionsTermTypes() {
        return ['report_delivery', 'report_pre_inspection', 'report_final_inspection']
      }
    },
    methods: {
      ...mapActions({
        update: 'terms/update',
        create: 'terms/create'
      }),
      onSubmit(event) {
        event.preventDefault()

        if(this.$route.name === 'TermEdit') {
          this.update(this.termEditing)
        } else if (this.$route.name === 'TermNew'){
          this.create(this.termEditing)
        }
      }
    },
    mounted() {
      this.termEditing = this.term
    }
  }
</script>

<style>
  textarea.i-input {
    height: 100%;
  }
</style>
