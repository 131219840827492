<template>
  <div v-bind="getRootProps()">
    <input v-bind="getInputProps()"/>
    <div class="image-uploader p-2 border-dash border-dash--rounded">
      <div v-if="object.imagesPreview && object.imagesPreview.length > 0" class="images-preview media-upload__preview">
        <div v-for="image in object.imagesPreview" :key="image.thumb_url" class="media-upload__preview__item h-100 mt-1 mb-1">
          <div class="icon--uploading__image" v-if="!image.uploadDone">
            <i class="icon icon-loading__pulse__md"></i>
          </div>
          <img :class="image.uploadDone ? '' : 'blur-opacity-04'" :src="image.url" alt="" v-bind:data-blob_key="image.blob_key"/>
          <span class="media-upload__preview__item__remove pointer z-index-10" v-if="image.uploadDone"
                  v-on:click="removeImage(image)">&times;
          </span>
        </div>
        <div class="media-upload__preview__item -add-new"></div>
      </div>
      <div v-else>
        <div class="media-upload__icon">
          <i class="icon icon-media"></i>
        </div>
        <div class="media-upload__text">
          <p>{{ t('agents.uploadFiles.drag_and_drop_label') }}</p>
        </div>
        <div class="media-upload__select">{{ t('agents.uploadFiles.choose_file') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useDropzone } from 'vue3-dropzone'
import { reactive } from 'vue'
import ActiveStorageApi from '@/backend/active_storage'

export default {
  name: 'ImageUploaderPreview',
  props: ['currentImages', 'index'],
  setup() {
    const object = reactive({ imagesPreview: [] })
    const { t } = useI18n()
    const saveFiles = (files) => {
      files.forEach((file) => {
        let imageUrl = URL.createObjectURL(file)
        let imageObject = { url: imageUrl, uploadDone: false }
        object.imagesPreview.push(imageObject)

        const formData = new FormData()
        formData.append('file', file)
        ActiveStorageApi.uploadSingleFile(formData).then((response) => {
          let currentImageIndex = object.imagesPreview.findIndex(element => element.url === imageUrl)
          object.imagesPreview[currentImageIndex]['uploadDone'] = true
          object.imagesPreview[currentImageIndex]['blob_key'] = response.data.blob_key
        }).catch((err) => {
          console.error(err)
        })
      })
    }

    function onDrop(acceptFiles) {
      saveFiles(acceptFiles)
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ maxSize: 5120000, onDrop })
    return {
      t,
      getRootProps,
      getInputProps,
      ...rest,
      object
    }
  },
  mounted() {
    this.object.imagesPreview = this.currentImages || []
  },
  methods: {
    removeImage(image) {
      event.stopPropagation()
      if(image.blob_key) {
        ActiveStorageApi.delete({ key: image.blob_key }).then(()=> {
          this.object.imagesPreview = this.object.imagesPreview.filter(_image => (_image.url !== image.url))
        })
      }
    }
  },
  watch: {
    'object.imagesPreview': {
      handler(val){
        if(this.index === undefined) {
          this.$emit('upload-images', val)
        } else {
          this.$emit('upload-images', val, this.index)
        }
      },
      deep: true
    }
  }
}
</script>
