import ReportOwnerInformationApi from '../../../backend/report_owner_information'

const SET_DETAILS = 'SET_DETAILS'
const SIGNATURE = 'SIGNATURE'

const state = {
  details: {},
  signature: {}
}

export const mutations = {
  [SET_DETAILS](state, details) {
    state.details = details
  },
  [SIGNATURE](state, signature) {
    state.signature = signature
  }
}

export const actions = {
  async show({ commit }, reportId) {
    await ReportOwnerInformationApi.show(reportId).then(response => {
      commit(SET_DETAILS, response.data)
    }).catch()
  },
  async getSignatureDetails({ commit }, reportId) {
    await ReportOwnerInformationApi.signature(reportId).then(response => {
      commit(SIGNATURE, response.data)
    }).catch()
  }
}

export const getters = {
  details: state => state.details,
  signature: state => state.signature,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
