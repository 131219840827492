<template>
  <div class="document-accepted mb-40 mb-md-30">
    <h2 class="review__title">
      <i class="icon icon-box-white review__title__icon"></i>
      {{ t('agents.houseApplications.countDocumentApproved.documentApproved') }}
    </h2>
    <div class="document-accepted__number">{{ numberOfDocumentApproved }}</div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'CountDocumentApproved',
  props: ['documents'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    numberOfDocumentApproved(){
      return this.documents.filter(document => document.state === 'approved').length
    }
  }
}
</script>
