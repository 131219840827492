<template>
  <div id="notifications">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>

      <h1 class="page-title -primary mb-35 mb-md-25">
        {{ t('agents.notifications.title') }}
      </h1>

      <SearchForm @form-searching="formSearching"/>

      <div class="notifications" v-if="notificationList !== undefined &&
        notificationList.length > 0" >
        <div v-for="(notification, index) in notificationList"
             :class="notificationItemClassName(index)"
             v-bind:key="index">
          <NotificationItem :notification="notification"></NotificationItem>
        </div>
      </div>
      <div class="notifications" v-else>
        <TableNoData></TableNoData>
      </div>
      <div class="text-center mt-45 mt-md-20" v-if="currentPage < totalPages">
        <button class="smooth i-btn -border -gray" v-on:click="loadMore">
          {{ t('agents.notifications.load_more') }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../shared/Breadcrumb'
import TableNoData from '../../shared/TableNoData'
import SearchForm from './SearchForm'
import NotificationItem from './NotificationItem'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Notifications',
  components: {
    Breadcrumb,
    SearchForm,
    NotificationItem,
    TableNoData
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.notifications'), routerName: '#', icon: 'icon icon-bell-2' }
      ],
      page: parseInt(this.$route.query.page) || 1,
      searchParams: { sender_id: '', content_cont: '' }
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/list',
    }),
     notificationList() {
      return this.notifications.list
    },
    totalPages() {
      return this.notifications.totalPages
    },
    currentPage() {
      return this.notifications.currentPage
    },
  },
  methods: {
    ...mapActions({
      setList: 'notifications/setList',
      loadMoreNotification: 'notifications/loadMore'
    }),
    notificationItemClassName(index) {
      if(index %2 === 0 ) {
        return 'notifications__item -green'
      } else {
        return 'notifications__item -orange'
      }
    },
    dateOfNotification(the_date) {
      return moment(the_date).format('MMMM DD, YYYY')
    },
    timeAgo(theDate) {
      return moment(theDate).fromNow()
    },
    loadMore() {
      let page = this.currentPage + 1
      this.searchParams.page = page
      this.loadMoreNotification( this.searchParams )
    },
    formSearching(params) {
      this.searchParams = params
    }
  },
  mounted() {
    this.setList()
  }
}
</script>
