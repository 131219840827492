import HouseApplicationApi from '../../../backend/house_application'
import router from '../../../router'

const APPLICATION_DETAILS = 'APPLICATION_DETAILS'
const DOCUMENT_LIST = 'DOCUMENT_LIST'
const APPLICATION_ERRORS = 'APPLICATION_ERRORS'
const TOGGLE_MODAL = 'TOGGLE_MODAL'

const state = {
  application: {},
  documentList: {},
  applicationError: [],
  modalState: {
    refuseApplication: 'off',
    reviewApplication: 'off',
    acceptance: 'off',
    documentView: 'off',
    approveApplication: 'off',
    acceptApplication: 'off'
  },
  openingDocumentId: null
}

export const mutations = {
  [APPLICATION_DETAILS] (state, application) {
    state.application = application
  },
  [DOCUMENT_LIST] (state, documents) {
    state.documentList = documents
  },
  [APPLICATION_ERRORS] (state, applicationError) {
    state.applicationError = applicationError
  },
  [TOGGLE_MODAL] (state, data) {
    state.modalState[data.modalName] = data.state
    state.openingDocumentId = data.documentId || {}
  }
}

const actions = {
  getHouseApplication({ commit }, id) {
    HouseApplicationApi.get(id).then(response => {
      commit(APPLICATION_DETAILS, response.data)
    }).catch(error => {
      console.error(error)
    })
  },
  getDocumentList({ commit }, id) {
    HouseApplicationApi.getDocumentList(id).then(response => {
      commit(DOCUMENT_LIST, response.data)
    }).catch(error => {
      console.error(error)
    })
  },
  refuseApplication({ commit }, params) {
    return HouseApplicationApi.refuseApplication(params).then(response => {
      commit(TOGGLE_MODAL, { modalName: 'refuseApplication', state: 'off' })
      router.push( { name: 'Agents House Show', params: { id: response.data.house_info.id } }).then(() => {})
    }).catch((error) => {
      commit(APPLICATION_ERRORS, error.response.data.messages)
    })
  },
  approve({ commit }, params) {
    return HouseApplicationApi.approveApplication(params).then(response => {
      commit(TOGGLE_MODAL, { modalName: 'approveApplication', state: 'off' })
      router.push( { name: 'Agents House Show', params: { id: response.data.house_info.id } }).then(() => {})
    }).catch((error) => {
      commit(APPLICATION_ERRORS, error.response.data.messages)
    })
  },
  accept({ commit }, id) {
    return HouseApplicationApi.accept(id).then(response => {
      commit(TOGGLE_MODAL, { modalName: 'acceptApplication', state: 'off' })
      router.push( { name: 'Agents House Show', params: { id: response.data.house_info.id } }).then(() => {})
    }).catch((error) => {
      commit(APPLICATION_ERRORS, error.response.data.messages)
    })
  },
  toggleModal({ commit, dispatch }, data) {
    commit(TOGGLE_MODAL, data)
    if(data.state === 'off') {
      commit(APPLICATION_ERRORS, [])
      dispatch('documents/hideEventError', {}, { root:true })
    }
  },
  review({ commit }, params) {
    return HouseApplicationApi.review(params).then(() => {
      commit(TOGGLE_MODAL, { modalName: 'reviewApplication', state: 'off' })
      // router.push( { name: 'Agents House Show', params: { id: response.data.house_info.id } }).then(() => {});
    }).catch((error) => {
      commit(APPLICATION_ERRORS, error.response.data.messages)
    })
  }
}

export const getters = {
  application: state => state.application,
  documentList: state => state.documentList,
  applicationError: state => state.applicationError,
  modalState: state => state.modalState,
  openingDocumentId: state => state.openingDocumentId
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
