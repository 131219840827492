<template>
  <div></div>
</template>

<script>
import ReportApi from '@/backend/reports'
import { mapActions, mapGetters } from 'vuex'
import reportNextStepMixin from '@/mixins/reportNextStepMixin'

export default {
    name: 'PdfDownload',
    mixins: [reportNextStepMixin],
    methods: {
      ...mapActions({
        getReportInfo: 'reports/getReportInfo',
      }),
      exportPdf() {
        ReportApi.download(this.$route.params.id).then(response => {
          window.location.replace(response.data.url)
        }).catch(() => {
        })
      },
    },
    computed: {
      ...mapGetters({
        reportDetails: 'reports/reportDetails',
        reportComments: 'reportComment/reportComments',
      })
    },
    async mounted() {
      await this.getReportInfo(this.$route.params.id)
      if(this.reportDetails.completed_step !== 'step-signature') {
        return this.$router.push({ path: this.nextStepPath(this.reportDetails) })
      }

      this.exportPdf()
    }
  }
</script>
