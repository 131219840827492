import ApiService from './api_services'

const DocumentApi = {
  get: function(id) {
    return ApiService.get(`/documents/${id}`)
  },
  review: function (documentParams) {
    let id = documentParams.id
    return ApiService.put(`/documents/${id}/review`, { document: documentParams })
  }
}

export default DocumentApi
