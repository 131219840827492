import moment from 'moment-timezone'

const momentServices = {
  convertTimeApiToLocal(apiTime) {
    let localTimeZone = moment.tz.guess()
    return moment.tz(apiTime, localTimeZone)
  }
}

export default momentServices
