<template>
  <div class="row">
    <div class="col-sm-6 mb-20">
      <div class="review-modal__label">{{ t('agents.modalHouseInfo.house_name') }}</div>
      <div class="review-modal__value" v-if="houseInfo">{{ houseInfo.name }}</div>
    </div>
    <div class="col-sm-6 mb-20">
      <div class="review-modal__label">{{ t('agents.modalHouseInfo.price') }}</div>
      <div class="review-modal__value" v-if="houseInfo">{{ houseInfo.price }}</div>
    </div>
    <div class="col-sm-6 mb-20">
      <div class="review-modal__label">{{ t('agents.modalHouseInfo.email') }}</div>
      <div class="review-modal__value" v-if="clientInfo">{{ clientInfo.email }}</div>
    </div>
    <div class="col-sm-6 mb-20">
      <div class="review-modal__label">{{ t('agents.modalHouseInfo.start') }}</div>
      <div class="review-modal__value" v-if="houseInfo">{{ houseInfo.start_hiring_date }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalHouseInfo',
  props: ['houseInfo', 'clientInfo'],
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>
