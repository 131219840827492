import HouseApi from '../../../backend/houses'
import { subDomain } from '../../../settings/subdomain'
import router from '../../../router'
import { createToast } from 'mosha-vue-toastify'
import { ToastSettings } from '../../../settings/toast'

const HOUSE_LIST = 'HOUSE_LIST'
const CREATE_HOUSE = 'CREATE_HOUSE'
const HOUSE_DETAILS = 'HOUSE_DETAILS'
const UPDATE_HOUSE = 'UPDATE_HOUSE'
const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS'
const REFUSE_APPLICATIONS = 'REFUSE_APPLICATIONS'

const state = {
  houses: {
    totalPages: 0,
    list: []
  },
  houseError: {},
  houseInfo: {},
  applications: []
}

export const mutations = {
  [HOUSE_LIST] (state, data) {
    state.houses.totalPages = data.total_pages
    state.houses.list = data.list
  },

  [CREATE_HOUSE] (state, houseError) {
    state.houseError = houseError
  },

  [UPDATE_HOUSE] (statue, houseError) {
    state.houseError = houseError
  },

  [HOUSE_DETAILS] (state, houseInfo) {
    state.houseInfo = houseInfo
  },

  [FETCH_APPLICATIONS] (state, applications) {
    state.applications = applications
  },

  [REFUSE_APPLICATIONS] (state, applications) {
    state.applications = applications
  }
}

export const actions = {
  setList ({ commit }, searchParams={}) {
    searchParams['entity_subdomain'] = subDomain()
    return HouseApi.getList(searchParams).then(response => {
      commit(HOUSE_LIST, response.data)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      router.replace({ name: 'Agents Houses', query: searchParams })
    }).catch(error => {
      console.log(error)
    })
  },

  getHouseInfo ({ commit }, houseID) {
    return HouseApi.get(houseID).then(response => {
      let houseInfo = response.data
      commit(HOUSE_DETAILS, houseInfo)
    }).catch(error => {
      console.log(error)
    })
  },

  createHouse({ commit }, houseParams={}){
    houseParams['entity_subdomain'] = subDomain()
    return HouseApi.create(houseParams).then(() => {
      router.push({ name: 'Agents Houses' }).then(() => {})
    }).catch((error) => {
      let errors = error.response.data.messages
      commit(CREATE_HOUSE, errors)
    })
  },

  updateHouse({ commit }, houseParams = {}) {
    return HouseApi.update(houseParams).then(() => {
      router.push({ name: 'Agents Houses' }).then(() => {})
    }).catch((error) => {
      let errors = error.response.data.messages
      commit(CREATE_HOUSE, errors)
    })
  },

  fetchApplications({ commit }, houseId) {
    return HouseApi.getApplications(houseId).then(response => {
      commit(FETCH_APPLICATIONS, response.data)
    }).catch((error) => {
      console.error(error)
    })
  },

  refuseApplications({ commit }, params) {
    return HouseApi.refuseApplicationList(params.houseId, params.refuse_ids).then(response => {
      commit(REFUSE_APPLICATIONS, response.data)
    }).catch((error) => {
      createToast(error.response.data.messages, ToastSettings.error)
    })
  }
}

export const getters = {
  houseList: state => state.houses,
  houseInfo: state => state.houseInfo,
  houseError: state => state.houseError,
  applications: state => state.applications
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
