<template>
  <form class="notify-search-form">
    <div class="row justify-content-between align-items-center mb-30">
      <div class="col-xl-4 mb-xl-15">
        <input class="search-form__input" v-model="searchParams.content_cont"
             v-bind:placeholder="t('agents.searchForm.placeholder')" />
      </div>
      <div class="col-xl-7">
        <div class="row align-items-center gutter-20">
          <div class="col">
            <div class="row gutter-20">
              <div class="col-sm-4 mb-sm-10 offset-md-4">
                <select class="i-select bg-white" v-model="searchParams.sender_id">
                  <option value="">{{ t('agents.notifications.select_agent') }}</option>
                  <option v-for="agent in agents" v-bind:key="agent.id" v-bind:value="agent.id">{{ agent.full_name }}</option>
                </select>
              </div>
              <div class="col-sm-4">
                <input type="date" class="i-input datepicker bg-white" v-model="searchParams.time" v-bind:placeholder="t('agents.notifications.pick_date')">
              </div>
            </div>
          </div>
          <div class="col-auto">
            <button @click.prevent="submitSearch()">
              <i class="icon icon-filter"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SearchForm',
  components: {
  },
  data () {
    return {
      searchParams: { sender_id: '', content_cont: '' }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters({
      agents: 'supports/agents',
    })
  },
  methods: {
    ...mapActions({
      getAgentList: 'supports/getAgentList',
    }),
    submitSearch() {
      this.$emit('form-searching', this.searchParams)
      this.$store.dispatch('notifications/setList', this.searchParams )
    }
  },
  mounted() {
    this.getAgentList()
  }
}
</script>
