<template>
  <div id="agents">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>
      <div class="row justify-content-between align-items-center mb-35 mb-md-25">
        <div class="col-xl-5 mt-xl-10">
          <h1 class="page-title">
            {{ t('agents.agents.index.title') }}
          </h1>
        </div>
        <div class="col-xl-auto mt-xl-20">
          <div class="row justify-content-end gutter-15 gutter-sm-10">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-15">
              <SearchForm @form-searching="formSearching"/>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-15">
              <a class="i-btn -block pl-sm-10 pr-sm-10"
               v-on:click="openModal({ modalName: 'newAgent', state: 'on' })">
                {{ t('agents.agents.new.title') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="reports__table">
        <table>
          <thead>
            <tr>
              <th class="col-name">{{ t('agents.agents.index.table.full_name') }}</th>
              <th class="col-email">{{ t('agents.agents.index.table.email') }}</th>
              <th class="col-role">{{ t('agents.agents.index.table.role') }}</th>
              <th class="col-action">{{ t('agents.agents.index.table.active') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(agent, index) in agentsList" v-bind:key="index">
              <td class="px-2 sp-nowrap">{{ agent.full_name }}</td>
              <td class="sp-nowrap">{{ agent.email }}</td>
              <td class="sp-nowrap" width="15%">{{ agent.type }}</td>
              <td class="sp-nowrap" width="15%">
                <div class="d-flex" v-if="agent.id !== currentUser.id">
                  <div class="custom-control custom-switch w-30px" >
                    <input type="checkbox" class="custom-control-input"
                           :checked="agent.active"
                           @change="changeStatus(agent.id)" :id="`customSwitch_${agent.id}`">
                    <label class="custom-control-label" :for="`customSwitch_${agent.id}`"></label>
                  </div>

                  <div class="w-30px">
                    <i class="icon icon-trash-disabled" v-if="agent.active"></i>
                    <i v-else class="icon icon-trash pointer"  @click="deleteAgent(agent.id)"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <v-pagination
          v-model="page"
          :pages="totalPages"
          :range-size="1"
          @update:modelValue="changePage"
          v-if="page <= totalPages && totalPages > 1"
        />
      </div>
    </div>

    <ModalNewAgent v-if="modalState.newAgent === 'on'" :enableModal="modalState.newAgent" :agent="this.agent"/>
    <div v-if="enableBackdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import VPagination from '@hennge/vue3-pagination'
import SearchForm from './SearchForm'
import ModalNewAgent from './ModalNewAgent'
import Breadcrumb from '../../shared/Breadcrumb'
import AgentApi from '@/backend/agents'

export default {
  name: 'Agents',
  components: {
    VPagination,
    Breadcrumb,
    SearchForm,
    ModalNewAgent
  },
  computed: {
    ...mapGetters({
      agents: 'agents/agentList',
      modalState: 'agents/modalState',
      currentUser: 'users/currentUser'
    }),
    enableBackdrop() {
      return Object.values(this.modalState).includes('on')
    },
    agentsList() {
      return this.agents.list
    },
    totalPages() {
      return this.agents.totalPages
    },
    paramsCurrentPage() {
      if(this.page !== 1) {
        return { page: this.page }
      }
      return {}
    },
  },
  methods: {
    ...mapActions({
      setList: 'agents/setList',
      delete: 'agents/delete'
    }),
    changePage() {
      this.setList({ ...this.paramsCurrentPage, ...this.searchParams })
    },
    formSearching(params) {
      this.searchParams = params
      this.page = 1
    },
    openModal(data) {
      this.$store.dispatch('agents/toggleModal', data)
    },
    changeStatus(id){
      AgentApi.changeStatus(id).then(() => {
        this.setList()
      }).catch()
    },
    deleteAgent(id) {
      if(confirm(this.t('agents.agents.index.messages.delete_confirm'))) {
        AgentApi.delete(id).then(() => {
          this.setList()
        }).catch()
      }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.agents'), routerName: 'Agents', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.list'), routerName: '#' }
      ],
      page: parseInt(this.$route.query.page) || 1,
      searchParams: {},
      agent: {
        email: '',
        first_name: '',
        last_name: '',
        type: 'Staff',
      }
    }
  },
  mounted() {
    this.setList()
  },
}
</script>

<style lang="scss">
  #agents {
    th.col-role {
      min-width: 50px;
    }

    th.col-action {
      min-width: 50px;
    }
  }
</style>
