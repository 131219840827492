export const ToastSettings = {
  error: {
    type: 'danger',
    transition: 'slide',
    position: 'top-right',
    showIcon: 'true'
  },

  success: {
    type: 'success',
    transition: 'slide',
    position: 'top-right',
    showIcon: 'true'
  }
}
