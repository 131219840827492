import ApiService from './api_services'

const HouseApi = {
  getList: function(params) {
    return ApiService.get('/houses', params)
  },

  get: function(houseID) {
    return ApiService.get(`/houses/${houseID}`)
  },

  create: function(houseAttributes) {
    return ApiService.post('/houses', { house: houseAttributes })
  },

  update: function(houseAttributes) {
    let houseID = houseAttributes.id
    return ApiService.put(`/houses/${houseID}`, { house: houseAttributes })
  },

  getApplications: function(houseID) {
    return ApiService.get(`/houses/${houseID}/applications`)
  },

  refuseApplicationList: function(houseId, application_ids) {
    return ApiService.post(`/houses/${houseId}/refuse_applications`,
      { application_ids: application_ids })
  },
}

export default HouseApi
