import ApiService from './api_services'

const ReportOwnerInformationApi = {
  show: function(reportId) {
    return ApiService.get(`reports/${reportId}/report_owner_information`)
  },
  signature: function(reportId) {
    return ApiService.get(`reports/${reportId}/report_owner_signature`)
  },
  createOrUpdateSignature(reportId, formData) {
    return ApiService.post(`reports/${reportId}/report_owner_signature`, formData)
  }
}

export default ReportOwnerInformationApi
