<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '~@fortawesome/fontawesome-free/css/all.css';
  @import 'assets/css/googleapisFont.css';
  @import './assets/scss/main.scss';
</style>
