<template>
  <div id="term-modal"
       class='modal fade review-modal show' role="dialog" style='display: block;'>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <h2 class="review__title">
            <i class="icon icon-page review__title__icon"></i>
            <span>Huurder verklaart:</span>
          </h2>
          <button type="button" class="i-text -danger review-modal__close" @click="$emit('hideModal')">&times;</button>
          <div class="white-box p-1 terms">
            <ul>
              <li  v-for="(paragraph, index) in term.content.replace(/\n{2,}/g, '\n').split('\n')" :key="index">
                <i class="icon icon-check-circle mr-3"></i>{{ paragraph }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import { nl } from 'date-fns/locale'

export default {
    name: 'ModalTerm',
    props: ['termType'],
    setup() {
      const { t } = useI18n()
      const locales = { nl }
      return { t, locales }
    },
    methods: {
      ...mapActions({
        findByType: 'terms/findByType'
      })
    },
    computed: {
      ...mapGetters({
        term: 'terms/termDetail'
      })
    },
    mounted() {
      this.findByType(this.termType)
    }
  }
</script>

<style>
  #term-modal li{
    list-style: initial;
  }
</style>
