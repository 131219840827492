const TOGGLE_STATUS = 'TOGGLE_STATUS'
const HIDE_SIDEBAR = 'HIDE_SIDEBAR'

const state = {
  sidebarStatus: 0
}

export const mutations = {
  [TOGGLE_STATUS](state) {
    state.sidebarStatus ^= true
  },
  [HIDE_SIDEBAR](state) {
    state.sidebarStatus = 0
  }
}
export const actions = {
  toggleSidebar({ commit }) {
    commit(TOGGLE_STATUS)
  },
  hide({ commit }) {
    commit(HIDE_SIDEBAR)
  }
}
export const getters = {
  sidebarStatus: state => state.sidebarStatus
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
