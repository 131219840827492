import ReportClientsInformation from '../../../backend/report_clients_information'

const ERROR = 'ERROR'

const state = {
  updatedSuccess: false,
  errors: {}
}

export const mutations = {
  [ERROR](state, errors) {
    state.errors = errors
  }
}

export const actions = {
  async createOrUpdate({ commit }, params) {
    await ReportClientsInformation.createOrUpdate(params.reportId, params.formData).then(() => {
      commit(ERROR, {})
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  }
}

export const getters = {
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
