import AgentApi from '../../../backend/agents'
import { subDomain } from '../../../settings/subdomain'
import router from '../../../router'

const AGENT_LIST = 'AGENT_LIST'
const CREATE_AGENT = 'CREATE_AGENT'
const TOGGLE_MODAL = 'TOGGLE_MODAL'

const state = {
  agents: {
    totalPages: 0,
    list: []
  },
  agentError: {},
  modalState: {
    newAgent: 'off'
  }
}

export const mutations = {
  [AGENT_LIST] (state, data) {
    state.agents.totalPages = data.total_pages
    state.agents.list = data.list
  },

  [CREATE_AGENT] (state, agentError) {
    state.agentError = agentError
  },

  [TOGGLE_MODAL] (state, data) {
    state.modalState[data.modalName] = data.state
  }
}

export const actions = {
  setList ({ commit }, searchParams={}) {
    return AgentApi.getList(searchParams).then(response => {
      commit(AGENT_LIST, response.data)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      router.replace({ name: 'Staffs', query: { page: searchParams.page } }).then(() => {})
    }).catch(error => {
      console.log(error)
    })
  },

  createAgent({ commit }, agentParams={}){
    agentParams['subdomain'] = subDomain()
    return AgentApi.create(agentParams).then(() => {
      commit(TOGGLE_MODAL, { modalName: 'newAgent', state: 'off' })
      router.push({ name: 'Staffs' }).then(() => {})
    }).catch((error) => {
      let errors = error.response.data.messages || error.response.data.error
      commit(CREATE_AGENT, errors)
    })
  },

  toggleModal({ commit }, data) {
    commit(TOGGLE_MODAL, data)
  }
}

export const getters = {
  agentList: state => state.agents,
  agentError: state => state.agentError,
  modalState: state => state.modalState,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
