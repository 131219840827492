<template>
  <div :class="sidebarClassNames">
    <div class="sidebar__company">
      <div class="sidebar__company__img">
        <img :src="currentUser.entity.logo_thumb_url">
      </div>
      <div class="sidebar__company__text">
        {{ currentUser.entity.name }}
      </div>
      <div class="sidebar__company__general_info">
        <div>{{ currentUser.entity.email }}</div>
        <div>{{ currentUser.entity.phone_number }}</div>
      </div>
    </div>
    <div class="sidebar__user" @mouseover="showEditBtn = true" @mouseleave="showEditBtn = false">
      <span v-if="showEditBtn" class="sidebar__user__edit_btn">
        <router-link :to="{ name: 'EditUser' }">
          <i class="icon icon-edit"></i>
        </router-link>
      </span>
      <div class="sidebar__user__img">
        <img :src="currentUser.avatar_url" />
      </div>
      <div class="sidebar__user__name">
        {{ currentUser.full_name }}
      </div>
      <div class="sidebar__user__job">
        {{ t(`sidebar.${currentUser.type}`) }}
      </div>
    </div>
    <nav class="sidebar-nav">
      <ul>
<!--        <li>-->
<!--          <a class="sidebar-nav__item" href="/"><i class="sidebar-nav__item__icon icon icon-dashboard"></i>{{ t('sidebar.dashboard') }}</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'Agents Houses' }" class="sidebar-nav__item">-->
<!--            <i class="sidebar-nav__item__icon icon icon-home"></i>{{ t('sidebar.houses') }}</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'Notifications' }" class="sidebar-nav__item">-->
<!--            <i class="sidebar-nav__item__icon icon icon-bell"></i>{{ t('sidebar.notifications') }}</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a class="sidebar-nav__item" href=""><i class="sidebar-nav__item__icon icon icon-box"></i>{{ t('sidebar.contracts') }}</a>-->
<!--        </li>-->
        <li>
          <router-link :to="{ name: 'Reports' }" class="sidebar-nav__item">
            <i class="sidebar-nav__item__icon icon icon-file-text"></i>{{ t('sidebar.reports') }}</router-link>
        </li>

        <li v-if="currentUser.type === 'Manager'">
          <router-link :to="{ name: 'Staffs' }" class="sidebar-nav__item">
            <i class="sidebar-nav__item__icon icon icon-people"></i>{{ t('sidebar.agents') }}</router-link>
        </li>

        <li v-if="currentUser.type === 'Manager'">
          <router-link :to="{ name: 'Configuration' }" class="sidebar-nav__item">
            <i class="sidebar-nav__item__icon icon icon-cog"></i>{{ t('sidebar.settings') }}</router-link>
        </li>

        <li>
          <a class="sidebar-nav__item" href="" v-on:click="signOut">
            <i class="sidebar-nav__item__icon icon icon-sign-out"></i>{{ t('sidebar.signOut') }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

export default {
    name: 'TheSideBar',
    props: [ 'currentUser' ],
    data: function() {
      return {
        showEditBtn: false,
      }
    },
    methods: {
      signOut: function (){
        this.$store.dispatch('auth/signOut')
      }
    },
    setup() {
      const { t } = useI18n()
      return { t }
    },
    computed: {
      ...mapGetters({
        sidebarStatus: 'sideBar/sidebarStatus'
      }),
      sidebarClassNames(){
        return this.sidebarStatus === 1 ? 'sidebar sidebar-sp-enabled' : 'sidebar'
      }
    }
  }
</script>

<style>
@media (max-width: 991px) {
  .sidebar-sp-enabled {
    left: 0;
  }
}
</style>
