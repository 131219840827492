<template>
  <div id="accept-modal"
       :class="enableModal ? 'modal fade review-modal show' : 'modal fade review-modal'"
       role="dialog"
       :style="enableModal ? 'display: block;' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <button type="button" class="i-text review-modal__close">&times;</button>
          <button type="button" class="i-text -danger review-modal__close" v-on:click="hideModal">&times;</button>
          <h3 class="page-title -primary">Client review</h3>
          <div class="alert alert-danger" v-if="applicationError.length > 0">
            <ul class="messages">
              <li v-for="(message, index) in applicationError" :key="index"> {{message}}</li>
            </ul>
          </div>
          <ModalHouseInfo :houseInfo="houseInfo" :clientInfo="clientInfo"/>

          <div class="review-modal__br mb-20"></div>

          <div class="review-modal__title mb-20">{{ t('agents.modalAccept.documents') }}</div>
          <div class="row mb-20">
            <div class="col-sm-6 mb-15" v-for="document in documents" :key="document.id">
              <label class="i-check">
                <i v-if="document.state === 'approved'" class="icon icon-valid i-text -success"></i>
                <i v-else class="icon icon-invalid"></i>
                <span class="ml-1">{{ document.document_type_name }}</span>
              </label>
            </div>
          </div>
          <form @submit.prevent="accept" role="form" id="approve-form">
            <div class="text-center mt-35">
              <button type="submit" class="i-btn -success">{{ t('buttons.accept') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalHouseInfo from './ModalHouseInfo'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalAccept',
  props: ['application', 'enableModal', 'documents'],
  data: function() {
    return {
      applyParams: {
        id: this.$route.params.id,
        comment: ''
      },
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  components: {
    ModalHouseInfo
  },
  computed: {
    ...mapGetters({
      applicationError: 'house_application/applicationError'
    }),
    clientInfo() {
      return this.application.client_info
    },
    houseInfo() {
      return this.application.house_info
    }
  },
  methods: {
    ...mapActions({
      acceptApplication: 'house_application/accept'
    }),
    accept() {
      this.acceptApplication(this.$route.params.id)
    },
    hideModal() {
      this.$store.dispatch('house_application/toggleModal', { modalName: 'acceptApplication', state: 'off' })
    }
  },
}
</script>
