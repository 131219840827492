<template>
  <div id="review-modal"
       :class="enableModal ? 'modal fade review-modal show' : 'modal fade review-modal'"
       role="dialog"
       :style="enableModal ? 'display: block;' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <button type="button" class="i-text -danger review-modal__close" v-on:click="hideModal">&times;</button>
          <h3 class="page-title">Client review</h3>
          <ModalHouseInfo :houseInfo="houseInfo" :clientInfo="clientInfo"/>

          <div class="review-modal__br mb-20"></div>

          <p class="i-text -gray mb-15">{{ t('agents.modalReview.need_to_resolved') }}</p>
          <select class="i-select mb-15" v-model="formReview.needResolved.title">
            <option v-for="opt in optionsNeedResolved" :key="opt"
                    :value="t(`agents.houseApplications.modalReview.${opt}`)">
              {{ t(`agents.houseApplications.modalReview.${opt}`) }}
            </option>
          </select>
          <textarea class="review-modal__content -small"
                    v-model="formReview.needResolved.problem"
                    v-bind:placeholder="t('agents.modalReview.write_the_problem_here')">
          </textarea>
          <div class="review-modal__br mb-15 mt-25"></div>

          <div class="review-modal__title mb-20">{{ t('agents.modalReview.need_corrections') }}</div>
          <div class="row mb-20">
            <div class="col-sm-6 mb-15" v-for="document in documents" :key="document.id">
              <label class="i-check">
                <input type="checkbox" name="" v-bind:value="document.id"
                       v-model="formReview.needCorrect.documentIds">
                <i class="i-check__icon"></i>
                <span>{{ document.document_type_name }}</span>
              </label>
            </div>
          </div>
          <textarea class="review-modal__content"
                    v-model="formReview.needCorrect.comment"
                    :placeholder="t('agents.modalReview.write_your_comment_here')"></textarea>

          <div class="text-center mt-40">
            <button type="button" class="i-btn -info" @click.prevent="submitReviewApplication()">
              {{ t('buttons.send_review') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHouseInfo from './ModalHouseInfo'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalReview',
  props: ['application', 'enableModal', 'documents'],
  data: function() {
    return {
      optionsNeedResolved: ['need_to_check', 'invalid_info', 'other'],
      formReview: {
        id: this.$route.params.id,
        needResolved: {
          title: '',
          problem: ''
        },
        needCorrect: {
          documentIds: [],
          comment: ''
        }
      }
    }
  },
  components: {
    ModalHouseInfo
  },
  methods: {
    hideModal() {
      this.$store.dispatch('house_application/toggleModal', { modalName: 'reviewApplication', state: 'off' })
    },
    submitReviewApplication() {
      this.$store.dispatch('house_application/review', this.formReview)
    }
  },
  computed: {
    clientInfo() {
      return this.application.client_info
    },
    houseInfo() {
      return this.application.house_info
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>
