<!-- pages 15 /16 (sign) -->
<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <h2 class="new-reports-box__title mt-04">{{ this.report_info.house_address }} - {{ this.report_info.zipcode }}</h2>
      <div class="i-text -gray-4 font-bold mt-05">
        {{ this.report_info.house_address }}
        <br>
        {{ this.report_info.city }}
      </div>

      <div class="i-text -gray-3 mb-40 mt-40 mb-md-25 mt-md-25">
        {{ t('agents.reports.new.term_of_use_text') }}
        <a href="javascript:void(0)" class="smooth i-text -link -info font-italic" @click="openModalTerm">
          {{ t('agents.reports.new.term_of_use')}}</a>
      </div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>

      <h1 class="title mt-3 mb-1 font-weight-bold section-title">{{ t('agents.stepSignature.ownerAgent') }}</h1>
      <div class="row">
        <div class="col-md-6">
          <div class="new-reports-box -bg-white">
            <div class="row gutter-xl-15 gutter-sm-10" v-if="report_owner_signature_attributes">
              <div class="col-12">
                <input type="hidden"
                       v-model='report_owner_signature_attributes.id'>

                <label>{{ t('agents.reports.new.enter_or_select_the_end_date_of_contact') }}</label>
                <VueDatePicker
                    class="custom-datepicker" locale="nl"
                    v-model="ownerSignatureParams.signed_date"
                    :format="t('datepicker.format.date')" :enable-time-picker="false"
                    :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />
                <InvalidFieldMessage v-bind:errorMessages="errorOwnerSignature"
                                     v-bind:field="`signed_date`"/>
              </div>

              <div class="col-12 mt-3">
                <label>{{ isPreInspectionReport ? t('agents.reports.new.behalf_landlord') : t('agents.reports.new.owner_name') }}</label>
                <input type="text" name="full_name" class="i-input"
                       v-model="ownerSignatureParams.full_name"/>
                <InvalidFieldMessage v-bind:errorMessages="errorOwnerSignature"
                                     v-bind:field="`full_name`"/>
              </div>

              <div class="col-12 mt-3 signature">
                <div>
                  <vue-drawing-canvas
                      :ref="`VueCanvasDrawingOwnerSignature`"
                      canvasId="owner-signature"
                      :lineWidth="signatureCavanConfig.lineWidth"
                      v-model:image="ownerSignatureParams.signature_content"
                      :width="cavanWidth"/>
                  <button type="button" @click.prevent="$refs[`VueCanvasDrawingOwnerSignature`].reset()">
                    <i class="icon icon-eraser"></i>
                    {{ t('agents.icons.eraser') }}
                  </button>
                </div>
                <InvalidFieldMessage v-bind:errorMessages="errorOwnerSignature"
                                     field='signature_content'/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="mt-3 mb-1 font-weight-bold section-title">{{ t('agents.stepSignature.hirer') }}</h1>
      <div class="row">
        <div class="col-md-6 mb-md-15" v-for="(client, index) in clientsInformation" :key="client.id">
          <div class="col-12 signature mt-3">
            <div class="cavan-signature">
            </div>
          </div>

          <div class="new-reports-box -bg-white" v-if='hirerSignatureParams[client.id]'>
            <div class="row gutter-xl-15 gutter-sm-10">
              <div class="col-12">
                <input type="hidden"
                       v-model='hirerSignatureParams[client.id]["report_hirer_signature_attributes"]["id"]'/>

                <label>{{ t('agents.reports.new.enter_or_select_the_end_date_of_contact') }}</label>
                <VueDatePicker
                    name="signed_date" locale="nl"
                    class="custom-datepicker"
                    v-model="hirerSignatureParams[client.id]['report_hirer_signature_attributes']['signed_date']"
                    :format="t('datepicker.format.date')" :enable-time-picker="false"
                    :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />
                <InvalidFieldMessage v-bind:errorMessages="errorHirerSignature"
                                     v-bind:field="`report_client_informations[${index}].report_hirer_signature.signed_date`"/>
              </div>

              <div class="col-12 mt-3">
                <label>{{ t('agents.reports.new.hirer_name') }}</label>
                <input type="text" name="" class="i-input"
                       v-model="hirerSignatureParams[client.id]['report_hirer_signature_attributes']['full_name']"/>
                <InvalidFieldMessage v-bind:errorMessages="errorHirerSignature"
                                     v-bind:field="`report_client_informations[${index}].report_hirer_signature.full_name`"/>
              </div>
              <div class="col-12 signature mt-3">
                <div class="cavan-signature">
                  <vue-drawing-canvas
                      ref="VueCanvasDrawingHirerSignature"
                      :canvasId="`hirer-signature-${client.id}`"
                      :lineWidth="signatureCavanConfig.lineWidth"
                      v-model:image="hirerSignatureParams[client.id]['report_hirer_signature_attributes']['signature_content']"
                      :width="cavanWidth" :height="cavanHeight"/>
                  <button type="button" @click.prevent="$refs['VueCanvasDrawingHirerSignature'][index].reset()">
                    <i class="icon icon-eraser"></i>
                    {{ t('agents.icons.eraser') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-report-line mb-20 mt-20">
        <div class="row gutter-10 justify-content-center">
          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -white -block pl-03 pr-03" type="button"
                    @click="navigateTo('FormReportStep5', currentReportId)">
              <i class="fa fa-long-arrow-alt-left mr-08 ml-0 mt-0"></i> {{ t('buttons.back') }}
            </button>
          </div>

          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -info -block pl-03 pr-03" type="button" @click="submit">
              {{ t('buttons.finish') }} <i class="fa fa-long-arrow-alt-right ml-08 mr-0 mt-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalTerm v-if="displayModalTerm" @hideModal="hideModal" :termType="termType"></ModalTerm>
    <div v-if="displayModalTerm" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import Breadcrumb from '../../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import InvalidFieldMessage from '../../../common/InvalidFieldMessage'
import { nl } from 'date-fns/locale'
import currentReport from '@/mixins/currentReport'
import reportNavigationButtonMixin from '@/mixins/reportNavigationButtonMixin'
import VueDrawingCanvas from 'vue-drawing-canvas'
import ModalTerm from './_ModalTerm'
import typeOfReport from '@/mixins/typeOfReport'

export default {
  name: 'ReportStep6',
  mixins: [currentReport, reportNavigationButtonMixin, typeOfReport],
  components: {
    Breadcrumb,
    InvalidFieldMessage,
    VueDrawingCanvas,
    ModalTerm
  },
  setup() {
    const { t } = useI18n()
    const locales = { nl }
    return { t, locales }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: '#' }
      ],
      previewImage: null,
      report_owner_signature_attributes: { full_name: '', signature_content: '' },
      report_info: {},
      cavanWidth: 200,
      cavanHeight: 400,
      hirerSignatureEditing: {},
      ownerSignatureEditing: false,
      signatureCavanConfig: {
        lineWidth: 1
      },
      displayModalTerm: false,
      hirerSignatureParams: {},
      ownerSignatureParams: { signed_date: new Date() }
    }
  },
  computed: {
    ...mapGetters({
      filesUploaded: 'commonUploadImage/files',
      reportErrors: 'reports/reportErrors',
      clientsInformation: 'reports/clientsInformation',
      detailsOwner: 'reportOwnerInformation/details',
      ownerSignature: 'reportOwnerInformation/signature',
      errorOwnerSignature: 'reportOwnerSignature/errors',
      errorHirerSignature: 'reportSignature/errors'
    }),
    termType() {
      return `report_${ this.reportDetails.type }`
    }
  },
  methods: {
    ...mapActions({
      getClientsInformation: 'reports/clientsInformation',
      createReport: 'reports/createReport',
      createOrUpdateHirerSignature: 'reportSignature/createOrUpdateClientSignature',
      getDetailsOwner: 'reportOwnerInformation/show',
      getOwnerSignatureDetails: 'reportOwnerInformation/getSignatureDetails',
      createOrUpdateOwnerSignature: 'reportOwnerSignature/createOrUpdateOwnerSignature',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      turnOffLoadingPage: 'loadingPage/turnOffLoadingPage'
    }),
    async submit() {
      this.turnOnLoading()
      await this.createOrUpdateOwnerSignature({ reportId: this.currentReportId, formData: this._formDataOwnerSignature() })
      if(Object.keys(this.errorOwnerSignature).length > 0){
        this.turnOffLoadingPage()
        return false
      }

      await this.createOrUpdateHirerSignature({ reportId: this.currentReportId, formData: this._formDataHirer() })
      if(Object.keys(this.errorHirerSignature).length > 0) {
        this.turnOffLoadingPage()
        return false
      }

      let params = {
        id: this.currentReportId,
        completed_step: this.currentStep
      }
      await this.createReport(params)
      this.turnOffLoadingPage()
    },
    editSignature(type, index){
      if(type==='hirer') {
        this.hirerSignatureEditing[index] = true
      } else {
        this.ownerSignatureEditing = true
      }
    },
    openModalTerm(){
      this.displayModalTerm = true
    },
    hideModal() {
      this.displayModalTerm = false
    },
    _formDataHirer() {
      let formData = new FormData()

      let self = this
      Object.keys(this.hirerSignatureParams).forEach((key, index) => {
        let _hirer = self.hirerSignatureParams[key]
        let _hirerAttributes = _hirer.report_hirer_signature_attributes
        formData.append(`reports[report_client_informations_attributes][${index}][id]`, _hirer.id)
        formData.append(`reports[report_client_informations_attributes][${index}][report_hirer_signature_attributes][id]`, _hirerAttributes.id)
        formData.append(`reports[report_client_informations_attributes][${index}][report_hirer_signature_attributes][full_name]`, _hirerAttributes.full_name || '')
        formData.append(`reports[report_client_informations_attributes][${index}][report_hirer_signature_attributes][signature_content]`, _hirerAttributes.signature_content || '')
        formData.append(`reports[report_client_informations_attributes][${index}][report_hirer_signature_attributes][signed_date]`, _hirerAttributes.signed_date || '')
      })
      return formData
    },
    _formDataOwnerSignature() {
      let formData = new FormData()
      formData.append('report_owner_signatures[full_name]', this.ownerSignatureParams.full_name)
      formData.append('report_owner_signatures[signed_date]', this.ownerSignatureParams.signed_date)
      formData.append('report_owner_signatures[signature_content]', this.ownerSignatureParams.signature_content)
      return formData
    }
  },
  async mounted() {
    this.$emit('header-title', this.t('breadcrumb.reports'))

    await this.getReportInfo(this.currentReportId)
    await this.getClientsInformation(this.currentReportId)
    await this.getOwnerSignatureDetails(this.currentReportId)
    await this.getDetailsOwner(this.currentReportId)
    this.report_info = this.reportDetails.report_basic_information
    this.clientsInformation.forEach(_client => {
      this.hirerSignatureParams[_client.id] = {}
      this.hirerSignatureParams[_client.id]['id'] = _client.id
      let signature = _client.report_hirer_signature
      this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes'] = {}
      if(signature) {
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['id'] = signature.id || ''
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['full_name'] = signature.full_name || ''
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['signature_content'] = signature.signature_content || ''
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['signed_date'] = new Date(signature.signed_date) || ''
      } else {
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['id'] = ''
        this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['full_name'] = _client.full_name
      }
      this.hirerSignatureParams[_client.id]['report_hirer_signature_attributes']['signed_date'] = new Date()
    })

    if(this.ownerSignature) {
      this.ownerSignatureParams = this.ownerSignature
      this.ownerSignatureParams['signed_date'] = new Date()
    } else {
      this.ownerSignatureParams.id = ''
      this.ownerSignatureParams.full_name = this.detailsOwner.owner_full_name
    }

    this.cavanWidth = document.getElementsByClassName('signature')[0].offsetWidth - 50
  }
}
</script>

<style type="scss">
  .cavan-signature {
    width: 100%;
  }
</style>
