import ApiService from './api_services'

const ReportConfigurationApi = {
  create: function(params) {
    return ApiService.post('/report_configurations', params)
  },
  list: function () {
    return ApiService.get('/report_configurations')
  }
}

export default ReportConfigurationApi
