<template>
  <div class="white-box pb-1 mb-30">
    <h2 class="page-title -primary">{{ t('agents.review.document')}}</h2>
    <p class="i-text -gray mb-25"><b>{{ t('agents.review.document_desc')}}</b></p>

    <div class="row">
      <div class="col-sm-6" v-for="document in documents" :key="document.id">
        <div class="document">
          <h3 class="document__label"
              v-on:click="enableModal({ modalName: 'documentView', state: 'on',
                                              documentId: document.id })">
            {{ document.document_type_name }}
          </h3>
          <a class="document__name" href="javascript:void(0)"
             v-on:click="enableModal({ modalName: 'documentView', state: 'on',
                                              documentId: document.id })">
            {{ document.file_name }}
          </a>
          <div class="row gutter-20">
            <div class="col-auto">
              <label class="i-check">
                <i v-if="document.file_url" class="i-check__icon checked"></i>
                <i v-else class="i-check__icon"></i>
                {{ t('agents.houseApplications.documentLists.upload') }}
              </label>
            </div>
            <div class="col-auto">
              <label class="i-check">
                <i v-if="isChecked(document)" class="i-check__icon checked"></i>
                <i v-else class="i-check__icon"></i>
                {{ t('agents.houseApplications.documentLists.checked') }}
              </label>
            </div>
            <div v-if="document.state==='rejected'" class="document__message">
              {{ t('agents.houseApplications.documentLists.messageNotPassRequired') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'DocumentList',
  props: ['documents'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    enableModal(data) {
      this.$store.dispatch('house_application/toggleModal', data)
    },
    isChecked(document){
      return document.state === 'rejected' || document.state === 'approved'
    }
  }
}

</script>
