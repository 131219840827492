import ApiService from './api_services'

export function currentUserProfile () {
  return ApiService.get('/users/me')
}

const UserApi = {
  profile: function (){
    return ApiService.get('/users/profiles')
  },

  updateProfile: function (formData) {
    return ApiService.put('/users/profiles', formData)
  }
}

export default UserApi
