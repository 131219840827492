export function subDomain() {
  const host = window.location.host
  const parts = host.split('.')
  const domainLength = 3

  if(parts.length < domainLength || parts[0] === 'www') {
    return ''
  } else {
    return parts[0]
  }
}
