import ReportCommentApi from '../../../backend/report_comment'

const REPORT_COMMENTS = 'REPORT_COMMENTS'
const ERROR = 'ERROR'

const state = {
  reportComments: [],
  errors: []
}

export const mutations = {
  [REPORT_COMMENTS](state, reportComments) {
    state.reportComments = reportComments
  },
  [ERROR](state, errors) {
    state.errors = errors
  }
}

export const actions = {
  async createOrUpdate({ commit }, { reportId: reportId, formData: formData }) {
    await ReportCommentApi.createOrUpdate(reportId, formData).then(() => {
      commit(ERROR, [])
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  },
  async getReportComments( { commit }, reportId) {
    ReportCommentApi.getReportComments(reportId).then(response => {
      commit(REPORT_COMMENTS, response.data)
    }).catch(error => {
      console.error(error)
    })
  },
}

export const getters = {
  reportComments: state => state.reportComments,
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
