<template>
  <div id="house-detail" class="house-detail">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      <div class="row justify-content-between align-items-center mb-45 mb-md-25">
        <div class="col-xl-5 mt-xl-10">
          <h1 class="page-title -primary">
            {{ t('agents.detail.title') }} - {{ houseInfo.id }}
          </h1>
          <div class="i-text house-detail__address">{{ houseInfo.full_address }}</div>
        </div>
        <div class="col-xl-auto mt-xl-20">
          <SearchForm/>
        </div>
      </div>

      <div class="row gutter-24 gutter-sm-10">
        <div class="col-md-3 col-6 mb-20 mb-sm-10">
          <div class="house-detail__item">
            <div class="house-detail__item__label">
              <i class="icon icon-dollar"></i> {{ t('agents.detail.price') }}
            </div>
            <div class="house-detail__item__ct">
              {{ houseInfo.rent_price }}
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-20 mb-sm-10">
          <div class="house-detail__item">
            <div class="house-detail__item__label">
              <i class="icon icon-key"></i> {{ t('agents.detail.start') }}
            </div>
            <div class="house-detail__item__ct">
              {{ houseInfo.start_hiring_date }}
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-20 mb-sm-10">
          <div class="house-detail__item">
            <div class="house-detail__item__label">
              <i class="icon icon-contact"></i> {{ t('agents.detail.length') }}
            </div>
            <div class="house-detail__item__ct">
              {{ houseInfo.contract_length }} months
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-20 mb-sm-10">
          <div class="house-detail__item">
            <div class="house-detail__item__label">
              <i class="icon icon-people"></i> {{ t('agents.detail.people') }}
            </div>
            <div class="house-detail__item__ct">
              {{ houseInfo.max_people_allowed }}
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-between align-items-center mt-15 mt-sm-25">
        <div class="col-sm-auto mb-15">
          <h2 class="house-detail__s-title">{{ t('agents.detail.applied_customers') }}</h2>
        </div>
        <div class="col-sm-auto mb-15">
          <div class="row gutter-15">
            <div class="col-sm-auto col-6" v-if="!this.houseInfo.application_approved">
              <button class="i-btn -danger -block" v-on:click="refuseListApplied" :disabled="disabledBtnRejectAll">
                {{ t('agents.detail.reject') }}
              </button>
            </div>
            <div class="col-sm-auto col-6">
              <button class="i-btn -dark -block">{{ t('agents.detail.export') }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="house-detail__tbl mb-50">
        <table>
          <thead>
            <tr>
              <th>
                <div class="house-detail__tbl__th">
                  <label class="i-check -single -inline">
                    <input type="checkbox" name="selectAll"
                           v-model="allClientSelected">
                    <i class="i-check__icon"></i>
                  </label>
                </div>
              </th>
              <th>
                <div class="house-detail__tbl__th">{{ t('agents.detail.name') }}</div>
              </th>
              <th>
                <div class="house-detail__tbl__th justify-content-center text-center">{{ t('agents.detail.time_waiting') }}</div>
              </th>
              <th>
                <div class="house-detail__tbl__th justify-content-center text-center" v-html="t('agents.detail.documents_approved')"></div>
              </th>
              <th>
                <div class="house-detail__tbl__th justify-content-center text-center">{{ t('agents.detail.status') }}</div>
              </th>
              <th>
                <div class="house-detail__tbl__th justify-content-center text-center">{{ t('agents.detail.action') }}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="application in houseApplications" :key="application.id"
                :class="application.state === 'approved' ? '-approved' : ''">
              <td>
                <label class="i-check -single -inline">
                  <input type="checkbox" name="appliedClientId" :value="application.id"
                         v-model="selectedAppliedClient">
                  <i class="i-check__icon"></i>
                </label>
              </td>
              <td> {{ application.client_info.name }} </td>
              <td class="text-center">{{ application.apply_at }}</td>
              <td class="text-center">{{ application.number_documents_approved }}</td>
              <td class="text-center">
                <div :class="stateClassName(application)">
                  {{ application.state_displayed }}
                </div>
              </td>
              <td class="text-center">
                <router-link class="house-detail__tbl__review"
                             :to="{ name: 'Agent House Application Show',
                                    params: { houseId: this.houseID, id: application.id } }">
                  {{ t('agents.detail.review') }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../shared/Breadcrumb'
import SearchForm from './SearchForm'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Detail',
  components: {
    Breadcrumb,
    SearchForm
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.houses'), routerName: 'Agents Houses', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.detail'), routerName: '#' }
      ],
      selectedAppliedClient: [],
      allClientSelected: false,
      disabledBtnRejectAll: true,
    }
  },
  computed: {
    ...mapGetters({
      houseInfo: 'houses/houseInfo',
      houseApplications: 'houses/applications'
    }),
    houseID() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions({
      getHouseInfo: 'houses/getHouseInfo',
      fetchApplications: 'houses/fetchApplications',
      refuseApplications: 'houses/refuseApplications'
    }),
    stateClassName(appliedClient) {
      let state = appliedClient.state
      switch (state){
        case 'pending':
          return 'i-badge -gray'
        case 'approved':
          return 'i-badge -primary'
        case 'refused':
          return 'i-badge -danger'
        case 'reviewing':
          return 'i-badge -warning'
        case 'accepted':
          return 'i-badge -success'
        default:
          return 'i-badge -gray'
      }
    },
    refuseListApplied() {
      this.refuseApplications({ houseId: this.houseID,
        refuse_ids: this.selectedAppliedClient })
    }
  },
  watch: {
    allClientSelected: function (){
      if(this.allClientSelected) {
        this.selectedAppliedClient = this.houseApplications.map(item => item.id)
      } else {
        this.selectedAppliedClient = []
      }
    },
    selectedAppliedClient: function () {
      this.disabledBtnRejectAll = this.selectedAppliedClient.length <= 0
    }
  },
  mounted() {
    this.getHouseInfo(this.houseID)
    this.fetchApplications(this.houseID)
  }
}
</script>
