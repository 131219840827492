<template>
  <div class="d-flex">
    <form class="search-form">
      <input class="search-form__input" v-model="searchParams" :readonly="loadingPageStatus === 'on'"
             v-bind:placeholder="t('agents.searchForm.placeholder')" />
      <button class="search-form__submit"><i class="icon icon-search"></i></button>
    </form>
    <i class="icon icon-loading-dual-ring icon-sub-input" v-if="searching"></i>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SearchForm',
  data () {
    return {
      searchParams: '',
      searching: false,
      timeout: null
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    ...mapActions({
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      reportList: 'reports/setList'
    })
  },
  computed: {
    ...mapGetters({
      loadingPageStatus: 'loadingPage/status'
    })
  },
  mounted() {
    this.searchParams = this.$route.query && this.$route.query.report_basic_information_house_address_or_report_basic_information_city_cont
  },
  watch: {
    searchParams: function (value){
      clearTimeout(this.timeout)

      let self = this
      this.timeout = setTimeout(async function () {
        if (value !== undefined && (value.length >= 3)) {
          self.searching = true
          let searchQuery = { report_basic_information_house_address_or_report_basic_information_city_cont: value }
          self.$emit('form-searching', searchQuery)
          await self.reportList(searchQuery)
          self.searching = false
        } else if (value !== undefined && value.length === 0) {
          self.searching = true
          await self.reportList()
          self.searching = false
        }
      }, 300)
    },
    '$route': function (after, before) {
      if(before.name === 'Reports' && after.name === 'Reports' && Object.keys(after.query).length === 0) {
        this.searchParams = ''
      }
    }
  }
}
</script>
