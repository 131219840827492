<template>
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404"></div>
      <h1>404</h1>
      <h2>{{ t('page404.title') }}</h2>
      <p>{{ t('page404.message') }}</p>
      <router-link :to="{ name: 'Home' }" class="more-link">{{ t("page404.homePage") }}</router-link>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'Page404',
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>
