<template>
  <div class="-bg-white rounded">
    <form role="form" class="new-reports-box -bg-white mt-60 mt-lg-40 mt-md-30">
      <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.entities.editPage.title') }}</h2>
      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.name') }}</label>
          <input type="text" name="owner_full_name" class="i-input"
                 v-model='entity.name'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="name"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.email') }}</label>
          <input type="text" name="email" class="i-input"
                 v-model='entity.email'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="email"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.phone_number') }}</label>
          <input type="text" name="phone_number" class="i-input"
                 v-model='entity.phone_number'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="phone_number"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.kvk_number') }}</label>
          <input type="text" name="kvk_number" class="i-input"
                 v-model='entity.kvk_number'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="kvk_number"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.address') }}</label>
          <input type="text" name="address" class="i-input"
                 v-model='entity.address'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="address"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.nvm') }}</label>
          <input type="text" name="nvm" class="i-input"
                 v-model='entity.nvm'>
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="nvm"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <label class="px-2 align-middle sp-nowrap">{{ t('agents.entities.editPage.logo') }}</label><br>
          <input type="file" id="file" v-on:change="handleLogoUpload($event)"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-sm-6 col-md-3 mb-sm-10">
          <router-link :to="{ name: 'Reports' }" class="i-btn -gray w-100 w-sm-auto btn-sm">
            Annuleren</router-link>
        </div>
        <button class="i-btn w-sm-auto col-sm-6 col-md-3 mx-1" type="button" @click="submit">
          {{ t('buttons.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import EntityApi from '@/backend/entity'
import router from '@/router'
import InvalidFieldMessage from '@/views/common/InvalidFieldMessage'
import { createToast } from 'mosha-vue-toastify'
import { ToastSettings } from '@/settings/toast'

export default {
  name: 'EntityConfiguration',
  data: function () {
    return {
      entity: {},
      errorValidation: {}
    }
  },
  components: { InvalidFieldMessage },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters({
      entityProfile: 'entities/profiles'
    })
  },
  methods: {
    ...mapActions({
      updateProfiles: 'entities/updateProfiles',
      entityInfo: 'entities/getProfiles',
      getInfoUser: 'users/getUserProfile'
    }),
    submit(){
      let formData = new FormData()
      if( this.entity.logo ){
        formData.append('entity[logo]', this.entity.logo)
      }
      formData.append('entity[name]', this.entity.name)
      formData.append('entity[email]', this.entity.email)
      formData.append('entity[phone_number]', this.entity.phone_number)
      formData.append('entity[address]', this.entity.address)
      formData.append('entity[kvk_number]', this.entity.kvk_number)
      formData.append('entity[nvm]', this.entity.nvm)
      let self = this
      EntityApi.updateProfile(formData).then(() => {
        self.getInfoUser()
        createToast(this.t('messages.entityUpdateSuccess'), ToastSettings.success)
      }).catch(error => {
        this.errorValidation = error.response.data.messages
      })
    },
    handleLogoUpload(event){
      this.entity.logo = event.target.files[0]
    }
  },
  async mounted() {
    await this.entityInfo()
    await this.getInfoUser()
    this.entity = this.entityProfile
    if(!this.$can('edit', 'MyEntity')) {
      await router.push({ name: 'Reports' })
    }
  }
}
</script>
