import ApiService from './api_services'

const TermsApi = {
  termList: function() {
    return ApiService.get('/terms')
  },
  getDetails: function (id) {
    return ApiService.get(`/terms/${id}`)
  },
  update: function (termAttributes) {
    let termID = termAttributes.id
    return ApiService.put(`/terms/${termID}`, { term: termAttributes })
  },
  create: function (termAttributes) {
    return ApiService.post('/terms', { term: termAttributes })
  },
  findByType: function(type) {
    let formData = { type: type }
    return ApiService.get('/terms/find_by', formData)
  }
}

export default TermsApi
