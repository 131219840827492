import NotificationApi from '../../../backend/notifications'

const NOTIFICATIONS = 'NOTIFICATIONS'
const LOAD_MORE = 'LOAD_MORE'
const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'

const state = {
  notificationList: {
    list: [],
    currentPage: 1,
    totalPages: 1
  },
}

export const mutations = {
  [NOTIFICATIONS] (state, data) {
    state.notificationList.list = data.list
    state.notificationList.currentPage = data.current_page
    state.notificationList.totalPages = data.total_pages
  },
  [LOAD_MORE] (state, notificationsByPage) {
    state.notificationList.list = state.notificationList.list.concat(notificationsByPage.list)
  },
  [UPDATE_CURRENT_PAGE] (state, newPage) {
    state.notificationList.currentPage = newPage
  }
}

const actions = {
  setList({ commit }, params = {}) {
    NotificationApi.getList(params).then(response => {
      commit(NOTIFICATIONS, response.data)
    }).catch(error => {
      console.error(error)
    })
  },
  loadMore( { commit }, params) {
    NotificationApi.getList(params).then(response => {
      commit(LOAD_MORE, response.data)
      commit(UPDATE_CURRENT_PAGE, params['page'])
    }).catch(error => {
      console.error(error)
    })
  }
}

export const getters = {
  list: state => state.notificationList,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
