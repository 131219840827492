{
  "signIn": {
    "title": "Estatus",
    "sign_in": "Inloggen",
    "name": "Naam",
    "password": "Wachtwoord",
    "forget_password": "Wachtwoord vergeten?",
    "dont_have_account": "Nog geen account? Registreren",
    // "password": "Password",
  }
}
