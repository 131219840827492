<template>
  <div id="document-view-modal"
       :class="enableModal ? 'modal fade document-modal show' : 'modal fade document-modal'"
       :style="enableModal ? 'display: block;' : ''"
       role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <button type="button" class="i-text document-modal__close" v-on:click="hideModal">&times;</button>

          <div class="document-modal__head">
            <h3 class="page-title -primary">{{ document.document_type_name }}</h3>
            <a class="smooth document-modal__file" href="#" title="">
              {{ document.file_name }}
              <i class="icon icon-download"></i>
            </a>
          </div>

          <div class="document-modal__ct">
            <iframe class="document-modal__frame" :src="document.file_url"></iframe>
            <textarea class="document-modal__content" v-model="documentParams.comment"></textarea>
          </div>

          <div class="row gutter-15 gutter-sm-10 mt-45 justify-content-center">
            <div class="col-sm-12 document__message" v-if="eventError">
              {{ eventError }}
            </div>

            <div class="col-md-auto col-4">
              <a class="i-btn -block -warning pl-sm-10 pr-sm-10" v-on:click="review('review')" title="">{{
                  t('buttons.review')
                }}</a>
            </div>
            <div class="col-md-auto col-4">
              <a class="i-btn -block -danger pl-sm-10 pr-sm-10" v-on:click="review('reject')" title="">
                {{ t('buttons.reject') }}</a>
            </div>
            <div class="col-md-auto col-4">
              <a class="i-btn -block -primary pl-sm-10 pr-sm-10" v-on:click="review('approve')" title="">
                {{ t('buttons.approve') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalDocumentView',
  props: ['enableModal'],
  data: function() {
    return {
      documentParams: {
        id: this.openingDocumentId
      }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters({
      openingDocumentId: 'house_application/openingDocumentId',
      document: 'documents/document',
      eventError: 'documents/eventError'
    })
  },
  methods: {
    ...mapActions({
      getDocument: 'documents/getDocument'
    }),
    hideModal() {
      this.$store.dispatch('house_application/toggleModal', { modalName: 'documentView', state: 'off' })
    },
    review(action) {
      this.documentParams.id = this.openingDocumentId
      this.documentParams.action = action
      this.$store.dispatch('documents/review', this.documentParams)
    }
  },
  async mounted() {
    await this.openingDocumentId
    this.getDocument(this.openingDocumentId)
  }
}
</script>
