import ApiService from './api_services'

const ClientAPi = {
  getReviewReceivedList: function (id){
    return ApiService.get(`/clients/${id}/reviews_received`)
  },

  reviewClient: function(id, reviewAttributes) {
    return ApiService.post(`/clients/${id}/review`, { review: reviewAttributes })
  },
}

export default ClientAPi
