import ApiService from './api_services'

const ReportBasicInformationApi = {
  getInformation: function(reportId) {
    return ApiService.get(`reports/${reportId}/report_basic_information`)
  },

  createOrUpdate: function (reportId, params) {
    return ApiService.post(`reports/${reportId}/report_basic_information`, params)
  }
}

export default ReportBasicInformationApi
