import 'bootstrap'
import 'popper.js' // import 'bootstrap/dist/js/bootstrap.min';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
// import moment from 'moment'
import ApiService from './backend/api_services'
import AuthService from './services/auth_service'
import { subDomain } from './settings/subdomain'
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import { abilitiesPlugin } from '@casl/vue'
import ability from './services/ability'
// DateTime picker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment-timezone'

moment.locale('nl')
moment.defineLocale('nl', {})
moment.tz.setDefault('Europe/London')

// Set the base URL of the API
const API_URL = `http://${subDomain()}.${process.env.VUE_APP_DOMAIN}/api/v1`
ApiService.init(API_URL)
if (AuthService.getToken()) {
  ApiService.setHeader()
}

const app = createApp(App).use(i18n).use(store).use(router).use(moment)
app.use(moshaToast)
app.use(abilitiesPlugin, ability(), {
  useGlobalProperties: true
})
app.component('VueDatePicker', VueDatePicker)

app.mount('#app')
