<template>
  <div class="main-container">
    <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
    <div class="row justify-content-between align-items-center mb-35 mb-md-25">
      <div class="col-md-5 mt-md-10">
        <div class="d-flex align-items-center">
          <h1 class="page-title -primary">
            {{ t('agents.review.title') }}
          </h1>
          <label :class="`badge badge-${labelState} font-15 ml-2`" v-if="showLabelState">{{ application.state }}</label>
        </div>
      </div>
      <div class="col-md-auto mt-md-20" v-if="showBtnActions">
        <div class="row gutter-15 gutter-sm-10">
          <div class="col-md-auto col-4" v-if="application.state !== 'approved'">
            <a class="i-btn -block -warning"
               v-on:click="openModal({ modalName: 'reviewApplication', state: 'on' })">
              {{ t('buttons.review') }}
            </a>
          </div>
          <div class="col-md-auto col-4" v-if="application.state !== 'approved'">
            <a class="i-btn -block -danger pl-sm-10 pr-sm-10"
               v-on:click="openModal({ modalName: 'refuseApplication', state: 'on' })">
              {{ t('buttons.reject') }}
            </a>
          </div>
          <div class="col-md-auto col-4">
            <a class="i-btn -block -primary" v-if="application.state !== 'approved'"
               v-on:click="openModal({ modalName: 'approveApplication', state: 'on' })"
               :class="allDocumentsAccepted ? '' : 'disabled'">
              {{ t('buttons.approve') }}</a>
            <a class="i-btn -block -success pl-sm-10 pr-sm-10" v-else-if="application.state === 'approved'"
               v-on:click="openModal({ modalName: 'acceptApplication', state: 'on' })">
              {{ t('buttons.accept') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <ClientInformation :generalInfo="generalInfo()"></ClientInformation>
        <DocumentList :documents="documentList"></DocumentList>
      </div>
      <div class="col-xl-5">
        <CountDocumentApproved v-if="documentList.length > 0"
                               :documents="documentList"></CountDocumentApproved>
        <ReviewReceived v-if="clientId" :clientId="clientId"></ReviewReceived>
      </div>
    </div>

    <ModalReject v-if="modalState.refuseApplication === 'on'" :application="application"
                 :enableModal="modalState.refuseApplication"/>
    <ModalReview v-if="modalState.reviewApplication === 'on'" :application="application"
                 :documents="documentList"
                 :enableModal="modalState.reviewApplication"/>
    <ModalApprove v-if="modalState.approveApplication === 'on'" :application="application"
                 :documents="documentList"
                 :enableModal="modalState.approveApplication"/>
    <ModalAccept v-if="modalState.acceptApplication === 'on'" :application="application"
                  :documents="documentList"
                  :enableModal="modalState.acceptApplication"/>
    <ModalDocumentView v-if="modalState.documentView === 'on'"
                       :enableModal="modalState.documentView"/>
    <div v-if="enableBackdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import Breadcrumb from '../../shared/Breadcrumb'
import ModalReject from './ModalReject'
import ModalReview from './ModalReview'
import ModalAccept from './ModalAccept'
import ModalApprove from './ModalApprove'
import ModalDocumentView from './ModalDocumentView'
import CountDocumentApproved from './_CountDocumentApproved'
import { useI18n } from 'vue-i18n'
import ClientInformation from './_ClientInformation'
import DocumentList from './_DocumentList'
import ReviewReceived from './_ReviewsReceived'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Show',
  components: {
    ReviewReceived,
    DocumentList,
    ClientInformation,
    Breadcrumb,
    ModalReject,
    ModalReview,
    ModalApprove,
    ModalAccept,
    ModalDocumentView,
    CountDocumentApproved
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.houses'), routerName: 'Agents Houses' },
        { name: this.t('breadcrumb.review'), routerName: '#' }
      ],
    }
  },
  methods: {
    ...mapActions({
      getInfo: 'house_application/getHouseApplication',
      getDocumentList: 'house_application/getDocumentList',
      getClientReviewReceivedList: 'clients/getReviewReceivedList',
      toggleModal: 'house_application/toggleModal'
    }),
    generalInfo() {
      if(this.application.client_info){
        return {
          clientName: this.application.client_info.full_name,
          description: this.application.description
        }
      }
    },
    openModal(data) {
      this.$store.dispatch('house_application/toggleModal', data)
    }
  },
  computed: {
    ...mapGetters({
      application: 'house_application/application',
      documentList: 'house_application/documentList',
      modalState: 'house_application/modalState'
    }),
    enableBackdrop() {
      return Object.values(this.modalState).includes('on')
    },
    applicationId() {
      return this.$route.params.id
    },
    clientId() {
      if( this.application.client_info) {
        return this.application.client_info.id
      }
      return null
    },
    showBtnActions() {
      return !['accepted', 'refused'].includes(this.application.state)
    },
    showLabelState() {
      return !['reviewing', 'pending'].includes(this.application.state)
    },
    labelState() {
      if(this.application.state === 'refused') {
        return 'danger'
      } else if(this.application.state === 'approved') {
        return 'info'
      } else {
        return 'primary'
      }
    },
    allDocumentsAccepted() {
      return this.documentList.length > 0 && this.documentList.filter(document => document.state !== 'approved').length === 0
    },
  },
  async mounted() {
    await this.getInfo(this.applicationId)
    this.getDocumentList(this.applicationId)
  }
}
</script>
