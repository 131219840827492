<template>
  <div class="-bg-white rounded">
    <form role="form" class="new-reports-box -bg-white mt-60 mt-lg-40 mt-md-30">
      <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.report_config.title') }}</h2>
      <div class="row gutter-xl-15 gutter-sm-10">
        <div class="col-12">
          <form @submit="submit">
            <div v-for="(reportType, index) in reportTypes" v-bind:key="index" class="form-group mt-3">
              <label class="px-2 align-middle sp-nowrap">{{ t(`agents.reports.types.${reportType}`) }}</label>
              <input type="text" name="email" class="i-input mt-2" v-model="params[reportType]" />
            </div>

            <div class="row gutter-15 gutter-sm-10 mt-3">
              <div class="col-sm-6 col-md-3 mb-sm-10">
                <router-link :to="{ name: 'Reports' }" class="i-btn -gray w-100 w-sm-auto btn-sm">
                  Annuleren</router-link>
              </div>
              <button type="submit" class="i-btn w-sm-auto col-sm-6 col-md-3 mx-1">{{ t('buttons.save') }}</button>
            </div>
          </form>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReportConfig',
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data: function (){
    return {
      reportTypes: ['delivery', 'pre_inspection', 'final_inspection'],
      params: {
        delivery: '',
        pre_inspection: '',
        final_inspection: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      configurations: 'reportConfig/list'
    })
  },
  methods: {
    ...mapActions({
      submitConfig: 'reportConfig/updateList',
      list: 'reportConfig/getList'
    }),
    submit() {
      event.preventDefault()
      this.submitConfig(this.params)
    }
  },
  async mounted() {
    await this.list()
    this.params = this.configurations
  }
}
</script>
