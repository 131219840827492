<template>
  <form role="form" id="house-form">
    <div class="row justify-content-between align-items-center">
      <div class="col-sm-5 mt-sm-10">
        <h1 class="page-title -primary">
          {{ t('agents.houseForm.title') }}
        </h1>
      </div>
      <div class="col-sm-auto mt-sm-20">
        <div class="row gutter-15 gutter-sm-10">
          <div class="col-sm-auto col-6">
            <router-link class="i-btn -gray w-100 w-sm-auto" :to="{ name: 'Agents Houses' }">{{ t('buttons.cancel') }}</router-link>
            <!-- <a class="i-btn -gray w-100 w-sm-auto" href="#">Cancel</a> -->
          </div>
          <div class="col">
            <button type='submit' class='i-btn w-100 w-sm-auto' @click.prevent="submitHouse()">{{ t('buttons.save') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="new-house">
      <div class="row">
        <div class="col-xl-7">
          <div class="new-house--col-left">
            <div class="row">
              <div class="col-sm-12 mb-28">
                <upload-files :existedVisualMedia="house.visual_media"></upload-files>
              </div>
            </div>

            <div class="white-box new-house__basic-info">
              <h2 class="page-title -primary">{{ t('agents.houseForm.house_infomation') }}</h2>
              <p class="i-text -gray mb-35 mb-md-25"><b>{{ t('agents.houseForm.basic_infomation') }}</b></p>

              <form class="">
                <div class="mb-35 mb-sm-20">
                  <input v-bind:class="classNameField('state')" type="text" name="name"
                         v-model='houseParams.name' v-bind:placeholder="t('agents.houseForm.house_name')">
                  <InvalidFieldMessage v-bind:errorMessages="houseError" field="name"/>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-35 mb-sm-20">
                      <input v-bind:class="classNameField('post_code')" type="text" name="post_code"
                             v-model='houseParams.post_code'
                             v-bind:placeholder="t('agents.houseForm.post_code')"
                             @change="fetchAddress">
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="post_code"/>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="mb-35 mb-sm-20">
                      <input v-bind:class="classNameField('house_number')" type="text" name="house_number"
                             v-model='houseParams.house_number' v-bind:placeholder="t('agents.houseForm.house_number')">
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="house_number"/>
                    </div>
                  </div>

                  <div class="col-sm-6"
                       v-if="houseAddress.size !== 1 || houseAddress.house_letter !== ''">
                    <div class="mb-35 mb-sm-20">
                      <input v-bind:class="classNameField('house_letter')" type="text" name="house_letter"
                             v-model='houseParams.house_letter' v-bind:placeholder="t('agents.houseForm.house_letter')">
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="house_letter"/>
                    </div>
                  </div>

                  <div class="col-sm-6"
                       v-if="houseAddress.size !== 1 || houseAddress.house_number_addition !== ''">
                    <div class="mb-35 mb-sm-20">
                      <input v-bind:class="classNameField('house_number_addition')" type="text" name="house_number_addition"
                             v-model='houseParams.house_number_addition' v-bind:placeholder="t('agents.houseForm.house_number_addition')">
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="house_number_addition"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-35 mb-sm-20">
                      <div class="i-input-wrap">
                        <input v-bind:class="classNameField('house_address')" type="text" name="street"
                               v-model='houseParams.street' class="pr-2"
                               v-bind:placeholder="t('agents.houseForm.street')">
                        <i v-if="isFetchingAddress" class="icon icon-bar-loading i-input-wrap__icon"></i>
                      </div>
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="street"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-35 mb-sm-20">
                      <div class="i-input-wrap">
                        <input v-bind:class="classNameField('city')" type="text"
                               name="city" v-model='houseParams.city'
                               v-bind:placeholder="t('agents.houseForm.house_city')">
                        <i v-if="isFetchingAddress" class="icon icon-bar-loading i-input-wrap__icon"></i>
                      </div>
                      <InvalidFieldMessage v-bind:errorMessages="houseError" field="city"/>
                    </div>
                  </div>
                </div>
                <div class="mb-35 mb-sm-20">
                      <textarea class="i-textarea" v-bind:placeholder="t('agents.houseForm.description')"
                                name='description' v-model="houseParams.description"></textarea>
                  <InvalidFieldMessage v-bind:errorMessages="houseError" field="description"/>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="new-house--col-right">
            <div class="white-box new-house__input mb-28">
              <label class="new-house__label">
                <i class="new-house__label__icon icon icon-key"></i> {{ t('agents.houseForm.start_hiring') }}
              </label>
              <div class="i-input new-house__datepicker">
                <dropdown-datepicker v-if="defaultStartHiringDate"
                    :defaultDate="defaultStartHiringDate"
                    defaultDateFormat="yyyy-mm-dd"
                    displayFormat="dmy"
                    :minYear=2021
                    sortYear="asc"
                    :on-day-change="changeDay"
                    :on-month-change="changeMonth"
                    :on-year-change="changeYear">
                </dropdown-datepicker>
              </div>
              <div v-if="errors.start_hiring_date.length >= 1">
                <InvalidFieldMessage v-bind:errorMessages="errors" field="start_hiring_date"/>
              </div>
              <InvalidFieldMessage v-bind:errorMessages="houseError" field="start_hiring_date"/>
            </div>
            <div class="white-box new-house__input mb-28">
              <label class="new-house__label">
                <i class="new-house__label__icon icon icon-bank"></i> {{ t('agents.houseForm.deposit') }}
              </label>
              <input v-bind:class="classNameField('deposit')" type="" name="deposit"
                     placeholder="$$$" v-model="houseParams.deposit">
              <InvalidFieldMessage v-bind:errorMessages="houseError" field="deposit"/>
            </div>
            <div class="white-box new-house__input mb-28">
              <label class="new-house__label">
                <i class="new-house__label__icon icon icon-card"></i> {{ t('agents.houseForm.rent_price') }}
              </label>
              <input v-bind:class="classNameField('rent_price')" type="" name="rent_price" placeholder="$$$"
                     v-model="houseParams.rent_price">
              <InvalidFieldMessage v-bind:errorMessages="houseError" field="rent_price"/>
            </div>
            <div class="white-box new-house__input mb-28">
              <label class="new-house__label">
                <i class="new-house__label__icon icon icon-contact"></i> {{ t('agents.houseForm.contact_length') }}
                <span class="i-text -gray">In Months</span>
              </label>
              <div class="d-flex justify-content-between">
                <label class="circle-check" v-for="contract_duration in contracts_length"
                       v-bind:key="contract_duration">
                  <input type="radio" name="contract" v-bind:value="contract_duration"
                         v-model="houseParams.contract_length">
                  <span class="circle-check__ct">{{contract_duration}}</span>
                </label>
              </div>
              <InvalidFieldMessage v-bind:errorMessages="houseError" field="contract"/>
            </div>
            <div class="white-box new-house__input mb-28">
              <label class="new-house__label">
                <i class="new-house__label__icon icon icon-people"></i> {{ t('agents.houseForm.people_allow') }}
              </label>
              <div class="d-flex justify-content-between">
                <label class="circle-check" v-for="item in maxPeopleAllowed" v-bind:key="item.value">
                  <input type="radio" name="maxPeopleAllowed" v-bind:value="item.value"
                         v-model="houseParams.max_people_allowed">
                  <span class="circle-check__ct">{{item.text}}</span>
                </label>
              </div>
              <InvalidFieldMessage v-bind:errorMessages="houseError" field="max_people_allowed"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InvalidFieldMessage from '../../common/InvalidFieldMessage'
import DropdownDatepicker from '/node_modules/vue-dropdown-datepicker/src/dropdown-datepicker.vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import UploadFiles from './_UploadFiles'

export default {
  name: 'HouseForm',
  components: {
    InvalidFieldMessage,
    DropdownDatepicker,
    UploadFiles
  },
  props: ['house', 'action'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      errors: {
        start_hiring_date: []
      },
      houseParams: {},
      maxPeopleAllowed: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      contracts_length: [6, 12, 18, 24, 30],
      start_hiring_date: {
        day: '',
        month: '',
        year: ''
      }
    }
  },
  methods: {
    ...mapActions({
    }),
    submitHouse() {
      if(this.action === 'create') {
        this.$store.dispatch('houses/createHouse', this.houseParams)
      } else if (this.action === 'update') {
        this.$store.dispatch('houses/updateHouse', this.houseParams)
      }
    },
    isInvalid(field){
      let invalidFields = Object.keys(this.houseError)
      return invalidFields.includes(field)
    },
    classNameField(field){
      return this.isInvalid(field) ? 'i-input -danger' : 'i-input'
    },
    changeDay(day) {
      this.start_hiring_date.day = day < 10 ? `0${day}` : day.toString()
      this.getHiringStartDate()
    },
    changeMonth(month) {
      this.start_hiring_date.month = month < 10 ? `0${month}` : month
      this.getHiringStartDate()
    },
    changeYear(year) {
      this.start_hiring_date.year = year
      this.getHiringStartDate()

    },
    getHiringStartDate() {
      this.errors['start_hiring_date'] = []
      let stringDate =
          `${this.start_hiring_date.day}-${this.start_hiring_date.month}-${this.start_hiring_date.year}`

      if(moment(stringDate, 'DD-MM-YYYY', true).isValid()) {
        this.houseParams.start_hiring_date = moment.utc(stringDate, 'DD-MM-YYYY').toDate()
      } else {
        this.errors['start_hiring_date'] = [this.t('agents.houseForm.invalid_date')]
      }
    },
    buildSearchPostalCodeParams() {
      let postCode = this.houseParams.post_code.replace(/ /g,'')
      let searchParams = {}
      searchParams['post_code'] = postCode
        searchParams['house_number'] = this.houseParams.house_number
        searchParams['house_letter'] = this.houseParams.house_letter
        searchParams['house_number_addition'] = this.houseParams.house_number_addition
      return searchParams
    },
    async autoDetectAddress() {
      let searchParams = this.buildSearchPostalCodeParams()
      await this.$store.dispatch('houseForm/detectHouseAddress', searchParams)
      this.houseParams.street = this.houseAddress.street
      this.houseParams.city = this.houseAddress.city
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
      houseError: 'houses/houseError',
      houseVisualMedia: 'houseVisualMedia/visualMedia',
      houseAddress: 'houseForm/houseAddress',
      isFetchingAddress: 'houseForm/isFetchingAddress'
    }),
    defaultStartHiringDate() {
      return moment(this.house.start_hiring_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
    setDefaultDate() {
      let currentStartDate = this.defaultStartHiringDate
      let day = moment(currentStartDate, 'YYYY-MM-DD').format('DD').toString()
      let month = moment(currentStartDate, 'YYYY-MM-DD').format('MM').toString()
      let year = moment(currentStartDate, 'YYYY-MM-DD').format('YYYY').toString()
      return {
        day: day,
        month: month,
        year: year
      }
    }
  },
  watch: {
    houseVisualMedia() {
      if(this.houseVisualMedia) {
        this.houseParams.houseImagesUploadedKeys = this.houseVisualMedia.map(image => image.key)
      }
    },
    'houseParams.post_code': function(){
      if(this.houseParams.post_code && this.houseParams.post_code.length >= 6) {
        this.autoDetectAddress()
      }
    },
    'houseParams.house_number': function(){
      if(this.houseParams.house_number && this.houseParams.house_number.length >= 1) {
        this.autoDetectAddress()
      }
    }
  },
  mounted() {
    this.houseParams = this.house
    if(this.defaultStartHiringDate){
      this.start_hiring_date = this.setDefaultDate
    }
  }
}
</script>

<style>
.loading-address {
  position: absolute;
  right: 20px;
  top: 10px;
}
</style>
