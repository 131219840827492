import ReportMeterInformation from '../../../backend/report_meter_information'

const ERROR = 'ERROR'

const state = {
  errors: []
}

export const mutations = {
  [ERROR](state, errors) {
    state.errors = errors
  }
}

export const actions = {
  async createOrUpdate({ commit }, formData) {
    await ReportMeterInformation.createOrUpdate(formData).then(() => {
      commit(ERROR, [])
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  }
}

export const getters = {
  updatedSuccess: state => state.updatedSuccess,
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
