<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>
      <div class="new-reports-box -bg-white mb-35">
        <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.reports.new.basic_info') }}</h2>
        <div class="row gutter-xl-15 gutter-sm-10 mt-3" v-if="isDeliveryReport">
          <div class="col-12">
            <input type="hidden"
                   v-model='basicInformationParams.id'>
            <label>{{ t('agents.reports.new.owner_full_name') }}</label>
            <input type="text" name="owner_full_name" class="i-input"
                   v-model='basicInformationParams.owner_full_name'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.ownerName')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.owner_full_name"/>
          </div>
        </div>

        <div class="row gutter-xl-15 gutter-sm-10">
          <div class="col-md-6 col-xs-12 mt-3">
            <label>{{ t('agents.reports.new.address') }}</label>
            <input type="text" name="house_address" class="i-input"
                   v-model='basicInformationParams.house_address'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.address')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.house_address"/>
          </div>
          <div class="col-md-6 col-sm-12 mt-3">
            <label>{{ t('agents.reports.new.zip_code') }}</label>
            <input type="text" name="zipcode" class="i-input"
                   v-model='basicInformationParams.zipcode'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.zipCode')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.zipcode"/>
          </div>
          <div class="col-md-6 col-sm-12 mt-3">
            <label>{{ t('agents.reports.new.city') }}</label>
            <input type="text" name="city" class="i-input"
                   v-model='basicInformationParams.city'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.city')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.city"/>
          </div>
          <div class="col-md-6 col-sm-12 mt-3" v-if="isDeliveryReport">
            <label>{{ t('agents.reports.new.date_of_statement') }}</label>
            <VueDatePicker
                class="custom-datepicker mb-15" locale="nl" name="signed_date"
                v-model="basicInformationParams.date_of_statement"
                :format="t('datepicker.format.date')"
                :enable-time-picker="false"
                :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.zipcode"/>
          </div>
          <div class="col-md-6 col-sm-12 mt-3 d-none" v-if="isDeliveryReport">
            <label>{{ t('agents.reports.new.contact_person') }}</label>
            <input type="text" name="contact_person" class="i-input"
                   v-model='basicInformationParams.contact_person'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.contactPerson')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.contact_person"/>
          </div>
          <div class="col-md-6 col-sm-12 mt-3 d-none" v-if="isDeliveryReport">
            <label>{{ t('agents.reports.new.phone_contact_person') }}</label>
            <input type="tel" name="phone_contact_person" class="i-input"
                   v-model='basicInformationParams.phone_contact_person'
                   v-bind:placeholder="t('reports.placeHolders.basicInformation.telephone')">
            <InvalidFieldMessage v-bind:errorMessages="basicInformationError"
                                 field="report_basic_information.phone_contact_person"/>
          </div>
        </div>
      </div>

      <div class="new-reports-box -bg-white">
        <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.reports.new.houseImages') }}</h2>
        <ImageUploaderPreview @upload-images="uploadImages" :currentImages="reportHouseImagesDisplayed"></ImageUploaderPreview>
      </div>


      <div class="new-reports-box -bg-white mt-35">
        <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.reports.new.hirer_info') }}</h2>

        <div class="hirer-information" v-for="(clientParam, index) in client_params"
             :key="`hirer-information-attributes-${index}`">
          <div v-if="clientParam" :class="`hirer-information-attributes-${index}`">
            <div class="row gutter-xl-15 gutter-sm-10 mt-3">
              <input type="hidden" v-model='clientParam["id"]'/>
              <div :class="isDeliveryReport ? 'col-12 col-md-6 mb-15' : 'col-md-6 col-sm-12 mb-15' ">
                <label>{{ t('agents.reports.new.contact_person_name') }}
                  <span class="js--hirer-reindex-number">{{ hirerIndexNumber(clientParam, index) }}</span></label>
                <input type="text" name="full_name" class="i-input"
                       v-model="clientParam['full_name']"/>
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].full_name`"/>
              </div>

              <div class="col-12 col-md-6 mb-15" v-if="isDeliveryReport">
                <label>{{ t('agents.reports.new.email') }}</label>
                <input type="email" name="email" class="i-input"
                       v-model="clientParam['email']"/>
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].email`"/>
              </div>


              <div class="col-md-6 col-sm-12 mb-15" v-if="!isDeliveryReport">
                <label>{{ t('agents.reports.new.bank_account_number') }}</label>
                <input type="text" name="bank_account_number" class="i-input"
                       v-model="clientParam['bank_account_number']"/>
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].bank_account_number`"/>
              </div>
            </div>

            <div class="row gutter-xl-15 gutter-sm-10">
              <div class="col-sm-6 mb-sm-15">
                <label>{{ t('agents.reports.new.phone_number') }}</label>
                <input type="tel" name="phone_number" class="i-input"
                       v-model="clientParam['phone_number']"/>
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].phone_number`"/>
              </div>

              <div class="col-sm-6 mb-sm-15 mb-15" v-if="isDeliveryReport">
                <label>{{ t('agents.reports.new.rental_agreement_start_at') }}</label>
                <VueDatePicker
                    class="custom-datepicker" locale="nl"
                    v-model="clientParam['rental_agreement_start_at']"
                    :format="t('datepicker.format.date')"
                    :enable-time-picker="false"
                    :disabled="clientParam.disabled !== 0"
                    :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].rental_agreement_start_at`"/>
              </div>

              <div class="col-sm-6 mb-sm-15 mb-15" v-if="isPreInspectionReport">
                <label>{{ t('agents.reports.new.scheduled_final_inspection_at') }}</label>
                <VueDatePicker
                    class="custom-datepicker" locale="nl"
                    v-model="clientParam['scheduled_final_inspection_at']"
                    :format="t('datepicker.format.dateTime')"
                    :disabled="clientParam.disabled !== 0"
                    :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />

                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].scheduled_final_inspection_at`"/>
              </div>

              <div class="col-sm-6 mb-sm-15 mb-15" v-if="!isDeliveryReport">
                <label>{{ t('agents.reports.new.end_date_agreement_hiring') }}</label>
                <VueDatePicker
                    class="custom-datepicker" locale="nl"
                    v-model="clientParam['rental_agreement_end_at']"
                    :enable-time-picker="false"
                    :format="t('datepicker.format.date')"
                    :disabled="clientParam.disabled !== 0"
                    :cancelText="t('datepicker.cancelText')" :selectText="t('datepicker.selectText')" />
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].rental_agreement_end_at`"/>
              </div>
            </div>

            <div class="row gutter-xl-15 gutter-sm-10 mb-15" v-if="!isDeliveryReport">
              <div class="col-sm-12 mb-sm-15">
                <label>{{ t('agents.reports.new.new_address') }}</label>
                <input type="text" name="address" class="i-input"
                       v-model="clientParam['address']">
                <InvalidFieldMessage v-bind:errorMessages="clientsError"
                                     :field="`report_client_informations[${index}].address`"/>
              </div>
            </div>
            <input type="hidden" name="_destroy" v-model="clientParam['_destroy']">
            <button v-if="isDeliveryReport && activeClients.length > 1" @click="(event) => removeHirer(index, event)"
                    class="mt-2 i-btn -border -danger">
              <i class="icon icon-trash"></i>
              <span class="ml-2">{{ t('reports.pageStep1.removeHirer') }}</span>
            </button>
            <hr v-if="isDeliveryReport" class="mt-4 mb-5">
          </div>
        </div>

        <button class="i-btn -info -block pl-03 pr-03" v-if="isDeliveryReport && activeClients.length < 3"
                @click="addMoreHirer()" >{{ t('reports.pageStep1.addMoreHirer') }}</button>
      </div>

      <div class="new-report-line mb-20 mt-20">
        <div class="row gutter-10 justify-content-center">
          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -white -block pl-03 pr-03" type="button">
              <i class="fa fa-long-arrow-alt-left mr-08 ml-0 mt-0"></i> {{ t('buttons.back') }}
            </button>
          </div>

          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -info -block pl-03 pr-03" type="button" @click="submitBasicInformation">
              {{ t('buttons.next') }} <i class="fa fa-long-arrow-alt-right ml-08 mr-0 mt-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import InvalidFieldMessage from '../../../common/InvalidFieldMessage'
import { nl } from 'date-fns/locale'
import currentReport from '@/mixins/currentReport'
import typeOfReport from '@/mixins/typeOfReport'
import ReportHouseImagesApi from '@/backend/report_house_images'
import momentServices from '@/services/momentServices'
import ImageUploaderPreview from '@/views/shared/ImageUploaderPreview'

export default {
  name: 'FormReportStep1',
  mixins: [currentReport, typeOfReport],
  components: {
    ImageUploaderPreview,
    Breadcrumb,
    InvalidFieldMessage
  },
  setup() {
    const { t } = useI18n()
    // const rental_agreement_end_at = ref();
    // const rental_agreement_start_at = ref();
    const locales = { nl }
    return { t, locales }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: '#' }
      ],
      reportType: 'delivery',
      basicInformationParams: {},
      client_params: [],
      defaultClientAttributes: { id: '', full_name: '', bank_account_number: '', phone_number: '',
        rental_agreement_start_at: new Date(), rental_agreement_end_at: new Date(), address: '', scheduled_final_inspection_at: new Date(), _destroy: 0 },
      reportHouseImagesDisplayed: [],
      allImageUploadDone: true,
      imageUploadKeys: []
    }
  },
  computed: {
    ...mapGetters({
      reportErrors: 'reports/reportErrors',
      reportDetails: 'reports/reportDetails',
      entityProfile: 'entities/profiles',
      clientsInformation: 'reports/clientsInformation',
      basicInformation: 'reportBasicInformation/data',
      clientsError: 'reportClientsInformation/errors',
      basicInformationError: 'reportBasicInformation/errors',
      houseImages: 'reportHouseImages/imageList'
    }),
    currentReportId() {
      return this.$route.params.id
    },
    activeClients(){
      return this.client_params.filter(client => client._destroy !== 1)
    },
    firstActiveClient() {
      return this.activeClients[0]
    }
  },
  methods: {
    ...mapActions({
      createReport: 'reports/createReport',
      getReportInfo: 'reports/getReportInfo',
      entityInfo: 'entities/getProfiles',
      getClientsInformation: 'reports/clientsInformation',
      reportClientsCreateOrUpdate: 'reportClientsInformation/createOrUpdate',
      getBasicInformation: 'reportBasicInformation/getInformation',
      basicInformationCreateOrUpdate: 'reportBasicInformation/createOrUpdate',
      getListHouseImages: 'reportHouseImages/list',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      turnOffLoadingPage: 'loadingPage/turnOffLoadingPage'
    }),
    async submitBasicInformation() {
      this.turnOnLoading()
      await this.basicInformationCreateOrUpdate({ reportId: this.currentReportId, formData: this._buildFormDataBasicInformation() })
      if(Object.keys(this.basicInformationError).length > 0) {
        this.turnOffLoadingPage()
        return false
      }

      await this.reportClientsCreateOrUpdate({ reportId: this.currentReportId, formData: this._buildFormDataClient() })
      if(Object.keys(this.clientsError).length > 0) {
        this.turnOffLoadingPage()
        return false
      }

      if(this.imageUploadKeys.length > 0) {
        await ReportHouseImagesApi.assign_images(this.currentReportId, this.imageUploadKeys)
      }

      let params = {
        id: this.currentReportId,
        type: this.reportType,
        completed_step: this.currentStep
      }
      await this.createReport(params)
      this.turnOffLoadingPage()

      let nextStep = this.isPreInspectionReport ? 'FormReportStep3' : 'FormReportStep2'
      this.$router.push( { name: nextStep, params: { id: this.currentReportId } }).then(() => {})
    },
    addMoreHirer() {
      this.client_params.push(Object.assign({}, this.defaultClientAttributes))
      this._enableFirstHirerDate()
    },
    removeHirer(index, event) {
      event.target.closest(`.hirer-information-attributes-${index}`).classList.add('d-none')
      this.client_params[index]['_destroy'] = 1
      this._enableFirstHirerDate()
    },
    hirerIndexNumber(clientParam){
      if(clientParam === this.client_params.at(-1)) {
        return this.activeClients.length
      } else {
        return this.activeClients.indexOf(clientParam) + 1
      }
    },
    uploadImages(params) {
      this.allImageUploadDone = params.filter(_image => (!_image.uploadDone)).length === 0
      this.imageUploadKeys = params.filter(_image => _image.uploadDone).map(_image => (_image.blob_key))
    },
    _enableFirstHirerDate(){
      if(this.firstActiveClient) {
        this.firstActiveClient.disabled = 0
      }
    },
    _buildFormDataBasicInformation() {
      let formData = new FormData()
      formData.append('reports[report_basic_information_attributes][id]', this.basicInformationParams.id || '')
      formData.append('reports[report_basic_information_attributes][owner_full_name]', this.basicInformationParams.owner_full_name || '')
      formData.append('reports[report_basic_information_attributes][house_address]', this.basicInformationParams.house_address || '')
      formData.append('reports[report_basic_information_attributes][zipcode]', this.basicInformationParams.zipcode || '')
      formData.append('reports[report_basic_information_attributes][city]', this.basicInformationParams.city || '')
      formData.append('reports[report_basic_information_attributes][date_of_statement]', this.basicInformationParams.date_of_statement || '')
      formData.append('reports[report_basic_information_attributes][contact_person]', this.basicInformationParams.contact_person || '')
      formData.append('reports[report_basic_information_attributes][phone_contact_person]', this.basicInformationParams.phone_contact_person || '')

      return formData
    },

    _buildFormDataClient() {
      let formData = new FormData()
      this.client_params.forEach((client, index) => {
        formData.append(`reports[report_client_informations_attributes][${index}][id]`, client.id || '')
        formData.append(`reports[report_client_informations_attributes][${index}][email]`, client.email || '')
        formData.append(`reports[report_client_informations_attributes][${index}][full_name]`, client.full_name || '')
        formData.append(`reports[report_client_informations_attributes][${index}][address]`, client.address || '')
        formData.append(`reports[report_client_informations_attributes][${index}][bank_account_number]`, client.bank_account_number || '')
        formData.append(`reports[report_client_informations_attributes][${index}][phone_number]`, client.phone_number || '')
        formData.append(`reports[report_client_informations_attributes][${index}][rental_agreement_start_at]`, client.rental_agreement_start_at || '')
        formData.append(`reports[report_client_informations_attributes][${index}][rental_agreement_end_at]`, client.rental_agreement_end_at || '')
        formData.append(`reports[report_client_informations_attributes][${index}][scheduled_final_inspection_at]`, client.scheduled_final_inspection_at || '')
        formData.append(`reports[report_client_informations_attributes][${index}][_destroy]`, client._destroy || 0)
      })

      return formData
    },
  },
  async mounted() {
    this.$emit('header-title', this.t('breadcrumb.reports'))

    await this.getReportInfo(this.currentReportId)
    await this.entityInfo()
    await this.getClientsInformation(this.currentReportId)
    await this.getBasicInformation(this.currentReportId)
    await this.getListHouseImages(this.currentReportId)

    this.basicInformationParams = this.basicInformation || {}
    this.reportType = this.reportDetails.type
    this.basicInformationParams.contact_person = this.entityProfile.name
    this.basicInformationParams.phone_contact_person = this.entityProfile.phone_number
    if(this.basicInformationParams.date_of_statement === undefined) {
      this.basicInformationParams.date_of_statement = new Date()
    } else {
      let date_of_statement = this.basicInformationParams.date_of_statement
      this.basicInformationParams.date_of_statement = momentServices.convertTimeApiToLocal(date_of_statement)
    }

    this.houseImages.forEach(image => {
      this.reportHouseImagesDisplayed.push({ url: image.thumb_url, blob_key: image.blob_key, uploadDone: true })
    })

    if(await this.clientsInformation.length > 0) {
      this.client_params = this.clientsInformation
      if(this.isDeliveryReport) {
        this.defaultClientAttributes.rental_agreement_start_at = momentServices.convertTimeApiToLocal(this.clientsInformation[0].rental_agreement_start_at)
        this.client_params.map(clientParams => {
          clientParams.rental_agreement_start_at = momentServices.convertTimeApiToLocal(clientParams.rental_agreement_start_at)
        })
      } else {
        this.defaultClientAttributes.rental_agreement_end_at = momentServices.convertTimeApiToLocal(this.clientsInformation[0].rental_agreement_end_at)
        this.client_params.map(clientParams => {
          clientParams.scheduled_final_inspection_at = momentServices.convertTimeApiToLocal(clientParams.scheduled_final_inspection_at)
          clientParams.rental_agreement_end_at = momentServices.convertTimeApiToLocal(clientParams.rental_agreement_end_at)
        })
      }
    } else {
      if(this.isDeliveryReport) {
        this.defaultClientAttributes.rental_agreement_start_at = momentServices.convertTimeApiToLocal(new Date())
      } else {
        this.defaultClientAttributes.rental_agreement_end_at = momentServices.convertTimeApiToLocal(new Date())
      }
      this.client_params = [Object.assign({}, this.defaultClientAttributes)]
    }
    await this._enableFirstHirerDate()
  },
  watch: {
  //   rental_agreement_end_at: function(val) {
  //     this.client_information_attributes.rental_agreement_end_at = moment(val).format('YYYY-MM-DD');
  //   },
    'firstActiveClient.rental_agreement_start_at': function(val){
      this.activeClients.forEach(client => {
        client.rental_agreement_start_at = val
      })
      this.defaultClientAttributes.rental_agreement_start_at = val
    }
  }
}
</script>
