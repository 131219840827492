import ApiService from './api_services'

const UserPasswordApi = {
  validateResetPasswordToken: function(params) {
    return ApiService.get('/user_password/check_token_reset_password', params)
  },

  changePassword: function (params) {
    return ApiService.put('/user_password/change_password', params)
  }
}

export default UserPasswordApi
