{
  "buttons": {
    "add": "Toevoegen",
    "cancel": "Annuleren",
    "reject": "Afwijzen",
    "review": "Review",
    "accept": "Accepteren",
    "sendDetails": "Informatie versturen",
    "send_review": "Review versturen",
    "approve": "Goedkeuren",
    "next": "Volgende",
    "back": "Vorige",
    "finish": "Opslaan",
    "submit": "Versturen",
    "save": "Opslaan"
  },
  "breadcrumb": {
  	"houses": "Huizen",
  	"list": "Lijst",
  	"new": "Nieuw",
  	"detail": "Informatie",
    "review": "Inschrijving",
    "notifications": "Notificaties",
    "reports": "Rapporten",
    "agents": "Makelaars",
    "terms": "Voorwaarden",
    "home": "Home",
    "edit": "Aanpassen"
  },
  "errorMessages": {
    "blankBasicInfoRequired": "Basis informatie is benodigd",
    "hirerInfoRequired": "Huurder informatie is benodigd"
  },
  "messages": {
    "entityUpdateSuccess": "Bedrijfsinformatie geupdate"
  },
  "datepicker": {
    "cancelText": "annuleren",
    "selectText": "selecteren",
    "format": {
      "date": "dd-MM-yyyy",
      "dateTime": "dd-MM-yyyy HH:mm"
    }
  }
}
