import ActiveStorageApi from '../../../backend/active_storage'

const UPLOAD = 'UPLOAD'
const MODIFY_PROCESS = 'MODIFY_PROCESS'

const state = {
  files: {},
  uploadProcessIds: []
}

export const mutations = {
  [UPLOAD] (state, params) {
    if(params.clean) {
      return state.files[params.imagesKey] = params.file
    }
    if(state.files[params.imagesKey] === undefined) {
      state.files[params.imagesKey] = params.file
    } else {
      state.files[params.imagesKey] = [...state.files[params.imagesKey], ...params.file]
    }
  },
  [MODIFY_PROCESS] (state, params) {
    let processID = params['processID']
    if(params['type'] === 'add') {
      state.uploadProcessIds.push(processID)
    } else {
      state.uploadProcessIds.splice(state.uploadProcessIds.indexOf(processID), 1)
    }
  }
}

export const actions = {
  modifyUploadProcessId({ commit }, params ) {
    commit(MODIFY_PROCESS, params)
  },
  upload({ commit }, params ) {
    commit(UPLOAD, params)
  },
  // eslint-disable-next-line no-unused-vars
  remove( { commit }, data) {
    let imageKey = data.imageKey
    return ActiveStorageApi.delete({ key: imageKey }).then(() => {
    }).catch( error => {
      console.error(error)
    })
  },
}

export const getters = {
  files: state => state.files,
  uploadProcessIds: state => state.uploadProcessIds
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
