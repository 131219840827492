import ActiveStorageApi from '../../../backend/active_storage'

const SET_VISUAL_MEDIA = 'SET_VISUAL_MEDIA'
const UPLOAD = 'UPLOAD'
const REMOVE_IMAGE = 'REMOVE_IMAGE'
const ADD_UPLOAD_PROCESS = 'ADD_UPLOAD_PROCESS'
const REMOVE_UPLOAD_PROCESS = 'REMOVE_UPLOAD_PROCESS'

const state = {
  visualMedia: [],
  uploadProcessIds: []
}

export const mutations = {
  [SET_VISUAL_MEDIA] (state, visualMedia) {
    state.visualMedia = visualMedia
  },
  [UPLOAD] (state, newVisualMedia) {
    if(state.visualMedia === undefined) {
      state.visualMedia = newVisualMedia
    } else {
      state.visualMedia = [...state.visualMedia, ...newVisualMedia]
    }
  },
  [REMOVE_IMAGE] (state, imageKey) {
    state.visualMedia = state.visualMedia.filter(item => (item.key !== imageKey))
  },
  [ADD_UPLOAD_PROCESS] (state, processID) {
    state.uploadProcessIds.push(processID)
  },
  [REMOVE_UPLOAD_PROCESS] (state, processID) {
    state.uploadProcessIds.splice(state.uploadProcessIds.indexOf(processID), 1)
  }
}

export const actions = {
  setVisualMedia( { commit }, visualMedia) {
    commit(SET_VISUAL_MEDIA, visualMedia)
  },
  upload( { commit }, newVisualMedia) {
    commit(UPLOAD, newVisualMedia)
  },
  remove( { commit }, data) {
    let imageKey = data.imageKey
    return ActiveStorageApi.delete({ key: imageKey }).then((response) => {
      if(response.status === 200 ){
        commit(REMOVE_IMAGE, imageKey)
      }
    }).catch( error => {
      console.error(error)
    })
  },
  addUploadProcessId( { commit }, processId ) {
    commit(ADD_UPLOAD_PROCESS, processId)
  },
  removeUploadProcessId( { commit }, processId ) {
    commit(REMOVE_UPLOAD_PROCESS, processId)
  }
}

export const getters = {
  visualMedia: state => state.visualMedia,
  uploadProcessIds: state => state.uploadProcessIds
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
