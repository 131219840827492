<template>
  <div class="white-box card media-upload">
    <div v-bind="getRootProps()">
      <input v-bind="getInputProps()" >
      <div v-if="visualMedia && visualMedia.length > 0" class="images-preview media-upload__preview">
        <p class="media-upload__text" v-if="isDragActive">{{ t('agents.uploadFiles.drop_files_label')}}</p>
        <div v-for="image in visualMedia" :key="image.key" class="media-upload__preview__item">
          <img :src="image.thumb_url" alt=""/>
          <button type="button" class="media-upload__preview__item__remove" v-on:click="removeImage(image.key)">&times;</button>
          <div class="media-upload__preview__item__icon" v-if="image.content_type.includes('video')"><i class="icon icon-play"></i></div>
        </div>
        <div class="media-upload__preview__item -uploading" v-if="uploadProcessIds.length > 0"><i class="icon icon-loading"></i></div>
        <div v-else class="media-upload__preview__item -add-new"></div>
      </div>
      <div v-else class="mb-15">
        <div class="media-upload__preview__item -uploading" v-if="uploadProcessIds.length > 0">
          <i class="icon icon-loading"></i>
        </div>
        <div v-else>
          <div class="media-upload__icon">
            <i class="icon icon-media"></i>
          </div>
          <div class="media-upload__text">
            <p v-if="isDragActive">{{ t('agents.uploadFiles.drop_files_label')}}</p>
            <p v-else>{{ t('agents.uploadFiles.drag_and_drop_label')}}</p>
          </div>
          <div class="media-upload__select">{{ t('agents.uploadFiles.choose_file')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone'
import ActiveStorageApi from '../../../backend/active_storage'
import { mapActions, mapGetters, useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'UploadFile',
  props: ['existedVisualMedia'],
  setup() {
    const store = useStore()
    const saveFiles = async (files) => {
      let processID = new Date().getTime()
      await store.dispatch('houseVisualMedia/addUploadProcessId', processID)
      for (let x = 0; x < files.length; x++) {
        const formData = new FormData()
        formData.append('files[]', files[x])
        await ActiveStorageApi.createAndUpload(formData).then((response) => {
          store.dispatch('houseVisualMedia/upload', response.data)
        }).catch((err) => {
          console.error(err)
        })
      }
      await store.dispatch('houseVisualMedia/removeUploadProcessId', processID)
    }

    function onDrop(acceptFiles) {
      saveFiles(acceptFiles)
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ maxSize: 5120000, onDrop })
    const { t } = useI18n()
    return {
      t,
      getRootProps,
      getInputProps,
      ...rest
    }
  },
  methods: {
    ...mapActions({
      setHouseVisualMedia: 'houseVisualMedia/setVisualMedia',
      upload: 'houseVisualMedia/upload'
    }),
    removeImage(imageKey) {
      event.stopPropagation()
      event.target.parentNode.style.display = 'none'
      this.$store.dispatch('houseVisualMedia/remove',
          { houseId: this.houseID, imageKey: imageKey })
    }
  },
  computed: {
    ...mapGetters({
      visualMedia: 'houseVisualMedia/visualMedia',
      uploadProcessIds: 'houseVisualMedia/uploadProcessIds'
    }),
    houseID() {
      return this.$route.params.id
    }
  },
  mounted() {
    let allVisualMedia = [...this.existedVisualMedia || [], ...this.houseVisualMedia || []]
    this.setHouseVisualMedia(allVisualMedia)
  }
}
</script>
