<template>
  <div class="sign-in">
    <div class="sign-in__banner">
      <h2 class="sign-in__banner__title" v-html="t('signIn.title')"></h2>
    </div>
    <div class="sign-in__content">
      <div class="sign-in__content__title">
        {{t('signIn.sign_in')}}
      </div>
      <form @submit="onSubmit" class="sign-in__form">
        <div class="alert alert-danger" v-if="showError">{{ errorMessage }}</div>
        <div class="sign-in__form__line">
          <input name="email" v-model="user.email" class="sign-in__form__input" v-bind:placeholder="t('signIn.name')" type="text" />
        </div>
        <div class="sign-in__form__line">
          <input name="password" v-model="user.password" class="sign-in__form__input" v-bind:placeholder="t('signIn.password')" type="password" />
        </div>
        <div class="sign-in__form__forget-pass">
          <a class="link" href="#">{{t('signIn.forget_password')}}</a>
        </div>
        <div class="text-center">
          <button class="i-btn sign-in__form__submit" type="submit">{{t('signIn.sign_in')}}</button><br /><a class="link" href="#">{{t('signIn.dont_have_account')}}</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'SignIn',
  data: function() {
    return ({
      user: {
        email: '',
        password: ''
      },
      showError: false
    })
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'auth/errorMessage'
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async onSubmit(event) {
      event.preventDefault()
      await this.login(this.user)
      if(this.errorMessage) {
        this.showError = true
      }
    },
  },
  watch: {
    user: {
      handler(){
        this.showError = false
      },
      deep: true
    }
  }
}
</script>
