<template>
  <div id="term-details" className="main-container">
    <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>
    <h2 className="reports__b-title">{{ t('agents.terms.modify') }}</h2>
    <div className="white-box mt-4">
      <Form :term="termDetail" v-if="loadDone"/>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from '../../shared/Breadcrumb'
import Form from '../terms/_Form'

export default {
  setup() {
    const { t } = useI18n()

    return { t }
  },
  components: {
    Breadcrumb,
    Form
  },
  data: function () {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.home'), routerName: 'Home', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.edit'), routerName: '#' }
      ],
      loadDone: false
    }
  },
  methods: {
    ...mapActions({
      getDetails: 'terms/getDetails'
    })
  },
  computed: {
    ...mapGetters({
      termDetail: 'terms/termDetail'
    }),
    currentTermId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    await this.getDetails(this.currentTermId)
    this.loadDone = true
  }
}
</script>

<style>
  textarea.i-input {
    height: 100%;
  }
</style>
