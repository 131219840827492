<template>
  <div id="houses">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      <HouseForm :house="house" action="create"/>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../shared/Breadcrumb'
import HouseForm from './_HouseForm'
import { useI18n } from 'vue-i18n'

export default {
  name: 'New',
  components: {
    Breadcrumb,
    HouseForm
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.houses'), routerName: 'Agents Houses', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.new'), routerName: '#' }
      ],
      house: {
        agent_id: '',
        name: '',
        house_number: '',
        house_letter: '',
        house_number_addition: '',
        city: '',
        street: '',
        post_code: '',
        description: '',
        contract_length: '',
        max_people_allowed: '',
        rent_price: '',
        deposit: '',
        start_hiring_date: '',
        images: []
      }
    }
  }
}
</script>
