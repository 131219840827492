import TermsApi from '../../../backend/terms'
import router from '../../../router'

const TERM_LIST = 'TERM_LIST'
const SHOW = 'SHOW'
const ERROR = 'ERROR'

const state = {
  terms: [],
  termDetail: {
    type: '',
    content: ''
  },
  errors: {}
}

export const mutations = {
  [TERM_LIST] (state, terms) {
    state.terms = terms
  },
  [SHOW] (state, term) {
    state.termDetail = term
  },
  [ERROR] (state, errors) {
    state.errors = errors
  }
}

export const actions = {
  getTermList ({ commit }) {
    return TermsApi.termList().then(response => {
      let terms = response.data
      commit(TERM_LIST, terms)
    }).catch(error => {
      console.log(error)
    })
  },
  getDetails({ commit }, termId) {
    return TermsApi.getDetails(termId).then(response => {
      let term = response.data
      commit(SHOW, term)
    }).catch(error => {
      console.log(error)
    })
  },
  update({ commit }, termAttributes) {
    return TermsApi.update(termAttributes).then(() => {
      commit(ERROR, {})
      router.push( { name: 'Configuration' }).then(() => {})
    }).catch(error => {
      console.log(error)
    })
  },
  create({ commit }, termAttributes) {
    return TermsApi.create(termAttributes).then(() => {
      commit(ERROR, {})
      router.push( { name: 'Configuration' }).then(() => {})
    }).catch(error => {
      commit(ERROR, error.response.data.messages)
    })
  },
  findByType({ commit }, type) {
    return TermsApi.findByType(type).then(response => {
      let term = response.data
      commit(SHOW, term)
    }).catch(error => {
      console.log(error)
    })
  },
}

export const getters = {
  terms: state => state.terms,
  termDetail: state => state.termDetail,
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
