<template>
  <div id="share-email-modal"
       class='modal fade review-modal show' role="dialog" style='display: block;'>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <h2 class="review__title">
            <i class="icon icon-page review__title__icon"></i>
            <span>{{ t("reports.modalShareEmail.title") }}</span>
          </h2>
          <button type="button" class="i-text -danger review-modal__close" @click="$emit('hideModalShareEmail')">&times;</button>
          <div class="white-box p-1">
            <form>
              <section class="form-group owner">
                <div>{{ t("reports.modalShareEmail.ownerAgent") }}</div>
                <div v-for="(agent, index) in ownerUser" :key="agent.email">
                  <input type="checkbox" :id="`cbAgent${index}`" name="selectedShareEmail"
                         v-model="recipientsSelected" :value="agent.email">
                  <label :for="`cbAgent${index}`" class="ml-1">{{ agent.name }} &lt;{{ agent.email }}&gt;</label>
                </div>
              </section>

              <section class="form-group hirer" v-if="clientUser.length > 0">
                <div>{{ t("reports.modalShareEmail.hirer") }}</div>
                <div v-for="(client, index) in clientUser" :key="client.email">
                  <input type="checkbox" :id="`cbClient${index}`" name="selectedShareEmail"
                         v-model="recipientsSelected" :value="client.email">
                  <label :for="`cbClient${index}`" class="ml-1"> {{ client.name }} &lt;{{ client.email }}&gt; </label>
                </div>
              </section>

              <section class="form-group custom">
                <div>{{ t("reports.modalShareEmail.manualAdded") }}</div>

                <div v-for="(client, index) in customRecipients" :key="client.email">
                  <input type="checkbox" :id="`cbCustom${index}`" name="selectedShareEmail"
                         v-model="recipientsSelected" :value="client.email" checked>
                  <label :for="`cbCustom${index}`" class="ml-1"> {{ client.name }} &lt;{{ client.email }}&gt; </label>
                </div>
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-sm-6">
                        <label>{{ t("reports.modalShareEmail.email") }}</label>
                        <input type="email" v-model="newCustomRecipient.email" class="i-input">
                        <InvalidFieldMessage v-bind:errorMessages="errorMessage" field="email"/>
                      </div>

                      <div class="col-sm-6">
                        <label>{{ t("reports.modalShareEmail.name") }}</label>
                        <input type="text" v-model="newCustomRecipient.name" class="i-input">
                        <InvalidFieldMessage v-bind:errorMessages="errorMessage" field="name"/>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group-add col-md-2">
                    <button type="button" @click="addNewCustomUser()" class="mt-30 mt-sm-10">
                      <i class="icon icon-check-circle"></i>
                    </button>
                  </div>
                </div>
              </section>
            </form>
          </div>

          <div>
            <button class="btn i-btn" @click="share" :disabled="recipientsSelected.length === 0">{{ t("buttons.save") }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InvalidFieldMessage from '../../common/InvalidFieldMessage'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalShareEmail',
  props: ['sharedUsers', 'currentReportId'],
  components: { InvalidFieldMessage },
  data: function() {
    return {
      recipientsSelected: this.sharedUsers.map(user => user.email),
      newCustomRecipient: { name: '', email: '' },
      customRecipients: [],
      errorMessage: { name: '', email: '' }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ownerUser() {
      return this.sharedUsers.filter(user => user.type === 'owner_agent')
    },
    clientUser() {
      return this.sharedUsers.filter(user => user.type === 'client' && !!user.email)
    },
    selectedUsers(){
      let _sharedUser = this.sharedUsers.filter(user => this.recipientsSelected.includes(user.email))
      let _customUser = this.customRecipients.filter(user => this.recipientsSelected.includes(user.email))
      return _sharedUser.concat(_customUser)
    }
  },
  methods: {
    addNewCustomUser() {
      this.errorMessage = { name: '', email: '' }
      if(!this.newCustomRecipient.email) {
        this.errorMessage.email = this.t('reports.modalShareEmail.emailRequired')
        return false
      }

      if(!this._validateEmail(this.newCustomRecipient.email)) {
        this.errorMessage.email = this.t('reports.modalShareEmail.emailInvalid')
        return false
      }

      if(!this.newCustomRecipient.name) {
        this.errorMessage.name = this.t('reports.modalShareEmail.nameRequired')
        return false
      }

      if(this._emailAlreadyExisted(this.newCustomRecipient.email)) {
        this.errorMessage.email = this.t('reports.modalShareEmail.emailExisted')
        return false
      }

      this.customRecipients.push(Object.assign({}, this.newCustomRecipient))
      this.recipientsSelected.push(this.newCustomRecipient.email)
      this.newCustomRecipient = { name: '', email: '' }
    },
    share(){
      this.$store.dispatch('reports/sharePdf', { reportId: this.currentReportId, recipients: this.selectedUsers })
      this.$emit('hideModalShareEmail')
    },
    _validateEmail(email) {
      return email.match(
          /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    _emailAlreadyExisted(email) {
      if(this.sharedUsers.filter(user => user.email === email).length > 0 ||
          this.customRecipients.filter(user => user.email === email).length > 0) {
        return true
      }
    }
  }
}
</script>
