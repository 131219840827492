<template>
  <div id="houses">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>
      <div class="row justify-content-between align-items-center mb-35 mb-md-25">
        <div class="col-xl-5 mt-xl-10">
          <h1 class="page-title">
            {{ t('agents.houses.title') }}
          </h1>
        </div>
        <div class="col-xl-auto mt-xl-20">
          <div class="row gutter-24 gutter-sm-10">
            <div class="col-auto">
              <router-link class="i-btn" :to="{ name: 'Agents New House' }">
                {{ t('buttons.save') }}</router-link>
            </div>
            <div class="col">
              <SearchForm @form-searching="formSearching"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row gutter-40 gutter-md-15">
        <div class="col-md-4 col-sm-6" v-for="house in houseList" v-bind:key="house.id">
          <House :house="house"></House>
        </div>
      </div>

      <v-pagination
          v-model="page"
          :pages="totalPages"
          :range-size="1"
          @update:modelValue="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VPagination from '@hennge/vue3-pagination'
import SearchForm from './SearchForm'
import House from './_House'
import Breadcrumb from '../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Houses',
  components: {
    House,
    Breadcrumb,
    VPagination,
    SearchForm
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.houses'), routerName: 'Agents Houses', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.list'), routerName: '#' }
      ],
      searchParams: {},
      page: parseInt(this.$route.query.page) || 1
    }
  },
  computed: {
    ...mapGetters({
      houses: 'houses/houseList'
    }),
    houseList() {
      return this.houses.list
    },
    totalPages(){
      return this.houses.totalPages
    },
    paramsCurrentPage() {
      if(this.page !== 1) {
        return { page: this.page }
      }
      return {}
    }
  },
  methods: {
    ...mapActions({
      setList: 'houses/setList'
    }),
    changePage() {
      this.setList({ ...this.paramsCurrentPage, ...this.searchParams })
    },
    formSearching(params) {
      this.searchParams = params
    }
  },
  mounted: function () {
    this.setList(this.paramsCurrentPage)
  }
}
</script>
