<template>
  <div class="text-center alert alert-warning">
    {{ t('agents.table.no_data') }}
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
    name: 'TableNoData',
    setup() {
      const { t } = useI18n()
      return { t }
    },
  }
</script>
