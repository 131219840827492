import ApiService from './api_services'
import axios from 'axios'

const ReportApi = {
  getList: function(params) {
    return ApiService.get('/reports', params)
  },

  create: function(reportParams) {
    return ApiService.post('/reports', { reports: reportParams })
  },

  delete: function(id) {
    return ApiService.delete(`/reports/${id}`)
  },

  statistics: function (params) {
    return ApiService.get('/reports/statistics', params)
  },

  get: function(id) {
    return ApiService.get(`/reports/${id}`)
  },

  getMeterInformation: function(id) {
    return ApiService.get(`/reports/${id}/report_meter_information`)
  },

  getClientsInformation: function(id) {
    return ApiService.get(`/reports/${id}/report_clients_information`)
  },

  download: function (id) {
    return axios.request({
      url: `/reports/${id}/download`,
      method: 'POST',
      // responseType: 'blob',
    })
  },

  sharePdf: function (params) {
    let id = params['reportId']
    let recipients = params['recipients']
    return ApiService.post(`/reports/${id}/share_pdf`, { recipients: recipients })
  }
}

export default ReportApi
