<!-- pages 9, 10, 11, 12 /16 -->
<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <h2 class="new-reports-box__title mt-04">{{ this.report_info.house_address }} - {{ this.report_info.zipcode }}</h2>
      <div class="i-text -gray-4 font-bold mt-05">
        {{ this.report_info.house_address }}
        <br>
        {{ this.report_info.city }}
      </div>

      <div class="i-text -gray-3 mt-40 font-bold mb-40 mt-md-30 mb-md-30" v-html="t('agents.reports.new.upload_description')"></div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>

      <div v-for="(reportComment, index) in commentParams" :key="index">
        <div class="new-reports-box -bg-white mt-30" v-if="reportComment['_destroy'] !== 1">
          <button class="trash-button clear-comment"
                  @click="removeComment(index)">
            ×</button>
          <div class="d-flex">
            <h2 class="new-reports-box__title mt-0 mb-20">{{ t('agents.reports.new.comments') }}</h2>
          </div>
          <div class="row gutter-xl-15 gutter-sm-10">
            <div class="col-md-6 mb-35 mb-sm-20" v-if="commentParams[index]">
              <label>{{ t('agents.reports.new.select_comment_type') }}</label>
              <input type="hidden"
                     v-model='commentParams[index]["id"]'>

              <select class="i-select"
                      v-model='commentParams[index]["type"]'>
                <option value="">{{ t('agents.reports.new.select_comment_type') }}</option>
                <option value="feedback">{{ t('agents.reports.new.feedback') }}</option>
                <option value="general">{{ t('agents.reports.new.general') }}</option>
                <option value="remaining">{{ t('agents.reports.new.remaining_appointments') }}</option>
                <option value="waarborgsom" v-if="isFinalReport">{{ t('agents.reports.new.waarborgsom') }}</option>
              </select>
              <InvalidFieldMessage v-bind:errorMessages="commentMessageErrors"
                                   v-bind:field="`report_comments[${index}].type`"/>
            </div>

            <div class="col-md-6 mb-35 mb-sm-20">
              <label>{{ t('agents.reports.new.enter_the_comment_name') }}</label>
              <input type="text" name="" class="i-input"
                     v-model="commentParams[index]['subject']"/>
              <InvalidFieldMessage v-bind:errorMessages="commentMessageErrors"
                                   v-bind:field="`report_comments[${index}].subject`"/>
            </div>
          </div>

          <div class="new-report-line mb-35 mb-sm-20">
            <label>{{ t('agents.reports.new.comments_placeholder') }}</label>
            <textarea class="i-textarea -small height-auto" rows="3"
                      v-if="commentParams[index]"
                      v-model="commentParams[index]['content']"/>
            <InvalidFieldMessage v-bind:errorMessages="commentMessageErrors"
                                 v-bind:field="`report_comments[${index}].content`"/>
          </div>

          <hr class="mt-0 mb-25 ml-n30 mr-n30 ml-lg-00 mr-lg-00">

          <label>foto’s</label>
          <div class="mb-35 mb-sm-20">
            <ImageUploaderPreview
                @upload-images="uploadCommentImages"
                :index="index"
                :currentImages="commentImagesDisplayed[index]" class="mt-4">
            </ImageUploaderPreview>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-40 mt-md-30 mb-35">
        <div class="col-sm-12 col-md-6">
          <a class="i-btn -bg-light -success pt-12 pb-10 -block" href="javascript:void(0)"
             title="" v-on:click="addNewComment">
            +&nbsp;
            {{ t('agents.reports.new.add_new_comment') }}
          </a>
        </div>
      </div>

      <div class="new-report-line mb-20 mt-20">
        <div class="row gutter-10 justify-content-center">
          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -white -block pl-03 pr-03" type="button"
                    @click="navigateTo(previousStep(), currentReportId)">
              <i class="fa fa-long-arrow-alt-left mr-08 ml-0 mt-0"></i> {{ t('buttons.back') }}
            </button>
          </div>

          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -info -block pl-03 pr-03" type="button" @click="submit">
              {{ t('buttons.next') }} <i class="fa fa-long-arrow-alt-right ml-08 mr-0 mt-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import nestedBlock from '../../../../mixins/nestedBlock'
import InvalidFieldMessage from '../../../common/InvalidFieldMessage'
import currentReport from '@/mixins/currentReport'
import reportNavigationButtonMixin from '@/mixins/reportNavigationButtonMixin'
import typeOfReport from '@/mixins/typeOfReport'
import ImageUploaderPreview from '@/views/shared/ImageUploaderPreview'

export default {
  name: 'ReportStep3',
  mixins: [nestedBlock, currentReport, reportNavigationButtonMixin, typeOfReport],
  components: {
    Breadcrumb,
    ImageUploaderPreview,
    InvalidFieldMessage
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: '#' }
      ],
      report_comments_attributes: {},
      report_info: {},
      numberOfComments: 1,
      commentAttribute:  { id: '', type: '', subject: '', content: '', _destroy: 0 },
      commentParams: [],
      commentImagesDisplayed: {},
      commentImageKeys: {}
    }
  },
  computed: {
    ...mapGetters({
      reportErrors: 'reports/reportErrors',
      commentMessageErrors: 'reportComment/errors',
    })
  },
  methods: {
    ...mapActions({
      increaseComments: 'reports/increaseComments',
      createReport: 'reports/createReport',
      reportCommentCreateOrUpdate: 'reportComment/createOrUpdate',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      turnOffLoadingPage: 'loadingPage/turnOffLoadingPage'
    }),
    addNewComment() {
      this.commentParams.push(Object.assign({}, this.commentAttribute))
    },
    removeComment(index){
      this.commentParams[index]['_destroy'] = 1
    },
    uploadCommentImages(params, index){
      if(params) {
        this.commentImageKeys[index] = params.filter(_image => _image.uploadDone).map(_image => (_image.blob_key))
      }
    },
    _formData() {
      let formData = new FormData()
      this.commentParams.forEach((commentParam, index) => {
        formData.append(`reports[report_comments_attributes][${index}][id]`, commentParam.id)
        formData.append(`reports[report_comments_attributes][${index}][code]`, commentParam.code || `${this.currentReportId}_${index}`)
        formData.append(`reports[report_comments_attributes][${index}][type]`, commentParam.type)
        formData.append(`reports[report_comments_attributes][${index}][subject]`, commentParam.subject)
        formData.append(`reports[report_comments_attributes][${index}][content]`, commentParam.content)
        formData.append(`reports[report_comments_attributes][${index}][_destroy]`, commentParam._destroy || 0)

        if(this.commentImageKeys[index]) {
          this.commentImageKeys[index].forEach((commentKey, _imageIndex) => {
            formData.append(`reports[report_comments_attributes][${index}][image_keys][${_imageIndex}]`, commentKey)
          })
        }
      })

      return formData
    },
    buildCommentGroup() {
      let result = {};
      [...new Array(this.numberOfComments).keys()].forEach((element) => {
        result[element] = {}
      })
      return result
    },
    previousStep() {
      let step = 'FormReportStep2'
      if(this.reportDetails.type === 'pre_inspection') {
        step = 'FormReportStep1'
      }

      return step
    },
    async submit() {
      this.turnOnLoading()
      await this.reportCommentCreateOrUpdate({ reportId: this.currentReportId, formData: this._formData() })

      if(Object.keys(this.commentMessageErrors).length === 0) {
        let params = {
          id: this.currentReportId,
          completed_step: this.currentStep,
        }
        await this.createReport(params)
      }
      this.turnOffLoadingPage()
    },
  },
  async mounted() {
    this.$emit('header-title', this.t('breadcrumb.reports'))

    await this.getReportInfo(this.currentReportId)
    this.report_info = this.reportDetails.report_basic_information

    if(this.reportComments.length > 0) {
      this.report_comments_attributes = this.reportComments
      this.numberOfComments = this.reportComments.length
      this.commentParams = this.reportComments
    } else {
      this.report_comments_attributes = this.buildCommentGroup()
      this.numberOfComments = 2
      this.commentParams = [Object.assign({}, this.commentAttribute)]
    }

    this.commentParams.forEach((_comment, index) => {
      let _commentImages = _comment.images
      let self = this
      if(_commentImages) {
        self.commentImagesDisplayed[index] = []
        _commentImages.forEach(image =>  {
          self.commentImagesDisplayed[index].push({ url: image.thumb_url, blob_key: image.blob_key, uploadDone: true })
        })
      }
    })
  },
}
</script>

<style>
  .trash-button {
    right: -5px;
    position: absolute;
    z-index: 1;
    display: flex;
    color: #fff;
    background: #F64B53;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-size: 22px;
    top: -9px;
  }
  .new-reports-box {
    position: relative;
  }
</style>
