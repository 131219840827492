import { createRouter, createWebHistory } from 'vue-router'
import AgentRoutes from './agent_routes'
import redirectUrlDefault from '../settings/redirectUrlDefault'
import TheContainer from '../containers/TheContainer'
import PdfDownload from '@/views/agents/reports/PdfDownload'
import store from '@/store'
import loginRouters from '../settings/loginRoutes'
// User
import SignIn from '../views/SignIn'
import EditUser from '../views/users/Edit'
import notRequireLogInRoutes from '@/settings/notRequireLogInRoutes'

import Page404 from '@/views/shared/Page404'
import TheContainerNoLayout from '@/containers/TheContainerNoLayout'
import UserChangePassword from '@/views/passwords/UserChangePassword'

const routes = [
  ...AgentRoutes,
  { path: '/', component: TheContainer, name: 'Root', redirect: redirectUrlDefault,
    children: [
      { path: 'users/edit', component: EditUser, name: 'EditUser' },
    ]
  },
  { path: '/agents/reports/:id/pdf', component: PdfDownload, name: 'ReportPdfDownload' },
  { path: '/users/sign_in', component: SignIn, name: 'Users Sign In' },
  { path: '/', component: TheContainerNoLayout, name: 'TheContainerNoLayout', redirect: redirectUrlDefault,
    children: [
      { path: '/users/reset-password', component: UserChangePassword, name: 'UserChangePassword' }
    ],
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: Page404 }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // let resourceType = store.getters['auth/resourceType']
  let loggedIn = store.getters['auth/isLoggedIn']

  if(!loggedIn && !notRequireLogInRoutes.includes(to.name)) {
    next({ name: 'Users Sign In', params: { next: to.path } })
  } else if (loggedIn && (loginRouters.includes(to.name))) {
    router.push('/agents').then(() => {})
  } else {
    next()
  }
})

export default router
