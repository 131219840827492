import ReportApi from '../../../backend/reports'
import router from '../../../router'
import _ from 'lodash'
import store from '@/store'
import { createToast } from 'mosha-vue-toastify'
import { ToastSettings } from '@/settings/toast'
import i18n from '@/i18n'

const { t } = i18n.global
const INCREASE_NUM_COMMENTS = 'INCREASE_NUM_COMMENTS'
const UPDATE_REPORT_PARAMS = 'UPDATE_REPORT_PARAMS'
const CREATE_REPORT = 'CREATE_REPORT'
const REPORTS = 'REPORTS'
const LOAD_MORE = 'LOAD_MORE'
const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
const SET_IMAGE_COMMENTS = 'SET_IMAGE_COMMENTS'
const REPORT_DETAIL = 'REPORT_DETAIL'
const VALIDATE = 'VALIDATE'
const STATISTIC = 'STATISTIC'
const SET_METER_INFORMATION = 'SET_METER_INFORMATION'
const SET_CLIENTS_INFORMATION = 'SET_CLIENTS_INFORMATION'
const SHARE_PDF = 'SHARE_PDF'

const state = {
  reportDetails: {
    report_basic_information: {},
    report_client_information: {},
    report_key_information: [],
    report_meter_information: {},
    report_comments: [],
    report_hirer_signatures: [],
    report_owner_signatures: [],
    report_signatures: {},
  },
  reportList: {
    list: [],
    currentPage: 1,
    totalPages: 1
  },
  numberOfCommentAttributes: 1,
  reportErrors: {},
  reportStatistics: {
    delivery: 0,
    pre_inspection: 0,
    final_inspection: 0,
    total_reports: 0
  },
  meterInformation: {},
  clientsInformation: {}
}

export const mutations = {
  [INCREASE_NUM_COMMENTS] (state) {
    state.numberOfCommentAttributes += 1
  },

  [SET_IMAGE_COMMENTS] (state, params) {
    Object.entries(state.reports.report_comments_attributes).forEach(([_index, _report_comments_attribute]) => {
      if(params.images[`reports-comment-${_index}`] !== undefined) {
        _report_comments_attribute['image_uploaded_keys'] = params.images[`reports-comment-${_index}`].map(obj => obj.key)
      }
    })
  },
  [CREATE_REPORT](state) {
    state.reports = {
      type: 'delivery',
      report_basic_information_attributes: {},
      report_client_information_attributes: {},
      report_key_information_attributes: {},
      report_meter_information_attributes: {},
      meter_comment: '',
      keys_comment: '',
      report_comments_attributes: {},
      report_hirer_signatures_attributes: {},
      report_owner_signatures_attributes: {}
    }
  },
  [REPORTS] (state, data) {
    state.reportList.list = data.list
    state.reportList.currentPage = data.current_page
    state.reportList.totalPages = data.total_pages
  },
  [LOAD_MORE] (state, reportsByPage) {
    state.reportList.list = state.reportList.list.concat(reportsByPage.list)
  },
  [UPDATE_CURRENT_PAGE] (state, newPage) {
    state.reportList.currentPage = newPage
  },
  [REPORT_DETAIL] (state, reportDetails) {
    state.reportDetails = reportDetails
  },
  [VALIDATE] (state, reportErrors) {
    state.reportErrors = reportErrors
  },
  [STATISTIC] (state, data) {
    state.reportStatistics = data
  },
  [SET_METER_INFORMATION] (state, data) {
    state.meterInformation = data
  },
  [SET_CLIENTS_INFORMATION] (state, data) {
    state.clientsInformation = data
  },
  [SHARE_PDF] () {}
}

export const actions = {
  async setList({ commit }, params = {}) {
    await ReportApi.getList(params).then(response => {
      commit(REPORTS, response.data)
      store.dispatch('loadingPage/turnOffLoadingPage').then(() => {})
      delete params['order_query']
      router.replace({ name: 'Reports', query: params }).then(() =>{})
    }).catch(error => {
      console.error(error)
      store.dispatch('loadingPage/turnOffLoadingPage').then(() => {})
    })
  },
  loadMore( { commit }, params) {
    ReportApi.getList(params).then(response => {
      commit(LOAD_MORE, response.data)
      commit(UPDATE_CURRENT_PAGE, params['page'])
    }).catch(error => {
      console.error(error)
    })
  },
  increaseComments({ commit }) {
    commit(INCREASE_NUM_COMMENTS)
  },
  setUploadedImageComment({ commit }, params) {
    commit(SET_IMAGE_COMMENTS, params)
  },
  updateReportParams({ commit }, attributes) {
    commit(UPDATE_REPORT_PARAMS, attributes)
  },

  async createReport({ commit }, params) {
    await ReportApi.create(params).then(response => {
      commit(CREATE_REPORT)
      switch(router.currentRoute._value.name) {
        case 'Reports':
          router.push( { name: 'FormReportStep1', params: { id: response.data.id } }).then(() => {})
          break
        case 'Home':
          router.push( { name: 'FormReportStep1', params: { id: response.data.id } }).then(() => {})
          break
        // case 'FormReportStep1':
        //   // eslint-disable-next-line no-case-declarations
        //   let nextStep = 'FormReportStep2';
        //   if(response.data.type === 'pre_inspection') {
        //     nextStep = 'FormReportStep3';
        //   }
        //   router.push( { name: nextStep, params: { id: response.data.id } }).then(() => {});
        //   break;
        case 'FormReportStep2':
          router.push( { name: 'FormReportStep3', params: { id: response.data.id } }).then(() => {})
          break
        case 'FormReportStep3':
          router.push( { name: 'FormReportStep5', params: { id: response.data.id } }).then(() => {})
          break
        case 'FormReportStep6':
          router.push( { name: 'Report show', params: { id: response.data.id } }).then(() => {})
          break
      }
    }).catch(error => {
      let errorResponse = error.response.data.messages
      let filteredKeyErrors
      switch(router.currentRoute._value.name) {
        case 'FormReportStep1':
          filteredKeyErrors = Object.keys(errorResponse).filter((item) =>
              item.includes('report_basic_information') || item.includes('report_client_information')
          )
          break
        case 'FormReportStep2':
          filteredKeyErrors = Object.keys(errorResponse).filter((item) =>
              item.includes('report_meter_information') || item.includes('report_key_information')
          )
          break
        case 'FormReportStep3':
          filteredKeyErrors = Object.keys(errorResponse).filter((item) =>
              item.includes('report_comments')
          )
          break
        case 'FormReportStep6':
          filteredKeyErrors = Object.keys(errorResponse).filter((item) =>
              item.includes('report_owner_signature') || item.includes('report_hirer_signature')
          )
          break
      }

      if(filteredKeyErrors.length === 0) {
        commit(VALIDATE, {})
        if(router.currentRoute._value.name !== 'FormReportStep6') {
          router.push( { name: params.nextStep })
        }
        return
      }
      let newError = _.pick(errorResponse, filteredKeyErrors)
      commit(VALIDATE, newError)
    })
  },

  async getReportInfo({ commit }, id) {
    await ReportApi.get(id).then(response => {
      commit(REPORT_DETAIL, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

  // eslint-disable-next-line no-unused-vars
  async deleteReport( { commit }, id) {
    await ReportApi.delete(id).then(() => {
      createToast(t('reports.messages.report_deleted_successful'), ToastSettings.success)
      router.push( { name: 'Home' }).then(() => {})
    }).catch(error => {
      console.error(error)
    })
  },

  getStatistic({ commit }, params = {}) {
    ReportApi.statistics(params).then(response => {
      commit(STATISTIC, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

  async meterInformation({ commit }, id) {
    await ReportApi.getMeterInformation(id).then(response => {
      commit(SET_METER_INFORMATION, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

  async clientsInformation({ commit }, id) {
    await ReportApi.getClientsInformation(id).then(response => {
      commit(SET_CLIENTS_INFORMATION, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

  sharePdf({ commit }, params) {
    return ReportApi.sharePdf(params).then(response => {
      commit(SHARE_PDF, response.data)
      createToast(t('reports.messages.shared_success'), ToastSettings.success)
    }).catch(error => {
      createToast(error.response.data.messages, ToastSettings.error)
    })
  },
}

export const getters = {
  reportDetails: state => state.reportDetails,
  numberOfKeyAttributes: state => state.numberOfKeyAttributes,
  numberOfCommentAttributes: state => state.numberOfCommentAttributes,
  numberOfHirerSignatures: state => state.numberOfHirerSignaturesAttributes,
  numberOfOwnerSignatures: state => state.numberOfOwnerSignaturesAttributes,
  list: state => state.reportList,
  reportErrors: state => state.reportErrors,
  statistics: state => state.reportStatistics,
  meterInformation: state => state.meterInformation,
  clientsInformation: state => state.clientsInformation
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
