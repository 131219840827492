{
  "reports": {
    "totalReport": "Rapporten",
    "types": {
      "delivery": "Proces-verbaal van oplevering",
      "pre_inspection": "Voorinspectierapport",
      "final_inspection": "Eindinspectierapport"
    },
    "steps": {
      "step-add-information": "Informatie toevoegen",
      "step-meter-keys": "Meterstanden en Sleutels",
      "step-comments": "Opmerking",
      "step-overviews": "Overzicht",
      "step-signature": "Handtekening",
      "completed": "Voltooid"
    },
    "state": "Status",
    "states": {
      "wip": "Concept",
      "completed": "Voltooid"
    },
    "messages":{
      "shared_success": "Email succesvol verzonden naar huurder!",
      "saveEmailConfig": "E-mailconfiguratie succesvol opgeslagen!",
      "report_deleted_successful": "Rapport is succesvol verwijderd",
      "confirm_delete_report": "Weet u zeker dat u dit rapport wilt verwijderen?"
    },
    "modalShareEmail": {
      "title": "Selecteer emailadres:",
      "hirer": "Huurder",
      "ownerAgent": "Eigenaar/Makelaar",
      "manualAdded": "Handmatig toegevoegd",
      "name": "Naam",
      "email": "Email",
      "emailRequired": "Email is vereist",
      "emailInvalid": "Email is ongeldig",
      "emailExisted": "Email bestaat al",
      "nameRequired": "Naam is vereist"
    },
    "pageStep1": {
      "addMoreHirer": "Huurder toevoegen",
      "removeHirer": "Huurder verwijderen"
    },
    "placeHolders": {
      "basicInformation": {
        "ownerName": "",
        "address": "",
        "zipCode": "",
        "city": "",
        "contactPerson": "",
        "telephone": ""
      },
      "clientInformation": {

      }
    }
  }
}
