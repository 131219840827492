<template>
  <div>
    <span class="notifications__item__timeline">
            {{ dateOfNotification(notification.created_at) }}
          </span>
    <div class="notifications__item__avt">
      <img src="~@/assets/images/avt.png"  alt=""/>
    </div>
    <span class="notifications__item__time">
            {{ timeAgo(notification.created_at)  }}
          </span>
    <div class="notifications__item__info">
      <h4 class="notifications__item__name">
        {{ notification.sender.full_name }}
      </h4>
      <div class="notifications__item__role i-label mb-0"
           :class="badgeClassNameSenderType(notification.sender.type)">
        {{ notification.sender.type }}
      </div>
    </div>
    <p class="notifications__item__action">
      <span v-html="displayHtmlStyle(notification.title)"></span>
    </p>

    <div v-if="notification.content !== null" class="notifications__item__comments">
      {{ notification.content }}
    </div>
    <div v-else-if="notification.images.length > 0" class="notifications__item__imgs">
      <div class="notifications__item__img"
           v-for="(image, index) in notification.images" v-bind:key="index">
        <img :src="image"  alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'NotificationItem',
  props: ['notification'],
  methods: {
    notificationItemClassName(index) {
      if(index %2 === 0 ) {
        return 'notifications__item -green'
      } else {
        return 'notifications__item -orange'
      }
    },
    dateOfNotification(the_date) {
      return moment(the_date).format('MMMM DD, YYYY')
    },
    timeAgo(theDate) {
      return moment(theDate).fromNow()
    },
    displayHtmlStyle(text) {
      return text
    },
    badgeClassNameSenderType(type) {
      switch(type){
        case 'Client':
          return '-primary'
        case 'Estate Agent':
          return '-warning'
      }
    }
  }
}
</script>
