<template>
  <div v-show="isActive">
    <div class="i-text -danger -form-error">
      <span v-if="typeof(messages) === 'string'" >
        {{ messages }}
      </span>
      <ul v-else v-for="(message, index) in messages" v-bind:key="index">
        <li>{{ message }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvalidFieldMessage',
  props: ['errorMessages', 'field'],
  computed: {
    messages() {
      return this.errorMessages[this.field] || []
    },
    isActive() {
      return this.messages.length
    }
  }
}
</script>
