<template>
  <form class="search-form">
    <input class="search-form__input" v-model="searchParams"
           v-bind:placeholder="t('agents.searchForm.placeholder')" />
    <button class="search-form__submit"><i class="icon icon-search"></i></button>
  </form>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'SearchForm',
  data () {
    return {
      searchParams: ''
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  watch: {
    searchParams: function (value){
      let searchQuery = { name_or_house_number_cont: value }
      this.$emit('form-searching', searchQuery)
      this.$store.dispatch('houses/setList', searchQuery)
    }
  }
}
</script>
