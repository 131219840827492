import ApiService from './api_services'

const ReportHouseImagesApi = {
  upload: function(reportId, formData) {
    return ApiService.post(`/reports/${reportId}/report_house_images`, formData)
  },
  assign_images: function(reportId, imageKeys) {
    return ApiService.put(`/reports/${reportId}/assign_images`, { image_keys: imageKeys })
  },
  list: function (reportId) {
    return ApiService.get(`/reports/${reportId}/report_house_images`)
  }
}

export default ReportHouseImagesApi
