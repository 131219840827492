<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <h2 class="new-reports-box__title mt-04">{{ this.report_info.house_address }} - {{ this.report_info.zipcode }}</h2>
      <div class="i-text -gray-4 font-bold mt-05">
        {{ this.report_info.house_address }}
        <br>
        {{ this.report_info.city }}
      </div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>
      <div class="new-reports-box -bg-white">
        <h2 class="new-reports-box__title mt-0 mb-20 mb-lg-15">{{ t('agents.reports.new.meter_info') }}</h2>
        <div class="row gutter-xl-15 gutter-sm-10">
          <div class="col-md-6 mb-35 mb-sm-20">
            <div class="i-input-unit">
              <label>{{ t('agents.reports.new.step2.label.water') }}</label>
              <input type="text" name="water" class="i-input"
                     v-model='report_meter_information_params.water'/>
              <InvalidFieldMessage v-bind:errorMessages="meterInformationError"
                                   field="water"/>
            </div>
          </div>

          <div class="col-md-6 mb-35 mb-sm-20">
            <div class="i-input-unit">
              <label>{{ t('agents.reports.new.step2.label.gas') }}</label>
              <input type="text" name="gas" class="i-input"
                     v-model='report_meter_information_params.gas'/>
              <InvalidFieldMessage v-bind:errorMessages="meterInformationError"
                                   field="gas"/>
            </div>
          </div>
        </div>

        <div class="row gutter-xl-15 gutter-sm-10">
          <div class="col-sm-6 mb-35 mb-sm-20">
            <div class="i-input-unit">
              <label>{{ t('agents.reports.new.day') }}</label>
              <input type="text" name="electric_day" class="i-input"
                     v-model='report_meter_information_params.electric_day'/>
              <InvalidFieldMessage v-bind:errorMessages="meterInformationError"
                                   field="electric_day"/>
              <span class="i-input-unit__text mt-0">/kWh</span>
            </div>
          </div>
          <div class="col-sm-6 mb-35 mb-sm-20">
            <div class="i-input-unit">
              <label>{{ t('agents.reports.new.night') }}</label>
              <input type="text" name="electric_night" class="i-input"
                     v-model='report_meter_information_params.electric_night'/>
              <InvalidFieldMessage v-bind:errorMessages="meterInformationError"
                                   field="electric_night"/>
              <span class="i-input-unit__text mt-0">/kWh</span>
            </div>
          </div>
        </div>
        <div class="mb-05">
          <label>{{ t('agents.reports.new.comments_placeholder') }}</label>
          <textarea class="i-textarea -small height-auto"
                    v-model="meter_comment" rows="3">
          </textarea>
        </div>

        <ImageUploaderPreview
            @upload-images="uploadMeterImages"
            :currentImages="meterImagesDisplayed" class="mt-4">
        </ImageUploaderPreview>
      </div>

      <div class="new-reports-box -bg-white mt-3 mb-35">
        <h2 class="new-reports-box__title mt-0 mb-05 mb-lg-15">{{ t('agents.reports.new.keys_info') }}</h2>
        <div class="i-text -gray-4 font-bold mb-20">{{ t('agents.reports.new.keys_info_desc')}}</div>

        <div v-for="(reportKey, index) in keyParams" :key="index">
          <div class="keys-form-group mb-5 pt-4" v-if="reportKey['_destroy'] !== 1">
            <div v-if="keyParams[index]" class="row gutter-xl-15 gutter-sm-10">
              <div class="col-md-6 mb-20 mb-sm-20">
                <input type="hidden" name="id" v-model='keyParams[index]["id"]'>
                <input type="hidden" name="code" v-model='keyParams[index]["code"]'>

                <label>{{ t('agents.reports.new.key_for_placeholder') }}</label>
                <input type="text" name="" class="i-input"
                       v-model='keyParams[index]["room"]'/>
                <InvalidFieldMessage v-bind:errorMessages="keyMessageErrors"
                                     v-bind:field="`report_key_information[${index}].room`"/>
              </div>

              <div class="col-md-3 col-6 mb-20 mb-sm-20">
                <label>{{ t('agents.reports.new.quantity') }}</label>
                <input type="text" name="" class="i-input"
                       v-model='keyParams[index]["quantity"]'/>
                <InvalidFieldMessage v-bind:errorMessages="keyMessageErrors"
                                     v-bind:field="`report_key_information[${index}].quantity`"/>
              </div>

              <div class="col-md-3 col-6 mb-20 mb-sm-20">
                <label>{{ t('agents.reports.new.key_number') }}</label>
                <input type="text" name="" class="i-input"
                       v-model='keyParams[index]["key_number"]'/>
                <InvalidFieldMessage v-bind:errorMessages="keyMessageErrors"
                                     v-bind:field="`report_key_information[${index}].key_number`"/>
              </div>
              <button class="remove-key"
                      @click="removeKey(index)">×</button>

              <div class="col-12">
                <ImageUploaderPreview
                    @upload-images="uploadKeyImages"
                    :index="index"
                    :currentImages="keyImagesDisplayed[index]" class="mt-4">
                </ImageUploaderPreview>
              </div>
            </div>
          </div>
        </div>

        <div class="row gutter-xl-15 gutter-sm-10 mb-35 mb-sm-20">
          <div class="col-md-6">
            <a class="i-btn -info -border pt-12 pb-10 bg-white -block" href="javascript:void(0)"
               title=""
               v-on:click="addNewKey">
              +&nbsp;
              {{ t('agents.reports.new.add_new_key') }}
            </a>
          </div>
        </div>

        <div class="new-report-line mb-05">
          <label>{{ t('agents.reports.new.comments_placeholder_2') }}</label>
          <textarea class="i-textarea -small height-auto"
                    v-model="keys_comment" rows="3"></textarea>
        </div>
      </div>

      <div class="new-report-line mb-20 mt-20">
        <div class="row gutter-10 justify-content-center">
          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -white -block pl-03 pr-03" type="button"
                    @click="navigateTo('FormReportStep1', currentReportId)">
              <i class="fa fa-long-arrow-alt-left mr-08 ml-0 mt-0"></i> {{ t('buttons.back') }}
            </button>
          </div>

          <div class="col-xl-2 col-md-3 col-6">
            <button class="i-btn -info -block pl-03 pr-03" type="button" @click="submit">
              {{ t('buttons.next') }} <i class="fa fa-long-arrow-alt-right ml-08 mr-0 mt-0"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import nestedBlock from '../../../../mixins/nestedBlock'
import InvalidFieldMessage from '../../../common/InvalidFieldMessage'
import currentReport from '@/mixins/currentReport'
import reportNavigationButtonMixin from '@/mixins/reportNavigationButtonMixin'
import ImageUploaderPreview from '@/views/shared/ImageUploaderPreview'

export default {
  name: 'FormReportStep2',
  mixins: [nestedBlock, currentReport, reportNavigationButtonMixin],
  components: {
    Breadcrumb,
    InvalidFieldMessage,
    ImageUploaderPreview
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: '#' }
      ],
      report_key_information_attributes: {},
      report_meter_information_params: {},
      meter_comment: '',
      keys_comment: '',
      report_info: {},
      numberOfKeyAttributes: 2,
      meterImagesDisplayed: [],
      keyDefaultAttribute:  { id: '', code: '', room: '', quantity: '', key_number: '', _destroy: 0 },
      keyParams: [],
      keyImagesDisplayed: {},
      imageMeterKeys: [],
      imageKeyKeys: {}
    }
  },
  computed: {
    ...mapGetters({
      reportErrors: 'reports/reportErrors',
      meterInformation: 'reports/meterInformation',
      updatedSuccess: 'reportMeterInformation/updatedSuccess',
      meterInformationError: 'reportMeterInformation/errors',
      keyMessageErrors: 'reportKeyInformation/errors'
    }),
    arrayKeyAttributes() {
      return [...new Array(this.numberOfKeyAttributes).keys()]
    }
  },
  methods: {
    ...mapActions({
      reportMeterCreateOrUpdate: 'reportMeterInformation/createOrUpdate',
      reportKeyCreateOrUpdate: 'reportKeyInformation/createOrUpdate',
      createReport: 'reports/createReport',
      getMeterInformation: 'reports/meterInformation',
      activeStorageRemoveImage: 'activeStorage/remove',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      turnOffLoadingPage: 'loadingPage/turnOffLoadingPage'
    }),
    buildReportKeyAttributes() {
      let result = {};
      [...new Array(this.numberOfKeyAttributes).keys()].forEach((element) => {
        result[element] = {}
      })
      return result
    },
    addNewKey() {
      this.keyParams.push(Object.assign({}, this.keyDefaultAttribute))
    },
    async submit() {
      this.turnOnLoading()
      await this.reportMeterCreateOrUpdate(this._formDataMeter())
      await this.reportKeyCreateOrUpdate({ reportId: this.currentReportId, formData: this._formDataKey() })

      if(Object.keys(this.meterInformationError).length === 0 && Object.keys(this.keyMessageErrors).length === 0) {
        let params = {
          id: this.currentReportId,
          completed_step: this.currentStep,
          meter_comment: this.meter_comment,
          keys_comment: this.keys_comment
        }
        await this.createReport(params)
      }
      this.turnOffLoadingPage()
    },
    removeKey(index){
      this.keyParams[index]['_destroy'] = 1
    },
    uploadMeterImages(params) {
      this.imageMeterKeys = params.filter(_image => _image.uploadDone).map(_image => (_image.blob_key))
    },
    uploadKeyImages(params, index){
      if(params) {
        this.imageKeyKeys[index] = params.filter(_image => _image.uploadDone).map(_image => (_image.blob_key))
      }
    },
    _defaultKeyTwo(){
      let keyVoordeur = Object.assign({}, this.keyDefaultAttribute)
      keyVoordeur['room'] = 'Voordeur'
      let keyStraatdeur = Object.assign({}, this.keyDefaultAttribute)
      keyStraatdeur['room'] = 'Straatdeur'

      return [keyVoordeur, keyStraatdeur]
    },
    _formDataKey() {
      let formData = new FormData()
      this.keyParams.forEach((keyParam, index) => {
        formData.append(`reports[report_key_information_attributes][${index}][id]`, keyParam.id)
        formData.append(`reports[report_key_information_attributes][${index}][code]`, keyParam.code || `${this.currentReportId}_${index}`)
        formData.append(`reports[report_key_information_attributes][${index}][room]`, keyParam.room || '')
        formData.append(`reports[report_key_information_attributes][${index}][quantity]`, keyParam.quantity || '')
        formData.append(`reports[report_key_information_attributes][${index}][key_number]`, keyParam.key_number || '')
        formData.append(`reports[report_key_information_attributes][${index}][_destroy]`, keyParam._destroy || 0)

        if(this.imageKeyKeys[index]) {
          this.imageKeyKeys[index].forEach((imageKey, _imageIndex) => {
            formData.append(`reports[report_key_information_attributes][${index}][image_keys][${_imageIndex}]`, imageKey)
          })
        }
      })

      return formData
    },
    _formDataMeter: function () {
      let formData = new FormData()
      formData.append('report_meter_information[report_id]', this.currentReportId)
      formData.append('report_meter_information[water]', this.report_meter_information_params.water || '')
      formData.append('report_meter_information[gas]', this.report_meter_information_params.gas || '')
      formData.append('report_meter_information[electric_day]', this.report_meter_information_params.electric_day || '')
      formData.append('report_meter_information[electric_night]', this.report_meter_information_params.electric_night || '')
      this.imageMeterKeys.forEach((imageKey, index) => {
        formData.append(`report_meter_information[image_keys][${index}]`, imageKey)
      })

      return formData
    }
  },
  async mounted() {
    this.$emit('header-title', this.t('breadcrumb.reports'))

    await this.getReportInfo(this.currentReportId)
    await this.getMeterInformation(this.currentReportId)

    let meterImages = this.meterInformation && this.meterInformation.images
    if(meterImages) {
      meterImages.forEach(image =>  {
        this.meterImagesDisplayed.push({ url: image.thumb_url, blob_key: image.blob_key, uploadDone: true })
      })
    }

    if(this.reportDetails.report_meter_information) {
      this.report_meter_information_params = this.meterInformation
    }
    this.report_key_information_attributes = this.reportDetails.report_key_information
    this.arrayKeyAttributes = this.report_key_information_attributes.length
    this.meter_comment = this.reportDetails.meter_comment
    this.keys_comment = this.reportDetails.keys_comment
    this.report_info = this.reportDetails.report_basic_information
    this.numberOfKeyAttributes = this.reportDetails.report_key_information.length
    this.keyParams = this.reportDetails.report_key_information
    if(this.reportDetails.report_key_information.length === 0) {
      this.report_key_information_attributes = this.buildReportKeyAttributes()
      this.numberOfKeyAttributes = 2
      this.keyParams = this._defaultKeyTwo()
    }

    this.keyParams.forEach((_keyInformation, index) => {
      let _keyImages = _keyInformation.images
      let self = this
      if(_keyImages) {
        self.keyImagesDisplayed[index] = []
        _keyImages.forEach(image =>  {
          self.keyImagesDisplayed[index].push({ url: image.thumb_url, blob_key: image.blob_key, uploadDone: true })
        })
      }
    })
  }
}
</script>

<style>
  .remove-key{
    right: -5px;
    position: absolute;
    z-index: 1;
    display: flex;
    color: #fff;
    background: #F64B53;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-size: 22px;
    top: -10px;
  }

  .keys-form-group {
    position: relative;
    border-top: solid #ccc 1px;
  }
</style>
