import SupportsApi from '../../../backend/supports'

const GET_AGENT_LIST = 'GET_AGENT_LIST'

const state = {
  agents: []
}

export const mutations = {
  [GET_AGENT_LIST] (state, agents) {
    state.agents = agents
  }
}

export const actions = {
  getAgentList ({ commit }) {
    return SupportsApi.agentList().then(response => {
      let agents = response.data
      commit(GET_AGENT_LIST, agents)
    }).catch(error => {
      console.log(error)
    })
  }
}

export const getters = {
  agents: state => state.agents
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
