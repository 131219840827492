<template>
  <div class="white-box mb-30">
    <h2 class="review__title">
      <i class="icon icon-comment review__title__icon"></i>
      Agent’s Comments
    </h2>

    <div class="agent-comment" v-for="review in clientReviewReceivedList" :key="review.id">
      <h3 class="agent-comment__name">{{ review.author_name }}</h3>
      <div class="agent-comment__text">
        {{ review.content }}
      </div>
    </div>
    <form @submit.prevent="review" role="form" id="review-form">
      <div class="agent-comment-form">
        <input type="text" name="" class="agent-comment-form__input" v-model='reviewParams.content'>
        <button type="submit" class="agent-comment-form__submit"><i class="icon icon-paper-plane"></i></button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReviewReceived',
  props: ['clientId'],
  data: function() {
    return {
      reviewParams: {
        content: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      clientReviewReceivedList: 'clients/reviewReceivedList'
    })
  },
  methods: {
    ...mapActions({
      getClientReviewReceivedList: 'clients/getReviewReceivedList',
      reviewClient: 'clients/reviewClient'
    }),
    review() {
      this.reviewClient({ id: this.clientId, data: this.reviewParams })
      this.reviewParams.content = ''
    },
  },
  mounted() {
    this.getClientReviewReceivedList(this.clientId)
  },
}
</script>
