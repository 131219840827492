import ApiService from './api_services'

const HouseApplicationApi = {
  get: function(id) {
    return ApiService.get(`/house_applications/${id}`)
  },

  getDocumentList: function(id) {
    return ApiService.get(`/house_applications/${id}/documents`)
  },

  refuseApplication: function(params) {
    return ApiService.post(`/house_applications/${params.id}/refuse`,  { house_application: params })
  },

  approveApplication: function(params) {
    return ApiService.post(`/house_applications/${params.id}/approve`,  { house_application: params })
  },

  accept: function(id) {
    return ApiService.post(`/house_applications/${id}/accept`)
  },

  review: function(params) {
    return ApiService.post(`/house_applications/${params.id}/review`, params)
  }
}

export default HouseApplicationApi
