import { currentUserProfile } from '@/backend/users'

const USER_PROFILE = 'USER_PROFILE'

const state = {
  currentUser: {
    entity: {}
  }
}

export const mutations = {
  [USER_PROFILE] (state, user) {
    state.currentUser = user
  }
}

export const actions = {
  getUserProfile({ commit }) {
    return currentUserProfile().then(response => {
      let currentUser = response.data
      commit(USER_PROFILE, currentUser )
    }).catch(error => {
      console.error(error)
    })
  }
}

export const getters = {
  currentUser: state => state.currentUser
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
