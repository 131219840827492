<template>
  <div class="main-container">
    <form role="form" class="new-reports-box -bg-white mt-60 mt-lg-40 mt-md-30">
      <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('users.edit.title') }}</h2>
      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <input type="text" name="user_first_name" class="i-input"
                 v-model='user.first_name'
                 v-bind:placeholder="t('users.edit.first_name')">
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="first_name"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <input type="text" name="user_last_name" class="i-input"
                 v-model='user.last_name'
                 v-bind:placeholder="t('users.edit.last_name')">
          <InvalidFieldMessage v-bind:errorMessages="errorValidation"
                               field="last_name"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <input type="file" id="file" v-on:change="handleAvatarUpload($event)"/>
        </div>
      </div>

      <div class="row gutter-xl-15 gutter-sm-10 mt-3">
        <div class="col-12">
          <button class="i-btn" type="button" @click="submit">
            {{ t('buttons.save') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import UserApi from '@/backend/users'
import router from '@/router'
import InvalidFieldMessage from '@/views/common/InvalidFieldMessage'

export default {
  name: 'edit',
  data: function () {
    return {
      user: {},
      errorValidation: {}
    }
  },
  components: { InvalidFieldMessage },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateProfiles: 'users/updateProfiles',
      getInfoUser: 'users/getUserProfile'
    }),
    submit(){
      let formData = new FormData()
      if( this.user.avatar ){
        formData.append('user[avatar]', this.user.avatar)
      }
      formData.append('user[first_name]', this.user.first_name)
      formData.append('user[last_name]', this.user.last_name)
      let self = this
      UserApi.updateProfile(formData).then(() => {
        self.getInfoUser()
        router.push({ name: 'Reports' })
      }).catch(error => {
        this.errorValidation = error.response.data.messages
      })
    },
    handleAvatarUpload(event){
      this.user.avatar = event.target.files[0]
    }
  },
  async mounted() {
    await this.getInfoUser()
    this.user = this.userProfile
  }
}
</script>
