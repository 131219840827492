import DocumentApi from '../../../backend/document'
import router from '../../../router'

const DOCUMENT = 'DOCUMENT'
const COMMENT = 'COMMENT'
const DOCUMENT_ERROR = 'DOCUMENT_ERROR'

const state = {
  document: {},
  eventError: null
}

export const mutations = {
  [DOCUMENT] (state, document) {
    state.document = document
  },
  [COMMENT] (state, document) {
    state.document = document
  },
  [DOCUMENT_ERROR] (state, eventError) {
    state.eventError = eventError
  }
}

const actions = {
  getDocument({ commit }, id) {
    DocumentApi.get(id).then(response => {
      commit(DOCUMENT, response.data)
    }).catch(error => {
      console.error(error)
    })
  },
  review({ commit, dispatch }, params) {
    let applicationId = router.currentRoute._value.params.id
    DocumentApi.review(params).then(response => {
      commit(DOCUMENT, response.data)
      dispatch(
        'house_application/toggleModal',
        { modalName: 'documentView', state: 'off' },
        { root:true })
      dispatch('house_application/getDocumentList', applicationId, { root: true })
    }).catch(error => {
      commit(DOCUMENT_ERROR, error.response.data.messages)
    })
  },
  hideEventError({ commit }) {
    commit(DOCUMENT_ERROR, null)
  }
}

export const getters = {
  document: state => state.document,
  eventError: state => state.eventError
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
