import ReportConfigurationApi from '../../../backend/report_configurations'
import { createToast } from 'mosha-vue-toastify'
import { ToastSettings } from '@/settings/toast'
import i18n from '@/i18n'
const { t } = i18n.global

const SET_LIST = 'SET_LIST'

const state = {
  list: []
}

export const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  }
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  updateList({ commit }, params = {}) {
    return ReportConfigurationApi.create(params).then(() => {
      createToast(t('reports.messages.saveEmailConfig'), ToastSettings.success)
    }).catch(() => {})
  },
  getList({ commit }) {
    return ReportConfigurationApi.list().then((response) => {
      commit(SET_LIST, response.data)
    })
  }
}

export const getters = {
  list: state => state.list
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
