<template>
  <div id="houses">
    <div class="main-container">
      <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      <HouseForm v-if="dataReady" v-bind:house="this.houseInfo" action="update"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from '../../shared/Breadcrumb'
import HouseForm from './_HouseForm'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Edit',
  components: {
    Breadcrumb,
    HouseForm
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.houses'), routerName: 'Agents Houses', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.new'), routerName: '#' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      houseInfo: 'houses/houseInfo'
    }),
    houseID() {
      return this.$route.params.id
    },
    dataReady() {
      return !!(this.houseInfo && this.houseInfo.id)
    }
  },
  methods: {
    ...mapActions({
      getHouseInfo: 'houses/getHouseInfo'
    })
  },
  async mounted() {
    await this.getHouseInfo(this.houseID)
  }
}
</script>
