import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import { ReportStepList } from '@/settings/reportStepList'

const currentReport = {
  methods: {
    ...mapActions({
      getReportInfo: 'reports/getReportInfo',
      getReportComments: 'reportComment/getReportComments',
    })
  },
  computed: {
    ...mapGetters({
      reportDetails: 'reports/reportDetails',
      reportComments: 'reportComment/reportComments',
    }),
    currentReportId() {
      return this.$route.params.id
    },
    currentStep() {
      return this.$route.path.split('/').at(-1)
    }
  },
  async mounted() {
    await this.getReportInfo(this.currentReportId)
    await this.getReportComments(this.currentReportId)
    let stepsList = ReportStepList[this.reportDetails.type]
    let completedStep = this.reportDetails.completed_step
    let visitStep = this.$route.path.split('/').at(-1)
    let indexCompletedStep = stepsList.indexOf(completedStep)
    let indexOfVisitStep = stepsList.indexOf(visitStep)
    if (completedStep === 'step-signature') {
      await router.push({ path: `/agents/reports/${this.reportDetails.id}` })
      return false
    }

    if( completedStep === 'step-comments' && (visitStep === 'step-overviews' || visitStep === 'step-signature') ){
      return false
    }
    if(indexOfVisitStep > indexCompletedStep + 1) {
      await router.push({ path: `/agents/reports/${this.reportDetails.id}/${completedStep}` })
    }
  }
}

export default currentReport
