const ACCESS_TOKEN = 'access_token'
const RESOURCE_TYPE = 'resource_type'

const AuthService = {
  setToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  },

  setResourceType(type) {
    localStorage.setItem(RESOURCE_TYPE, type)
  },

  getToken() {
    return localStorage.getItem(ACCESS_TOKEN)
  },

  getResourceType() {
    return localStorage.getItem(RESOURCE_TYPE)
  },

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN)
  },

  removeResourceType() {
    localStorage.removeItem(RESOURCE_TYPE)
  }
}

export default AuthService
