import { subDomain } from '@/settings/subdomain'
import ApiService from './api_services'

export function authenticate (email, password) {
  return ApiService.post('/auth/login', {
    email: email,
    password: password,
    grant_type: 'password',
    subdomain: subDomain()
  })
}
