import OverheidApi from '../../../backend/overheid'

const FETCH_ADDRESS = 'FETCH_ADDRESS'
const FETCHING_STATE = 'FETCHING_STATE'

const state = {
  houseAddress: {},
  isFetchingAddress: false
}

export const mutations = {
  [FETCH_ADDRESS] (state, address) {
    state.houseAddress = address
  },
  [FETCHING_STATE] (state, isFetchingAddress) {
    state.isFetchingAddress = isFetchingAddress
  }
}

export const actions = {
  detectHouseAddress( { commit }, searchParams) {
    commit(FETCHING_STATE, true)
    return OverheidApi.bag(searchParams).then((response) => {
      if(response.status === 200 ){
        commit(FETCH_ADDRESS, response.data)
        commit(FETCHING_STATE, false)
      }
    }).catch( error => {
      console.error(error)
      commit(FETCHING_STATE, false)
    })
  }
}

export const getters = {
  houseAddress: state => state.houseAddress,
  isFetchingAddress: state => state.isFetchingAddress
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
