<template>
  <div class="white-box mb-40 mb-md-30">
    <h2 class="review__title">
      <i class="icon icon-page review__title__icon"></i>
      <span v-if="generalInfo">{{generalInfo.clientName}}</span>
      <i class="icon icon-comment -blue review__title__s-icon"></i>
    </h2>
    <div class="review__cmt-box">
      <div v-if="generalInfo">
        {{ generalInfo.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientInformation',
  props: ['generalInfo']
}
</script>
