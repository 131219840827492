import ClientAPi from '../../../backend/client'

const REVIEW_RECEIVED_LIST = 'REVIEW_RECEIVED_LIST'
const REVIEW_CLIENT = 'REVIEW_CLIENT'

const state = {
  reviewReceivedList: []
}

export const actions = {
  getReviewReceivedList({ commit }, id) {
    ClientAPi.getReviewReceivedList(id).then(response => {
      commit(REVIEW_RECEIVED_LIST, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

  reviewClient({ commit }, params) {
    ClientAPi.reviewClient(params.id, params.data).then(response => {
      commit(REVIEW_CLIENT, response.data)
    }).catch(error => {
      console.error(error)
    })
  },

}

export const mutations = {
  [REVIEW_RECEIVED_LIST] (state, reviews) {
    state.reviewReceivedList = reviews
  },
  [REVIEW_CLIENT] (state, review) {
    state.reviewReceivedList.push(review)
  }
}

export const getters = {
  reviewReceivedList: state => state.reviewReceivedList
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
