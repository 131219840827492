import EntityApi from '../../../backend/entity'

const PROFILES = 'PROFILES'

const state = {
  profiles: {}
}

export const mutations = {
  [PROFILES] (state, profiles) {
    state.profiles = profiles
  }
}

export const actions = {
  async getProfiles({ commit }) {
    await EntityApi.profile().then(response => {
      commit(PROFILES, response.data)
    }).catch(error => {
      console.error(error)
    })
  }
}

export const getters = {
  profiles: state => state.profiles
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
