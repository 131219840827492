import ApiService from './api_services'

const ActiveStorageApi = {
  createAndUpload: function (formData){
    return ApiService.post('/active_storage_blob/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadSingleFile: function (formData) {
    return ApiService.post('/active_storage_blob/upload_single_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  delete: function (data) {
    return ApiService.delete('/active_storage_blob/delete', data)
  }
}

export default ActiveStorageApi
