import ReportKeyInformationApi from '../../../backend/report_key_information'

const ERROR = 'ERROR'
const DATA = 'DATA'

const state = {
  errors: [],
  data: {}
}

export const mutations = {
  [ERROR](state, errors) {
    state.errors = errors
  },

  [DATA] (state, data) {
    state.data = data
  }
}

export const actions = {
  async createOrUpdate({ commit }, { reportId: reportId, formData: formData }) {
    await ReportKeyInformationApi.createOrUpdate(reportId, formData).then(() => {
      commit(ERROR, [])
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  },

  async getInformation( { commit }, reportId ) {
    await ReportKeyInformationApi.getInformation(reportId).then((response) => {
      commit(DATA, response.data)
    }).catch((error) => {
      console.log(error)
    })
  }
}

export const getters = {
  errors: state => state.errors,
  data: state => state.data
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
