<template>
  <div id="reject-modal"
       :class="enableModal ? 'modal fade review-modal show' : 'modal fade review-modal'"
       role="dialog"
       :style="enableModal ? 'display: block;' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <button type="button" class="i-text -danger review-modal__close" v-on:click="hideModal">&times;</button>
          <h3 class="page-title -danger">Client review</h3>
          <div class="alert alert-danger" v-if="applicationError.length > 0">
            <ul class="messages">
              <li v-for="(message, index) in applicationError" :key="index"> {{message}}</li>
            </ul>
          </div>
          <ModalHouseInfo :houseInfo="houseInfo" :clientInfo="clientInfo"/>
          <div class="review-modal__br mb-30"></div>
          <form @submit.prevent="refuseApplied" role="form" id="refuse-form">
            <textarea class="review-modal__content" name="name" v-model='applyParams.comment'></textarea>
            <div class="text-center mt-35">
              <button type="submit" class="i-btn -danger">{{ t('buttons.send_details') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalHouseInfo from './ModalHouseInfo'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalReject',
  props: ['application', 'enableModal'],
  components: {
    ModalHouseInfo
  },
  computed: {
    ...mapGetters({
      applicationError: 'house_application/applicationError'
    }),
    clientInfo() {
      return this.application.client_info
    },
    houseInfo() {
      return this.application.house_info
    }
  },
  data: function() {
    return {
      applyParams: {
        id: this.$route.params.id,
        comment: ''
      },
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    ...mapActions({
      refuseApplication: 'house_application/refuseApplication'
    }),
    refuseApplied() {
      this.$store.dispatch('house_application/refuseApplication', this.applyParams)
    },
    hideModal() {
      this.$store.dispatch('house_application/toggleModal', { modalName: 'refuseApplication', state: 'off' })
    }
  },
}
</script>
