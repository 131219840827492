import { contractList } from '../../../backend/contract'

const SET_CONTRACT_LIST = 'SET_CONTRACT_LIST'

const state = {
  contracts: []
}

export const mutations = {
  [SET_CONTRACT_LIST] (state, contracts) {
    state.contracts = contracts
  }
}

export const actions = {
  setContractList ({ commit }) {
    return contractList().then(response => {
      let contracts = response.data
      commit(SET_CONTRACT_LIST, contracts)
    }).catch(error => {
      console.log(error)
    })
  }
}

export const getters = {
  contractList: state => state.contracts
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
