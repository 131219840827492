<template>
  <div id="accept-modal"
       :class="enableModal ? 'modal fade review-modal show' : 'modal fade review-modal'"
       role="dialog"
       :style="enableModal ? 'display: block;' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-scroll">
          <button type="button" class="i-text -danger review-modal__close" v-on:click="hideModal">&times;</button>

          <form role="form" id="agent-form">
            <div class="row justify-content-between align-items-center mb-35 mb-md-25">
              <div class="col-sm-5 mt-sm-10">
                <h1 class="page-title -primary">
                  {{ t('agents.agents.new.title') }}
                </h1>
              </div>
            </div>

            <div class="new-agent">
              <div class="row">
                <div class="col-xl-12">
                  <div class="new-agent--col-left">
                    <div class="white-box new-agent__basic-info">
                      <form class="">
                        <div class="mb-35 mb-sm-20">
                          <input v-bind:class="classNameField('email')" type="text" name="email"
                                v-model='agentParams.email' v-bind:placeholder="t('agents.agents.agentForm.email')">
                          <InvalidFieldMessage v-bind:errorMessages="agentError" field="email"/>
                        </div>
                        <div class="mb-35 mb-sm-20">
                          <select class="i-select" v-bind:class="classNameField('type')" name="type"
                                  v-model='agentParams.type'>
                            <option value="">Choose role</option>
                            <option value="Staff">Staff</option>
                            <option value="Manager">Manager</option>
                          </select>
                          <InvalidFieldMessage v-bind:errorMessages="agentError" field="type"/>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-35 mb-sm-20">
                              <input v-bind:class="classNameField('first_name')" type="text" name="first_name"
                                    v-model='agentParams.first_name'
                                    v-bind:placeholder="t('agents.agents.agentForm.first_name')">
                              <InvalidFieldMessage v-bind:errorMessages="agentError" field="first_name"/>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-35 mb-sm-20">
                              <div class="i-input-wrap">
                                <input v-bind:class="classNameField('last_name')" type="text" name="last_name"
                                      v-model='agentParams.last_name' class="pr-2"
                                      v-bind:placeholder="t('agents.agents.agentForm.last_name')">
                              </div>
                              <InvalidFieldMessage v-bind:errorMessages="agentError" field="last_name"/>
                            </div>
                          </div>
                        </div>

                        <div class="row gutter-15 gutter-sm-10">
                          <div class="col-sm-auto">
                            <button type="button" class="i-btn -gray w-100 w-sm-auto mb-3" v-on:click="hideModal">
                              {{ t('buttons.cancel') }}
                            </button>
                          </div>
                          <div class="col-sm-auto">
                            <button type='submit' class='i-btn w-100 w-sm-auto' @click.prevent="submitAgent()">
                              {{ t('buttons.save') }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import InvalidFieldMessage from '../../common/InvalidFieldMessage'

export default {
  name: 'ModalNewAgent',
  components: {
    InvalidFieldMessage
  },
  props: ['agent', 'action', 'enableModal'],
  data: function() {
    return {
      agentParams: {}
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  methods: {
    ...mapActions({
      setList: 'agents/setList'
    }),
    hideModal() {
      this.$store.dispatch('agents/toggleModal', { modalName: 'newAgent', state: 'off' })
    },
    async submitAgent() {
      await this.$store.dispatch('agents/createAgent', this.agentParams)
      await this.setList()
    },
    isInvalid(field){
      let invalidFields = Object.keys(this.agentError)
      return invalidFields.includes(field)
    },
    classNameField(field){
      return this.isInvalid(field) ? 'i-input -danger' : 'i-input'
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
      agentError: 'agents/agentError'
    }),
  },
  mounted() {
    this.agentParams = this.agent
  }
}
</script>
