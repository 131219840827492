{
  "userChangePassword": {
    "password": "Password",
    "passwordConfirmation": "Wachtwoord bevestigen",
    "noticeChangePassword1": "U gaat het wachtwoord wijzigen voor gebruiker <span class='email-highlight'>{ email }</span>.",
    "noticeChangePassword2": "Voer uw wachtwoord en bevestig deze om te voltooien.",
    "errorMessages": {
      "invalidPasswordConfirm": "Wachtwoord bevestiging komt niet overeen met wachtwoord",
      "password": {
        "tooShort": "Wachtwoord moet meer dan 6 tekens bevatten"
      },
      "passwordConfirm": {
        "notMatch": "Wachtwoord bevestiging komt niet overeen met wachtwoord"
      }
    },
    "messages": {
      "resetPasswordSuccess": "Wachtwoord is succesvol gewijzigd."
    }
  }
}
