import ApiService from './api_services'

const ReportKeyInformationApi = {
  createOrUpdate: function(reportId, formData) {
    return ApiService.post(`/reports/${reportId}/report_key_information`, formData)
  },
  getInformation: function(id) {
    return ApiService.get(`/reports/${id}/report_key_information`)
  },
}

export default ReportKeyInformationApi
