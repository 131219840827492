{
  "agents": {
    "icons": {
      "eraser": "Opnieuw",
      "edit": "Bewerking"
    },
    "houses": {
      "title": "Woningen"
    },
    "searchForm": {
      "placeholder": "Zoeken..."
    },
    "houseForm": {
      "title": "Huis toevoegen",
      "add_image": "Afbeelding toevoegen",
      "add_video": "Video toevoegen",
      "house_infomation": "Woning informatie",
      "basic_infomation": "Basis informatie",
      "house_name": "Naam",
      "house_number": "Huisnummer",
      "house_letter": "Huisletter",
      "house_number_addition": "Huisnummertoevoeging",
      "street": "Straat",
      "house_address": "Adres",
      "house_city": "Plaats",
      "house_state": "Provincie",
      "post_code": "Postcode",
      "description": "Beschrijving",
      "start_hiring": "Aanvang huurcontract",
      "placeholder_day": "Dag",
      "placeholder_month": "Maand",
      "placeholder_year": "Jaar",
      "deposit": "Waarborgsom",
      "rent_price": "Huur prijs",
      "contact_length": "Contractduur",
      "people_allow": "Toegestane hoeveelheid mensen",
      "invalid_date": "Ongeldige datum"
    },
    "detail": {
      "title": "Huis",
      "price": "Prijs",
      "start": "Start",
      "length": "Contractduur",
      "people": "Mensen",
      "applied_customers": "Aanmeldingen",
      "reject": "Afwijzen",
      "export": "Exporteren",
      "name": "Naam",
      "time_waiting": "Wachtende",
      "documents_approved": "Documenten<br>Goedgekeurd",
      "status": "Status",
      "action": "Actie",
      "pending": "In afwachting",
      "declined": "Afgewezen",
      "reviewing": "In behandeling",
      "approved": "Goedgekeurd",
      "review": "Bekijken"
    },
    "review": {
      "title": "Aanmeldingen",
      "document": "Documenten",
      "document_desc": "Benodigde documenten"
    },
    "uploadFiles": {
      "drop_files_label": "Sleep afbeeldingen om te uploaden...",
      "choose_file": "Bestand kiezen",
      "drag_and_drop_label": "Sleep afbeeldingen of videos om te uploaden"
    },
    "modalHouseInfo": {
      "house_name": "Naam",
      "price": "Prijs",
      "email": "Email",
      "start": "Start"
    },
    "modalReview": {
      "need_to_resolved": "Moet worden opgelost",
      "others": "Anders",
      "write_the_problem_here": "Schrijf het probleem hier...",
      "write_your_comment_here": "Schrijf notitie hier...",
      "need_corrections": "Aanpassingen nodig van dit document"
    },
    "modalAccept": {
      "documents": "Documenten",
      "write_your_comment_here": "Schrijf notitie hier..."
    },
    "houseApplications": {
      "documentLists": {
        "upload": "ingeleverd",
        "checked": "nagekeken",
        "messageNotPassRequired": "Voldoet niet aan de minimum eisen"
      },
      "modalReview": {
        "documents": "Documenten",
        "need_to_check": "Moet worden nagekeken",
        "invalid_info": "Ongeldige informatie",
        "other": "Anders"
      },
      "countDocumentApproved": {
        "documentApproved": "Documenten goedgekeurd"
      }
    },
    "notifications": {
      "title": "Notificaties",
      "select_agent": "Kies werknemer",
      "select_house": "Kies woning",
      "pick_date": "Kies datum",
      "client": "Klant",
      "estate_agent": "Makelaar",
      "load_more": "Meer laden"
    },
    "reports": {
      "last_month": "Afgelopen maand",
      "add_new_report": "Rapport toevoegen",
      "reports": "Rapporten",
      "show_all_reports": "Alle rapporten bekijken",
      "delivery_report": "Procesverbaal van oplevering",
      "for_inspection": "Voorinspectie",
      "final_inspection": "Eindinspectie",
      "load_more": "Meer laden",
      "address": "Adres",
      "type": "Rapport",
      "date": "Datum",
      "config": "Config",
      "types": {
        "delivery": "procesverbaal van oplevering",
        "pre_inspection": "voorinspectie",
        "final_inspection": "eindinspectie"
      },
      "new": {
        "add_another_hirer_signature": "Handtekening huurder toevoegen",
        "add_another_owner_agent_signature": "Handtekening eigenaar/makelaar toevoegen",
        "add_new_comment": "Notitie toevoegen",
        "add_new_key": "Sleutel toevoegen",
        "address": "Adres",
        "address_hourse": "Huis adres",
        "agreement": "Overeenkomst",
        "basic_info": "Basis informatie",
        "city": "Plaats",
        "comment": "Notitie",
        "comments": "Notities",
        "comments_placeholder": "Schrijf notitie...",
        "comments_placeholder_2": "Schrijf notitie over de sleutels",
        "company_address": "Bedrijfs adres",
        "company_info": "Bedrijfsinformatie ",
        "company_name": "Bedrijfsnaam",
        "date": "Datum",
        "day": "Elektriciteit dag",
        "night": "Elektriciteit nacht",
        "done": "Klaar",
        "done_desc": "U hebt het rapport aangemaakt",
        "download_as_pdf": "Downloaden",
        "electric_meter": "Elektriciteitsmeter",
        "end_date_agreement_hiring": "Einddatum huurovereenkomst",
        "rental_agreement_start_at": "Ingangsdatum huurovereenkomst",
        "scheduled_final_inspection_at": "Geplande eindinspectie",
        "electricity": "Elektriciteit",
        "email_address": "Email adres",
        "enter_how_many_keys": "Aantal sleutels",
        "enter_key_number": "Voer sleutel nummer in",
        "enter_or_select_date": "Vul in of kies een datum",
        "enter_or_select_the_end_date_of_contact": "Vul in of kies een eind datum",
        "enter_phone_number": "Telefoonnummer",
        "enter_the_comment_name": "Notitie naam",
        "enter_your_comment_here": "Schrijf notitie...",
        "enter_your_name": "Naam",
        "feedback": "Opmerking",
        "first_name": "Voornaam",
        "gas": "Gas",
        "gas_placeholder": "Gas meterstand",
        "gas_unit": "kubieke meters",
        "general": "Algemeen",
        "remaining": "Overige afspraken",
        "hirer_info": "Informatie huurder",
        "hirer_name": "Naam huurder",
        "home": "Home",
        "hirer_signature": "Handtekening huurder",
        "owner_signature": "Handtekening eigenaar",
        "image": "Afbeelding",
        "key_for": "Sleutel voor",
        "key_for_placeholder": "Vul in waarvoor de sleutel is",
        "key_number": "Sleutel nummer",
        "keys_info": "Sleutel informatie",
        "keys_info_desc": "De volgende sleutels zijn aanwezig.",
        "kitchen_wall_damaged": "Keuken muur beschadigd",
        "last_name": "Achternaam",
        "meter_info": "Meterstanden",
        "name": "Naam",
        "new_address": "Nieuw adres",
        "new_phone_number": "Telefoonnummer",
        "or_link_your_image": "Of link een afbeelding",
        "overview": "Overzicht",
        "overview_desc": "Controleer alle informatie voor tekenen",
        "owner_agent_name": "Eigenaar/Makelaar naam",
        "owner_agent_signature": "Eigenaar/Makelaar handtekening",
        "owner_name": "Naam eigenaar",
        "behalf_landlord": "Namens verhuurder",
        "phone_number": "Telefoonnummer invullen",
        "quantity": "Aantal",
        "registration_number": "Registratie nummer",
        "remaining_appointments": "Overige afspraken",
        "waarborgsom": "Waarborgsom",
        "select_comment_type": "Selecteer notitie type",
        "share": "Delen",
        "starting_date": "Ingangsdatumt",
        "starting_date_of_lease_agreement": "Ingangsdatum huurovereenkomst",
        "streed_address": "Adres",
        "subject": "Subject",
        "term_of_use": "Algemene voorwaarden",
        "term_of_use_text": "Bij het tekenen gaat u akkoord met de ",
        "upload_description": "Noteer opmerkingen en upload afbeeldingen als u denkt dat er iets is veranderd of beschadigd is. Ook kunt u overeenkomsten toevoegen. <br>Anders kunt u dit gedeelte overslaan.",
        "upload_image": "Afbeelding uploaden",
        "upload_signature": "Handtekening uploaden",
        "wall_color_damaged": "Muur kleur beschadigd",
        "water": "Water",
        "water_placeholder": "Vul de watermeterstand in",
        "water_unit": "gallons",
        "zip_code": "Postcode",
        "date_of_statement": "Datum opgave",
        "contact_person": "Contact persoon",
        "phone_contact_person": "Telefoonnummer contact persoon",
        "contact_person_name": "Naam huurder",
        "upload_image_or_link_image_here": "Upload image or link image here.",
        "hirer_full_name": "Volledige naam huurder",
        "bank_account_number": "Bankrekeningnummer huurder",
        "owner_full_name": "Volledige naam eigenaar",
        "qty": "Aantal",
        "rental_agreement_end_at": "Datum overeenkomst",
        "term_agreement1": "Aldus opgemaakt in tweevoud en voor akkoord online ondertekend",
        "email": "Email adres",
        "houseImages": "Huis afbeeldingen",
        "step2": {
          "label": {
            "water": "Watermeterstand",
            "gas": "Gasmeterstand"
          }
        }
      },
      "show": {
        "gallons": "Liter",
        "cubic_meters": "vierkante meter",
        "commet_type": "Type",
        "comment_subject": "Onderwerp",
        "image": "Afbeeldingen",
        "comment_content": "Content",
        "day": "Dag",
        "night": "Nacht"
      }
    },
    "report_config": {
      "title": "Email configuratie",
      "type": "type",
      "email": "email"
    },
    "table": {
      "no_data": "Geen data om weer te geven"
    },
    "agents": {
      "index": {
        "title": "Makelaars",
        "table": {
          "full_name": "Naam",
          "role": "Rol",
          "email": "Email",
          "active": "Actief"
        },
        "messages": {
          "delete_confirm": "Weet u zeker dat u dit makelaars wilt verwijderen?"
        }
      },
      "new": {
        "title": "Nieuwe makelaar"
      },
      "agentForm": {
        "email": "Email",
        "first_name": "Voornaam",
        "last_name": "Achternaam"
      }
    },
    "entities": {
      "editPage": {
        "title": "Bedrijfsinformatie",
        "name": "Naam",
        "address": "Adres",
        "phone_number": "Telefoonnummer",
        "email": "Email",
        "kvk_number": "KvK number",
        "nvm": "NVM number",
        "logo": "Logo"
      }
    },
    "terms": {
      "title": "Algemene voorwaarden",
      "type": "Type",
      "actions": "Acties",
      "modify": "Aanpassen",
      "select_type": "Select rapport type",
      "new": "Nieuwe algemene voorwaarden",
      "types": {
        "report_delivery": "Procesverbaal van oplevering",
        "report_pre_inspection": "Voorinspectie",
        "report_final_inspection": "Eindinspectie"
      }
    },
    "dashboard": "Dashboard",
    "stepSignature": {
      "hirer": "Huurder",
      "ownerAgent": "Eigenaar/Makelaar"
    }
  }
}
