import ApiService from './api_services'

const AgentApi = {
  getList: function(params) {
    return ApiService.get('/agents', params)
  },

  create: function(agentAttributes) {
    return ApiService.post('/agents', { agent: agentAttributes })
  },

  changeStatus: function(id) {
    return ApiService.get(`agents/${id}/change_status`)
  },

  delete: function (id) {
    return ApiService.delete(`agents/${id}/delete`)
  }
}

export default AgentApi
