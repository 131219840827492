import axios from 'axios'
import AuthService from '../../services/auth_service'
import router from '@/router'
import store from '@/store'

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.interceptors.request.use(
      (config) => {
        return config
      }
    )
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        config.headers['Authorization'] = AuthService.getToken()
        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          store.dispatch('auth/signOut')
          if (router.currentRoute.name !== 'SignUpForm') {
            router.replace({ name: 'Users Sign In' }).then(() => {})
          }
        } else if (error.response.status === 403) {
          router.replace({ name: 'Home' })
          // eslint-disable-next-line no-empty
        } else if (error.response.status === 404 && router.currentRoute.name !== 'SignUpForm') {
          store.dispatch('globalHandleError/setError', 404)
        }

        // request is rejected and will direct logic to the catch() method
        return Promise.reject(error)
      }
    )
  },

  setHeader() {
    axios.defaults.headers.common['Authorization'] = AuthService.getToken()
  },

  removeHeader() {
    axios.defaults.headers.common['Authorization'] = ''
  },

  get(resource, params={}) {
    return axios.get(resource, { params })
  },

  post(resource, data, options={}) {
    return axios.post(resource, data, options)
  },

  put(resource, data) {
    return axios.put(resource, data)
  },

  delete(resource, data = {}) {
    return axios.delete(resource, { data: data })
  },
}

export default ApiService
