import ApiService from './api_services'

const ReportCommentApi = {
  createOrUpdate: function(reportId, formData) {
    return ApiService.post(`/reports/${reportId}/report_comments`, formData)
  },

  getReportComments: function(reportId) {
    return ApiService.get(`/reports/${reportId}/report_comments`)
  },
}

export default ReportCommentApi
