<template>
  <div id="reports">
    <div class="main-container">
      <div class="font-18">
        <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"></Breadcrumb>
      </div>

      <div class="mt-25">
        <img :src="entityProfile.logo_thumb_url" width="96">
      </div>
      <h2 class="new-reports-box__title mt-04">{{ reportDetails.house_address }} - {{ reportDetails.report_code }}</h2>
      <div class="i-text -gray-4 font-bold mt-05">
        {{ reportDetails.house_address }}
        <br>
        {{ reportDetails.city }}
      </div>

      <div class="report-type-title mt-60 mt-lg-40 mt-md-30 mb-4">{{ t(`reports.types.${reportDetails.type}`) }}</div>
      <div class="new-reports-box -bg-white mb-35 mb-md-30">
        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.basic_info') }}</h2>
        <div class="mb-20 mb-sm-15"></div>
        <div class="row gutter-md-10">
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.owner_full_name') }}</label>
            <div class="font-15 font-md-14"> {{ reportDetails.report_basic_information.owner_full_name }}</div>
          </div>

          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.address') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.report_basic_information.house_address }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.city') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.report_basic_information.city }}</div>
          </div>
          <div class="col-lg-4 col-6 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.zip_code') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.report_basic_information.zipcode }}</div>
          </div>
          <div class="col-lg-4 col-6 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.date_of_statement') }}</label>
            <div class="font-15 font-md-14">{{ displayDateTimeByFormat(reportDetails.report_basic_information.date_of_statement) }}</div>
          </div>
        </div>
        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">

        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.company_info') }}</h2>
        <div class="row gutter-md-10">
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{
                t('agents.reports.new.company_name')
              }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.name }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_phone_number') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.phone_number }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.email_address') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.email_company }}</div>
          </div>
          <div class="col-lg-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.company_address') }}</label>
            <div class="font-15 font-md-14">{{ entityProfile.address }}</div>
          </div>
        </div>
        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">

        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.hirer_info') }}</h2>
        <div class="row gutter-md-10" v-for="client in reportDetails.report_client_informations" :key="client.id">
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.hirer_full_name') }}</label>
            <div class="font-15 font-md-14">{{ client.full_name }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_phone_number') }}</label>
            <div class="font-15 font-md-14">{{ client.phone_number }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.email') }}</label>
            <div class="font-15 font-md-14">{{ client.email }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="!isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.new_address') }}</label>
            <div class="font-15 font-md-14">{{ client.address }}</div>
          </div>
          <div class="col-lg-4 col-6 col-6 mb-20 mb-sm-15" v-if="!isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.bank_account_number') }}</label>
            <div class="font-15 font-md-14">{{ client.bank_account_number }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isDeliveryReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.rental_agreement_start_at') }}</label>
            <div class="font-15 font-md-14">{{ displayDateTimeByFormat(client.rental_agreement_start_at) }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-else>
            <label class="font-bold mb-05 font-16 font-md-15">{{
                t('agents.reports.new.end_date_agreement_hiring')
              }}</label>
            <div class="font-15 font-md-14">{{ displayDateTimeByFormat(client.rental_agreement_end_at) }}</div>
          </div>
          <div class="col-lg-4 col-6 mb-20 mb-sm-15" v-if="isPreInspectionReport">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.scheduled_final_inspection_at') }}</label>
            <div class="font-15 font-md-14">{{ displayDateTimeByFormat(client.scheduled_final_inspection_at, defaultDateTime) }}</div>
          </div>
        </div>

        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0" v-if="reportDetails.report_meter_information">

        <h2 class="new-reports-box__title mb-20" v-if="meterInformation">
          {{ t('agents.reports.new.meter_info') }}</h2>
        <div class="row gutter-md-10" v-if="meterInformation">
          <div class="col-lg-3 col-sm-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.water') }}</label>
            <div class="font-15 font-md-14">{{ meterInformation.water }}</div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.gas') }}</label>
            <div class="font-15 font-md-14">{{ meterInformation.gas }}</div>
          </div>
          <div class="col-lg-6 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.electricity') }}</label>
            <div class="row gutter-md-10">
              <div class="col-6 mb-20 mb-sm-15 i-text font-15 font-md-14">
                <p><b>{{ t('agents.reports.show.day') }} -</b> {{ meterInformation.electric_day }} kWh</p>
              </div>
              <div class="col-6 mb-20 mb-sm-15 i-text font-15 font-md-14">
                <p><b>{{ t('agents.reports.show.night') }} -</b> {{ meterInformation.electric_night }} kWh</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-20 mb-sm-15" v-if="meterInformation && meterInformation.images">
          <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
          <div class="row gutter-15">
            <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in meterInformation.images"
                 v-bind:key="index">
              <div class="new-report-img">
                <a :href="image.url" target="_blank" rel="noopener noreferrer">
                  <img v-bind:src="image.thumb_url" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutter-md-10" v-if="reportDetails.meter_comment">
          <div class="col-12 mb-20 mb-sm-15">
            <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.comments') }}</label>
            <div class="font-15 font-md-14">{{ reportDetails.meter_comment }}</div>
          </div>
        </div>

        <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0" v-if="displayKeysBlock()">

        <h2 class="new-reports-box__title mb-20" v-if="displayKeysBlock()">
          {{ t('agents.reports.new.keys_info') }}</h2>

        <div v-if="displayKeysBlock()">
          <div v-for="(key_for, index) in keyData" v-bind:key="index">
            <div class="row gutter-md-5">
              <div class="col-lg-3 col-4">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.key_for') }}</label>
                <div class="font-15 font-md-14">{{ key_for.room }}</div>
              </div>
              <div class="col-lg-3 col-5">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.key_number') }}</label>
                <div class="font-15 font-md-14">{{ key_for.key_number }}</div>
              </div>
              <div class="col-lg-3 col-3">
                <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.qty') }}</label>
                <div class="font-15 font-md-14">{{ key_for.quantity }}</div>
              </div>
            </div>
            <div class="mb-20 mb-sm-15 mt-3" v-if="key_for.images.length > 0">
              <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
              <div class="row gutter-15">
                <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in key_for.images" v-bind:key="index">
                  <div class="new-report-img">
                    <a :href="image.url" target="_blank" rel="noopener noreferrer">
                      <img v-bind:src="image.thumb_url" alt="">
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-report-line -view-mode mb-15">{{ key_for.note }}</div>
          </div>
          <div class="row gutter-md-10" v-if="reportDetails.keys_comment">
            <div class="col-12 mb-20 mb-sm-15">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t('agents.reports.new.comments') }}</label>
              <div class="font-15 font-md-14">{{ reportDetails.keys_comment }}</div>
            </div>
          </div>
        </div>

        <div v-if="displayComment()">
          <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">
          <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.comments') }}</h2>
          <div v-for="(comment, index) in reportDetails.report_comments" v-bind:key="index">
            <div class="mb-20 mb-sm-15">
              <label class="font-bold mb-05 font-16 font-md-15">{{ t(`agents.reports.new.${comment.type}`) }} -
                {{ comment.subject }}</label>
              <div class="font-15 font-md-14">{{ comment.content }}</div>
            </div>

            <div class="mb-20 mb-sm-15" v-if="comment.images.length > 0">
              <label class="font-bold mb-10 font-16 font-md-15">{{ t('agents.reports.show.image') }}</label>
              <div class="row gutter-15" v-if="comment">
                <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in comment.images"
                     v-bind:key="index">
                  <div class="new-report-img" v-if="comment">
                    <a :href="image.url" target="_blank" rel="noopener noreferrer">
                      <img v-bind:src="image.thumb_url" alt="">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="house-images" v-if="houseImages.length > 0">
          <hr class="mt-05 mb-md-25 ml-n30 ml-lg-0 mr-n30 mr-lg-0">
          <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.houseImages') }}</h2>

          <div class="row gutter-15">
            <div class="col-lg-20p mb-15 col-sm-3 col-4" v-for="(image, index) in houseImages" v-bind:key="index">
              <div class="new-report-img">
                <a :href="image.url" target="_blank" rel="noopener noreferrer">
                  <img v-bind:src="image.thumb_url" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-reports-box -bg-white mb-35 pb-15" v-if="termThisReport()">
        <h2 class="new-reports-box__title mb-20">{{ t('agents.reports.new.agreement') }}</h2>
        <div class="new-report-line mb-40">
          <div v-for="(term, index) in termThisReport().content.trim().replace(/\n{2,}/g, '\n').split('\n')" :key="index">
            <div class="d-flex mb-20 mb-md-15 ml-lg-3">
              <div class="mr-10 ml-10 ml-md-00 mt-01 mt-md-00">
                <i class="icon icon-check-circle"></i>
              </div>
              <div class="font-16 font-md-14">
                {{ term }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-reports-box -bg-white mb-35 pb-15">
        <div class="row gutter-md-10">
          <div class="col-lg-3 col-6 mb-20 mb-sm-15">
            <div class="font-bold font-16 font-md-15 mb-08">{{ t('agents.reports.new.owner_agent_name') }}</div>
            <div class="font-15 font-md-14 mb-10">
              <span v-if="owner">{{ owner.full_name }}</span>
            </div>
          </div>

          <div class="col-lg-3 col-6 mb-20 mb-sm-15">
            <div class="font-bold font-16 font-md-15 mb-08">{{ t('agents.reports.new.owner_agent_signature') }}</div>
            <div class="mb-10">
              <span v-if="owner">
                <span>{{ displayDateTimeByFormat(owner.signed_date) }}</span>
                <img :src="owner.signature" alt=""/>
              </span>
            </div>
          </div>
        </div>

        <hr>
        <div class="row gutter-md-10">
          <div class="col-lg-6 col-12" v-for="hirer in clientsInformation" :key="hirer.id">
            <div class="row">
              <div class="col-6 mb-20 mb-sm-15" >
                <div class="font-bold font-16 font-md-15 mb-08">{{ t('agents.reports.new.hirer_name') }}</div>
                <div class="font-15 font-md-14 mb-10">
                  <span v-if="hirer">{{ hirer.report_hirer_signature && hirer.report_hirer_signature.full_name }}</span>
                </div>
              </div>
              <div class="col-6 mb-20 mb-sm-15">
                <div class="font-bold font-16 font-md-15 mb-08">{{ t('agents.reports.new.hirer_signature') }}</div>
                <div class="mb-10">
              <span v-if="hirer">
                <span>{{ displayDateTimeByFormat(hirer.report_hirer_signature && hirer.report_hirer_signature.signed_date) }}</span>
                <img :src="hirer.report_hirer_signature && hirer.report_hirer_signature.signature" alt=""/>
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row gutter-xl-15 mt-40 mt-md-30 mb-40 gutter-md-10 justify-content-center">
        <div class="col-md-3 col-sm-4 mb-sm-15">
          <a class="i-btn -info -bg-light -block pl-03 pr-03" href="javascript:void(0)" title="" @click="openModalShareEmail()">
            {{ t('agents.reports.new.share') }}
            <i class="icon icon-share ml-12"></i>
          </a>
        </div>
        <div class="col-md-3 col-sm-4 mb-sm-15">
          <router-link :to="{ name: 'ReportPdfDownload' }" class="i-btn -info -block pl-03 pr-03" target="_blank">
            <i class="icon icon-pdf ml-00 mr-12"></i>
            {{ t('agents.reports.new.download_as_pdf') }}
          </router-link>
        </div>
        <div class="col-md-3 col-sm-4">
          <router-link :to="{ name: 'Reports' }" class="i-btn -info -bg-light -block pl-03 pr-03">
            {{ t('agents.reports.new.home') }}
          </router-link>
        </div>
      </div>
      <div v-if="displayModalShareEmail">
        <ModalShareEmail @hideModalShareEmail="hideModalShareEmail"
                         :sharedUsers="sharedUsers" :currentReportId="currentReportId"></ModalShareEmail>
        <div class="modal-backdrop fade show"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../shared/Breadcrumb'
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import currentReport from '@/mixins/currentReport'
import typeOfReport from '@/mixins/typeOfReport'
import ModalShareEmail from './_ModalShareEmail'
import reportNextStepMixin from '@/mixins/reportNextStepMixin'
import momentJsMixin from '@/mixins/momentJsMixin'

export default {
  name: 'Show',
  components: {
    Breadcrumb,
    ModalShareEmail
  },
  mixins: [currentReport, typeOfReport, reportNextStepMixin, momentJsMixin],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data: function () {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.reports'), routerName: 'Reports', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.detail'), routerName: '#' }
      ],
      displayModalShareEmail: false,
      sharedUsers: [],
      pdfDownloadUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      entityProfile: 'entities/profiles',
      clientsInformation: 'reports/clientsInformation',
      ownerSignature: 'reportOwnerInformation/signature',
      meterInformation: 'reports/meterInformation',
      keyData: 'reportKeyInformation/data',
      terms: 'terms/terms',
      houseImages: 'reportHouseImages/imageList'
    }),
    hirer() {
      return this.reportDetails.report_hirer_signature
    },
    owner() {
      return this.reportDetails.report_owner_signature
    },

  },
  methods: {
    ...mapActions({
      getReportInfo: 'reports/getReportInfo',
      entityInfo: 'entities/getProfiles',
      getClientsInformation: 'reports/clientsInformation',
      getOwnerSignatureDetails: 'reportOwnerInformation/getSignatureDetails',
      getMeterInformation: 'reports/meterInformation',
      getKeyInformation: 'reportKeyInformation/getInformation',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      turnOffLoadingPage: 'loadingPage/turnOffLoadingPage',
      getTerms: 'terms/getTermList',
      getListHouseImages: 'reportHouseImages/list'
    }),
    displayComment() {
      return this.reportDetails.report_comments.length > 0
    },
    displayKeysBlock() {
      return (this.isDeliveryReport || this.isFinalReport) && (this.reportDetails.report_key_information.length > 0)
    },
    openModalShareEmail(){
      this.displayModalShareEmail = true
    },
    hideModalShareEmail(){
      this.displayModalShareEmail = false
    },
    loadSharedUsers() {
      this.sharedUsers.push({ email: this.entityProfile.email, name: this.entityProfile.name, type: 'owner_agent' })
      if(this.clientsInformation) {
        this.clientsInformation.forEach(client=> {
          this.sharedUsers.push({ email: client.email, name: client.full_name, type: 'client' })
        })
      }
    },
    termThisReport() {
      let reportType = this.reportDetails.type
      return this.terms.find(_term => _term.type === `report_${reportType}`)
    }
  },
  async mounted() {
    await this.getReportInfo(this.currentReportId)
    if(this.reportDetails.completed_step !== 'step-signature') {
      return this.$router.push({ path: this.nextStepPath(this.reportDetails) })
    }
    await this.getClientsInformation(this.currentReportId)
    await this.getOwnerSignatureDetails(this.currentReportId)
    await this.getMeterInformation(this.currentReportId)
    await this.getKeyInformation(this.currentReportId)
    await this.entityInfo()
    await this.getTerms()
    await this.getListHouseImages(this.currentReportId)
    await this.loadSharedUsers()
  }
}
</script>
