<template>
  <div id="term-details" class="main-container" v-if="loadDone">
    <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>
    <h2 class="reports__b-title">{{ t(`agents.terms.types.${termDetail.type}`) }}</h2>
    <div class="white-box mt-4">
      <ul v-for="(paragraph, index) in termDetail.content.replace(/\n{2,}/g, '\n').split('\n')" :key="index">
        <li>{{ paragraph }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from '../../shared/Breadcrumb'

export default {
  setup() {
    const { t } = useI18n()

    return { t }
  },
  components: {
    Breadcrumb
  },
  data: function() {
    return {
      loadDone: false
    }
  },
  methods: {
    ...mapActions({
      getDetails: 'terms/getDetails'
    })
  },
  computed: {
    ...mapGetters({
      termDetail: 'terms/termDetail'
    }),
    breadcrumbItems(){
      return [
          { name: this.t('breadcrumb.home'), routerName: 'Home', icon: 'icon icon-home-2' },
          { name: this.t(`agents.terms.types.${this.termDetail.type}`), routerName: '#' }
        ]
    },
    currentTermId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    await this.getDetails(this.currentTermId)
    this.loadDone = true
  }
}
</script>

<style>
#term-details li {
  margin-top: 10px;
  font-size: 1rem;
  list-style: initial;
}
</style>
