{
  "sidebar": {
    "dashboard": "Dashboard",
    "houses": "Woningen",
    "notifications": "Notificaties",
    "contracts": "Contracten",
    "settings": "Instellingen",
    "reports": "Rapporten",
    "agents": "Makelaars",
    "Staff": "Werknemer",
    "Manager": "Manager",
    "edit_entity": "Bedrijfsinformatie aanpassen",
    "termManagement": "Algemene Voorwaarden",
    "signOut": "Uitloggen"
  }
}
