import ReportSignatureInformationApi from '@/backend/report_signature_information'

const ERROR = 'ERROR'

const state = {
  errors: {}
}

export const mutations = {
  [ERROR](state, errors) {
    state.errors = errors
  }
}

export const actions = {
  async createOrUpdateClientSignature({ commit }, data) {
    await ReportSignatureInformationApi.createOrUpdateClientSignature(data.reportId, data.formData).then(() => {
      commit(ERROR, [])
    }).catch(error => {
        commit(ERROR, error.response.data.messages)
    })
  }
}

export const getters = {
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
