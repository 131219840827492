const typeOfReport = {
  computed: {
    isDeliveryReport() {
      return this.reportDetails.type === 'delivery'
    },

    isPreInspectionReport() {
      return this.reportDetails.type === 'pre_inspection'
    },

    isFinalReport() {
      return this.reportDetails.type === 'final_inspection'
    }
  }
}

export default typeOfReport
