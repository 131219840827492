import momentServices from '@/services/momentServices'
import { dateTimeFormat } from '@/settings/dateTimeFormat'

const momentJsMixin = {
  computed: {
    defaultDateTime() {
      return dateTimeFormat.defaultDateTime
    }
  },
  methods: {
    displayDateTimeByFormat(timeStr, format=dateTimeFormat.defaultDate){
      return momentServices.convertTimeApiToLocal(timeStr).format(format)
    }
  }
}

export default momentJsMixin
