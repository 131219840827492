import ApiService from './api_services'

const EntityApi = {
  profile: function (){
    return ApiService.get('/entities/profiles')
  },

  updateProfile: function (formData) {
    return ApiService.put('/entities/profiles', formData)
  }
}

export default EntityApi
