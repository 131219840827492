<template>
  <div id="term-details" className="main-container">
    <Breadcrumb v-bind:breadcrumbItems="breadcrumbItems"/>
    <h2 class="reports__b-title">{{ t('agents.terms.modify') }}</h2>
    <div class="white-box mt-4">
      <Form :term="termDetail"/>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import Breadcrumb from '../../shared/Breadcrumb'
import Form from '../terms/_Form'

export default {
  setup() {
    const { t } = useI18n()

    return { t }
  },
  components: {
    Breadcrumb,
    Form
  },
  data: function () {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.home'), routerName: 'Home', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.new'), routerName: '#' }
      ],
      termDetail: {
        type: '',
        content: ''
      }
    }
  }
}
</script>
