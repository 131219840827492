<template>
  <div v-if="errorCode">
    <Page404></Page404>
  </div>
  <div id="app" v-else>
    <Header :title="headerText" />
    <main class="main">
      <TheSidebar :currentUser="currentUser" style="float: left"/>
      <div v-if="sidebarStatus === 1" class="cover-open-sidebar" @click="clickCover()"></div>
      <div v-if="currentUser.id">
        <router-view :key="$route.path" @header-title="headerTitle"></router-view>
      </div>
    </main >
    <div class="loading style-2" v-if="loadingPageStatus === 'on'"><div class="loading-wheel"></div></div>
  </div>
</template>

<script>
import Header from './Header'
import TheSidebar from './TheSidebar'
import { mapActions, mapGetters } from 'vuex'
import defineAbilitiesFor from '@/services/ability'
import Page404 from '@/views/shared/Page404'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar, Header, Page404
  },
  data: function() {
    return {
      headerText: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
      sidebarStatus: 'sideBar/sidebarStatus',
      loadingPageStatus: 'loadingPage/status',
      errorCode: 'globalHandleError/errorCode'
    })
  },
  methods: {
  ...mapActions({
      getInfoUser: 'users/getUserProfile',
      toggleSidebar: 'sideBar/toggleSidebar',
      hideSidebar: 'sideBar/hide',
      clearError: 'globalHandleError/clearError'
    }),
    clickCover() {
      this.toggleSidebar()
    },
    headerTitle(text) {
      this.headerText = text
    }
  },
  async mounted() {
    this.clearError()
    await this.getInfoUser()
    let ability = defineAbilitiesFor(this.currentUser)
    await this.$ability.update(ability.rules)
  },
  watch: {
    sidebarStatus(newState){
      if(newState === 1)
        document.body.classList.add('body-block-scroll')
      else
        document.body.classList.remove('body-block-scroll')
    },
    $route (to, from){
      this.headerText = ''
      if(to.name !== from.name) {
        this.hideSidebar()
      }
    }
  },
}
</script>

<style>
@media (max-width: 991px) {
  .cover-open-sidebar {
    width: 100%;
    height: 100%;
    background: grey;
    z-index: 3;
    position: absolute;
    opacity: 0.5;
    top: 0
  }

  .body-block-scroll {
    overflow: hidden;
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 999;
}
.loading-wheel {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 30px;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
}
.style-2 .loading-wheel {
  border-style: double;
  border-color: #ccc transparent;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
</style>
