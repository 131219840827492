const TURN_ON = 'TURN_ON'
const TURN_OFF = 'TURN_OFF'

const state = {
  status:  'off'
}

export const mutations = {
  [TURN_ON] (state){
    state.status = 'on'
  },
  [TURN_OFF] (state){
    state.status = 'off'
  }
}

export const actions = {
  turnOnLoadingPage({ commit }) {
    commit(TURN_ON, 'on')
  },

  turnOffLoadingPage({ commit }) {
    commit(TURN_OFF, 'off')
  }
}

export const getters = {
  status: state => state.status
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
