<template>
  <header class="header d-lg-none">
    <div class="main-container">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto w-100 d-flex">
          <button class="header__nav-open" @click="toggle()"><i></i><i></i><i></i></button>
          <div class="header__page-wrapper-title">
            {{title}}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Header',
    props: ['title'],
    methods: {
      ...mapActions({
        toggleSidebar: 'sideBar/toggleSidebar'
      }),
      toggle(){
        this.toggleSidebar()
      }
    }
  }
</script>
