<template>
  <div id="reset-password">
    <Form class="form" @submit="onSubmit">
      <div class="content">
        <div class="entity-info">
          <div class="entity-logo text-center">
            <img :src="userInfo.entity_logo_thumb" :alt="`Logo ${userInfo.entity_name}`"/>
          </div>
          <div class="entity-name text-center"> {{ userInfo.entity_name }} </div>
        </div>
        <div class="message mt-3">
          <div v-html="t('userChangePassword.noticeChangePassword1', { email: userInfo.email })"></div>
          <div>{{ t('userChangePassword.noticeChangePassword2') }}</div>
        </div>
        <div class="new-password mt-3">
          <label>{{ t('userChangePassword.password') }}</label>
          <Field type="password" class="white-text-input" v-model="userPasswordParams.password" name="password" :rules="validatePassword"/>
          <ErrorMessage name="password" class="veeValidateError"></ErrorMessage>
        </div>
        <div class="password-confirm mt-3">
          <label>{{ t('userChangePassword.passwordConfirmation') }}</label>
          <Field type="password" class="white-text-input" v-model="userPasswordParams.password_confirm"
                 name="password_confirm" :rules="validatePasswordConfirm"/>
          <ErrorMessage name="password_confirm" class="veeValidateError"></ErrorMessage>
        </div>

        <div class="mt-4">
          <button class="i-btn w-100 w-sm-auto">{{ t('buttons.save') }}</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { subDomain } from '@/settings/subdomain'
import UserPasswordApi from '@/backend/user_password'
import { createToast } from 'mosha-vue-toastify'
import { ToastSettings } from '@/settings/toast'
import { ErrorMessage, Field, Form } from 'vee-validate'

export default {
  name: 'UserChangePassword',
  setup() {
    const { t } = useI18n()
    return { t }
  },
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      entityInfo: {},
      resetPasswordToken: '',
      userInfo: {},
      userPasswordParams: {
        password: '',
        password_confirm: ''
      }
    }
  },
  methods: {
    onSubmit(values) {
      let userParams = values
      userParams['reset_password_token'] = this.resetPasswordToken
      userParams['subdomain'] = subDomain()
      UserPasswordApi.changePassword(userParams).then(() => {
        this.$store.dispatch('auth/signOut')
        createToast(this.t('userChangePassword.messages.resetPasswordSuccess'), ToastSettings.success)
        this.$router.push( { name: 'Home' })
      }).catch(error => {
        createToast(error.response.data.message, ToastSettings.error)
      })
    },
    validatePassword(value) {
      if(value.length < 6) {
        return this.t('userChangePassword.errorMessages.password.tooShort')
      }

      return true
    },
    validatePasswordConfirm(value) {
      if(value !== this.userPasswordParams.password) {
        return this.t('userChangePassword.errorMessages.passwordConfirm.notMatch')
      }

      return true
    }
  },
  mounted() {
    this.resetPasswordToken = this.$route.query.token
    UserPasswordApi.validateResetPasswordToken({ reset_password_token: this.resetPasswordToken, subdomain: subDomain() })
      .then(response => {
        this.userInfo = response.data
      }).catch(() => {
      })
  }
}
</script>
