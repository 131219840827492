<template>
  <div id="reports">
    <div class="main-container">
      <div class="row justify-content-between align-items-center group-dashboard">
        <div class="col-xl-3 col-md-4 col-sm-6 d-flex mb-sm-10">
          <h1 class="page-title">
            {{ t('agents.dashboard').toUpperCase() }}
          </h1>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <a class="i-btn -block pl-md-05 pr-md-05 p-2 btn-new-report" href="#" title="" data-toggle="collapse" data-target="#new-report">
            +&nbsp; {{ t('agents.reports.add_new_report') }}
          </a>
          <div class="new-report__pane collapse" id="new-report">
            <div class="new-report__pane__ct">
              <button class="new-report__pane__item btn btn-block" title=""
                      v-on:click="clickNewReport('delivery')">
                {{ t('agents.reports.delivery_report') }}</button>
              <button class="new-report__pane__item btn btn-block" title=""
                      v-on:click="clickNewReport('pre_inspection')">
                {{ t('agents.reports.for_inspection') }}</button>
              <button class="new-report__pane__item btn btn-block" title=""
                      v-on:click="clickNewReport('final_inspection')">
                {{ t('agents.reports.final_inspection') }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row gutter-xl-20 gutter-md-10" style="clear: both">
        <div class="col-md-3 col-sm-6 mb-10">
          <div class="reports__statistic">
            <div class="reports__statistic__time">Afgelopen maand</div>
            <div class="reports__statistic__number">{{ this.displayStatistic(statistics.delivery) }}</div>
            <div class="reports__statistic__text">Procesverbaal van oplevering</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-10">
          <div class="reports__statistic">
            <div class="reports__statistic__time">{{ t('agents.reports.last_month') }}</div>
            <div class="reports__statistic__number">{{ this.displayStatistic(statistics.pre_inspection) }}</div>
            <div class="reports__statistic__text">Voor inspectie</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-10">
          <div class="reports__statistic">
            <div class="reports__statistic__time">{{ t('agents.reports.last_month') }}</div>
            <div class="reports__statistic__number">{{ this.displayStatistic(statistics.final_inspection) }}</div>
            <div class="reports__statistic__text">Eind inspectie</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-10">
          <div class="reports__statistic">
            <div class="reports__statistic__time">{{ t('agents.reports.last_month') }}</div>
            <div class="reports__statistic__number">{{ this.displayStatistic(statistics.total_reports) }}</div>
            <div class="reports__statistic__text">{{ t('reports.totalReport') }}</div>
          </div>
        </div>
      </div>

      <div class="row justify-content-between align-items-center mt-4 mb-2">
        <div class="col-12 col-md-6 mb-15">
          <h2 class="reports__b-title">{{ t('agents.reports.reports') }}</h2>
        </div>
        <div class="col-12 col-md-6 mb-15">
          <SearchForm @form-searching="formSearching"/>
        </div>
      </div>

      <div class="reports__table reports__table__sorting">
        <table>
          <thead>
            <tr>
              <th class="col-address pointer" v-on:click="changePriorityOrder('address')">
                <span>{{ t('agents.reports.address') }}</span>
                <i class="icon icon-sm float-right"
                   v-bind:class="sortingClass('address')"/>
              </th>

              <th class="col-state pointer" v-on:click="changePriorityOrder('state')">
                <span>{{ t('reports.state') }}</span>
                <i class="icon icon-sm float-right"
                   v-bind:class="sortingClass('state')"/></th>

              <th class="col-type pointer" v-on:click="changePriorityOrder('type')">
                <span>{{ t('agents.reports.type') }}</span>
                <i class="icon icon-sm float-right"
                   v-bind:class="sortingClass('type')"/>
              </th>

              <th class="col-date pointer" v-on:click="changePriorityOrder('createdAt')">
                <span>{{ t('agents.reports.date') }}</span>
                <i class="icon icon-sm float-right"
                   v-bind:class="sortingClass('createdAt')"/>
              </th>

              <th v-if="currentUser.type === 'Manager'" class="col-action"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(report, index) in reportsList" v-bind:key="index">
              <td class="px-2">
                <router-link class="smooth"
                             :to="{ path: `${reportPath(report)}` }">
                  {{ report.house_address }}
                  <span class="i-label -primary -radius pl-08 pr-08" v-if="report.city">{{ report.city }}</span>
                </router-link>
              </td>
              <td>
                <span :class="badgeReportType(state(report.completed_step))">{{ state(report.completed_step) }}</span>
              </td>
              <td>
                <router-link class="smooth"
                             :to="{ path: `${reportPath(report)}` }">
                  {{ reportType(report.type) }}
                </router-link>
              </td>
              <td>{{ displayDateTimeByFormat(report.created_at, defaultDateTime) }}</td>
              <td v-if="currentUser.type === 'Manager'">
                <i class="icon icon-trash pointer" @click="deleteReport(report)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="totalPages > 1">
        <v-pagination
            v-model="page"
            :pages="totalPages"
            :range-size="1"
            @update:modelValue="changePage"
            :hideFirstButton="true"
            :hideLastButton="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import { useAbility } from '@casl/vue'
import SearchForm from './SearchForm'
import VPagination from '@hennge/vue3-pagination'
import reportNextStepMixin from '@/mixins/reportNextStepMixin'
import momentJsMixin from '@/mixins/momentJsMixin'

export default {
  name: 'Reports',
  data: function () {
    return {
      defaultSort: {
        address: { order: 'desc', className: 'icon-sort-asc-default' },
        createdAt: { order: 'desc', className: 'icon-sort-desc' },
        state: { order: 'asc', className: 'icon-sort-asc-default' },
        type: { order: 'asc', className: 'icon-sort-asc-default' }
      },
      currentSort: '',
      page: parseInt(this.$route.query.page) || 1
    }
  },
  mixins: [reportNextStepMixin, momentJsMixin],
  components: {
    SearchForm,
    VPagination
  },
  setup() {
    const { t } = useI18n()
    const { can } = useAbility({ 'user': '1' })

    return { t, can }
  },
  computed: {
    ...mapGetters({
      reports: 'reports/list',
      currentUser: 'users/currentUser',
      entityProfile: 'entities/profiles',
      statistics: 'reports/statistics',
    }),
    reportsList() {
      return this.reports.list
    },
    totalPages() {
      return this.reports.totalPages
    },
    currentPage() {
      return this.reports.currentPage
    },
    paramsCurrentPage() {
      if(this.page !== 1) {
        return { page: this.page }
      }
      return {}
    }
  },
  methods: {
    ...mapActions({
      getInfoUser: 'users/getUserProfile',
      setList: 'reports/setList',
      loadMoreReport: 'reports/loadMore',
      getEntityProfiles: 'entities/getProfiles',
      getStatistics: 'reports/getStatistic',
      createReport: 'reports/createReport',
      turnOnLoading: 'loadingPage/turnOnLoadingPage',
      delete: 'reports/deleteReport'
    }),
    signOut: function (){
      this.$store.dispatch('auth/signOut')
    },
    loadMore() {
      let page = this.currentPage + 1
      this.loadMoreReport({ page: page, ...this.searchParams })
    },
    clickNewReport(reportType) {
      let params = { type: reportType }
      this.createReport(params)
    },
    displayStatistic(data) {
      return data === undefined ? '0' : data
    },
    reportType(type) {
      if (type === 'delivery') {
        return 'procesverbaal van oplevering'
      } else if (type === 'pre_inspection') {
        return 'voorinspectie'
      } else {
        return 'eindinspectie'
      }
    },
    state(currentStep) {
      return currentStep === 'step-signature' ? this.t('reports.states.completed') : this.t('reports.states.wip')
    },
    reportPath(report) {
      if(report.completed_step === null) {
        return `/agents/reports/${ report.id }/step-add-information`
      } else if(report.completed_step === 'step-signature') {
        return `/agents/reports/${ report.id }`
      } else {
        return this.nextStepPath(report)
      }
    },
    formSearching(params) {
      this.searchParams = params
    },
    async deleteReport(report) {
      if (confirm(this.t('reports.messages.confirm_delete_report'))) {
        await this.delete(report.id)
        await this.setList()
      }
    },
    changePriorityOrder(attribute) {
      let currentOrder = this.defaultSort[attribute]['order']

      if(currentOrder === 'asc') {
        currentOrder = 'desc'
      } else {
        currentOrder = 'asc'
      }
      Object.keys(this.defaultSort).forEach((key) => {
        if(key !== attribute) {
          this.defaultSort[key]['className'] = 'icon-sort-asc-default'
        }
      })
      this.defaultSort[attribute]['order'] = currentOrder
      this.defaultSort[attribute]['className'] = `icon-sort-${this.defaultSort[attribute]['order']}`

      if(attribute === 'address') {
        attribute = 'report_basic_information.house_address'
      } else if (attribute === 'state') {
        attribute = 'completed_step'
      }

      this.currentSort = `${attribute} ${currentOrder}`
      this.setList({ ...this.searchParams, ...{ order_query: this.currentSort } })
      this.page = 1
    },
    sortingClass(attribute){
      return this.defaultSort[attribute]['className']
    },
    badgeReportType(reportType) {
      if(reportType === 'Voltooid') {
        return 'badge badge-success'
      }

      return 'badge badge-secondary'
    },
    changePage() {
      this.setList({ ...this.paramsCurrentPage, ...this.searchParams, ...{ order_query: this.currentSort } })
    }
  },
  updated() {
    this.$emit('header-title', this.t('agents.dashboard').toUpperCase())
  },
  mounted() {
    this.turnOnLoading()
    this.setList(this.$route.query)
    this.getEntityProfiles()
    this.getStatistics()
  },
  watch: {
    '$route': function (after, before) {
      if(before.name === 'Reports' && after.name === 'Reports' && Object.keys(after.query).length === 0) {
        this.setList()
        this.page = 1
        this.searchParams = ''
      }
    }
  }
}
</script>
