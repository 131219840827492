import ReportBasicInformationApi from '../../../backend/report_basic_information'

const SET_INFORMATION = 'SET_INFORMATION'
const ERROR = 'ERROR'

const state = {
  data: {},
  errors: {}
}

export const mutations = {
  [SET_INFORMATION](state, data) {
    state.data = data
  },
  [ERROR](state, errors) {
    state.errors = errors
  }
}

export const actions = {
  async getInformation({ commit }, reportId) {
    await ReportBasicInformationApi.getInformation(reportId).then((response) => {
      commit(SET_INFORMATION, response.data)
    }).catch((error) => {
      console.error(error)
    })
  },
  async createOrUpdate({ commit }, params) {
    await ReportBasicInformationApi.createOrUpdate(params.reportId, params.formData).then(() => {
      commit(ERROR, {})
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  }
}

export const getters = {
  data: state => state.data,
  errors: state => state.errors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
