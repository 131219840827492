import { createStore } from 'vuex'
import auth from './modules/auth/index'
import houses from './modules/houses/index'
import houseVisualMedia from './modules/houses/visualMedia'
import houseForm from './modules/houses/houseForm'
import users from './modules/users/index'
import contracts from './modules/contracts/index'
import house_application from './modules/house_applications/index'
import clients from './modules/clients'
import documents from './modules/documents'
import notifications from './agents/notifications'
import supports from './modules/supports'
import reports from './modules/reports'
import commonUploadImage from './modules/common/uploadImage'
import entities from './modules/entities'
import reportSignature from './modules/reports/report_signature'
import agents from './modules/agents'
import terms from './modules/terms'
import sideBar from './modules/sidebar'
import reportConfig from './modules/reports/report_config'
import loadingPage from './modules/common/loadingPage'
import reportMeterInformation from './modules/reports/report_meter_information'
import reportClientsInformation from './modules/reports/report_clients_information'
import reportKeyInformation from './modules/reports/report_key_information'
import reportOwnerInformation from './modules/reports/report_owner_information'
import reportOwnerSignature from './modules/reports/report_owner_signature'
import reportComment from './modules/reports/report_comment'
import reportBasicInformation from './modules/reports/report_basic_information'
import reportHouseImages from './modules/reports/report_house_images'
import globalHandleError from '@/store/modules/global_handle_error'

export default createStore({
  modules: {
    auth,
    houses,
    houseVisualMedia,
    houseForm,
    users,
    contracts,
    house_application,
    clients,
    documents,
    notifications,
    supports,
    reports,
    commonUploadImage,
    entities,
    reportSignature,
    agents,
    terms,
    sideBar,
    reportConfig,
    loadingPage,
    reportMeterInformation,
    reportClientsInformation,
    reportKeyInformation,
    reportOwnerInformation,
    reportOwnerSignature,
    reportComment,
    reportBasicInformation,
    reportHouseImages,
    globalHandleError
  }
})
