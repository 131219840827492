<template>
  <div class="-bg-white rounded">
    <form role="form" class="new-reports-box -bg-white mt-60 mt-lg-40 mt-md-30">
      <h2 class="new-reports-box__title mt-0 mb-25 mb-lg-15">{{ t('agents.terms.title') }}</h2>

      <div class="reports__table">
        <table>
          <thead>
          <tr>
            <th>{{ t('agents.terms.type') }}</th>
            <th>{{ t('agents.terms.actions') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(term, index) in terms" v-bind:key="index">
            <td class="px-2 sp-nowrap">{{ t(`agents.terms.types.${term.type}`) }} </td>
            <td class="sp-nowrap">
              <router-link :to="{ name: 'TermShow', params: { id: term.id } }">
                <i class="icon icon-eye"></i></router-link>

              <router-link :to="{ name: 'TermEdit', params: { id: term.id } }" class="ml-3">
                <i class="icon icon-edit"></i></router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'

export default {
  name: 'TermManagement',
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data: function() {
    return {
      breadcrumbItems: [
        { name: this.t('breadcrumb.home'), routerName: 'Home', icon: 'icon icon-home-2' },
        { name: this.t('breadcrumb.terms'), routerName: '#' },
        { name: this.t('breadcrumb.list'), routerName: '#' }
      ]
    }
  },
  methods: {
    ...mapActions({
      getList: 'terms/getTermList'
    }),
    newTerm() {
      router.push( { name: 'TermNew' })
    }
  },
  computed: {
    ...mapGetters({
      terms: 'terms/terms',
      currentUser: 'users/currentUser'
    })
  },
  async mounted() {
    await this.getList()
  }
}
</script>