import { authenticate } from '../../../backend/authenticate'
import AuthService from '../../../services/auth_service'
import router from '../../../router'
import ApiService from '../../../backend/api_services'

const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
const SIGN_OUT = 'SIGN_OUT'

const state = {
  accessToken: AuthService.getToken(),
  isLoggedIn: !!AuthService.getToken(),
  resourceType: AuthService.getResourceType(),
  errorMessage: ''
}

export const mutations = {
  [SIGN_IN_SUCCESS] (state, data) {
    AuthService.setToken(data['accessToken'])
    AuthService.setResourceType(data['resourceType'])

    state.accessToken = data['accessToken']
    state.isLoggedIn = true
    state.resourceType = data['resourceType']
    state.errorMessage = ''
  },
  [SIGN_IN_FAILED] (state, error_response) {
    state.errorMessage = error_response
  },
  [SIGN_OUT] (state) {
    AuthService.removeAccessToken()
    AuthService.removeResourceType()
    ApiService.removeHeader()

    state.accessToken = null
    state.isLoggedIn = false
    state.resourceType = null
  }
}

export const actions = {
  login ({ commit }, credentials) {
    return authenticate(credentials.email, credentials.password).then(response => {
      let data = response.data
      let accessToken = data['access_token']
      let resourceType = data['resource_type']

      commit(SIGN_IN_SUCCESS, { accessToken: accessToken, resourceType: resourceType })
      router.push('/').then(() => {})
    }).catch(() => {
      commit(SIGN_IN_FAILED, 'Onjuiste combinatie e-mailadres en wachtwoord.')
    })
  },
  signOut({ commit }) {
    commit(SIGN_OUT)
  }
}

export const getters = {
  accessToken: state => state.accessToken,
  isLoggedIn: state => state.isLoggedIn,
  resourceType: state => state.resourceType,
  errorMessage: state => state.errorMessage
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
