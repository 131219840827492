import ReportHouseImagesApi from '../../../backend/report_house_images'

const ERROR = 'ERROR'
const SET_IMAGE_LIST = 'SET_IMAGE_LIST'

const state = {
  imageList: [],
  errors: {}
}

export const mutations = {
  [ERROR](state, errors) {
    state.errors = errors
  },

  [SET_IMAGE_LIST](state, images) {
    state.imageList = images
  }
}

export const actions = {
  async upload({ commit }, params) {
    await ReportHouseImagesApi.upload(params.reportId, params.formData).then(() => {
      commit(ERROR, {})
    }).catch((error) => {
      commit(ERROR, error.response.data.messages)
    })
  },

  async list({ commit }, reportId) {
    await ReportHouseImagesApi.list(reportId).then((response) => {
      commit(SET_IMAGE_LIST, response.data)
      commit(ERROR, {})
    }).catch(error => {
      commit(ERROR, error.response.data.messages)
    })
  }
}

export const getters = {
  errors: state => state.errors,
  imageList: state => state.imageList
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
