// Container
const TheContainer = () => import('@/containers/TheContainer')

import Houses from '../views/agents/houses/Houses'
import NewHouse from '../views/agents/houses/New'
import HouseDetail from '../views/agents/houses/Show'
import EditHouse from '../views/agents/houses/Edit'
import HouseApplicationShow from '../views/agents/house_applications/Show'
import Notifications from '../views/agents/notifications/Notifications'
import Reports from '../views/agents/reports/Reports'
import ReportShow from '../views/agents/reports/Show'
import Agents from '../views/agents/agents/Agents'
import NewReportStep1 from '../views/agents/reports/steps/Step1'
import NewReportStep2 from '../views/agents/reports/steps/Step2'
import NewReportStep3 from '../views/agents/reports/steps/Step3'
import NewReportStep5 from '../views/agents/reports/steps/Step5'
import NewReportStep6 from '../views/agents/reports/steps/Step6'
import Configuration from '@/views/agents/configuration/Index'

// Term management
import TermShow from '../views/agents/terms/Show'
import TermEdit from '../views/agents/terms/Edit'
import TermNew from '../views/agents/terms/New'

// http://philips.tim.dave:8080/#/agents/houses
export default [
  {
    path: '/agents',
    name: 'Agents',
    component: TheContainer,
    redirect: '/agents/reports',
    children: [
      { path: 'houses', component: Houses, name: 'Agents Houses' },
      { path: 'houses/new', component: NewHouse, name: 'Agents New House' },
      { path: 'houses/:id/edit', component: EditHouse, name: 'Agents Edit House' },
      { path: 'houses/:id', component: HouseDetail, name: 'Agents House Show' },
      { path: 'houses/:houseId/applications/:id', component: HouseApplicationShow,
        name: 'Agent House Application Show' },
      { path: 'notifications', component: Notifications, name: 'Notifications' },
      { path: 'reports', component: Reports, name: 'Reports' },
      { path: 'staffs', component: Agents, name: 'Staffs' },
      { path: '/', component: Reports, name: 'Home' },
      { path: 'reports/:id', component: ReportShow, name: 'Report show' },
      { path: 'reports/new', component: NewReportStep1, name: 'FormNewReport' },
      { path: 'reports/:id/step-add-information', component: NewReportStep1, name: 'FormReportStep1' },
      { path: 'reports/:id/step-meter-keys', component: NewReportStep2, name: 'FormReportStep2' },
      { path: 'reports/:id/step-comments', component: NewReportStep3, name: 'FormReportStep3' },
      { path: 'reports/:id/step-overviews', component: NewReportStep5, name: 'FormReportStep5' },
      { path: 'reports/:id/step-signature', component: NewReportStep6, name: 'FormReportStep6' },
      { path: 'configuration', component: Configuration, name: 'Configuration' },
      { path: 'terms/:id', component: TermShow, name: 'TermShow' },
      { path: 'terms/:id/edit', component: TermEdit, name: 'TermEdit' },
      { path: 'terms/new', component: TermNew, name: 'TermNew' }
    ]
  }
]
