const SET_ERROR = 'SET_ERROR'
const CLEAR_ERROR = 'CLEAR_ERROR'

const state = {
  errorCode: null
}

export const mutations = {
  [SET_ERROR] (state, errorCode) {
    state.errorCode = errorCode
  },

  [CLEAR_ERROR] (state) {
    state.errorCode = null
  }
}

export const actions = {
  setError({ commit }, errorCode = {}) {
    commit(SET_ERROR, errorCode)
  },
  clearError({ commit }) {
    commit(CLEAR_ERROR)
  }
}

export const getters = {
  errorCode: state => state.errorCode
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
